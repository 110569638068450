import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { environment } from 'src/environments/environment';
import { Parametros } from '../model/parametros';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  language: string; //language variable
  dateYear: number = 0; //date year
  version: string = "";
  parametros: Parametros;
  @Input() isFixed: boolean = false; //Enable fixed-footer class

  constructor(private translate: TranslateService, private auth: AuthService, private apiService: ApiService) { }

  ngOnInit() {
    this.language = this.auth.getLanguage();
    if (this.language == "" || this.language == null) {
      this.language = 'es';
    }
    this.translate.setDefaultLang(this.language);
    this.getDateYear(); //Get Year

    let web = environment.base;
    this.configClientParams(web);

    

  }

  private configClientParams(web: string) {
    this.apiService.searchParamCliente(web).subscribe((parametros: Parametros) => {
      this.parametros = parametros;
      this.version = this.parametros[22].valor;
    })
  }

  
  /**
 * Get Year For All Rights Reserved
 */
  getDateYear() {
    const currenDate = new Date();
    this.dateYear = currenDate.getFullYear();
  }

}
