import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { Informes } from '../model/informes';
import { Usuarios } from '../dashboard/models/usuarios';
import { environment } from "../../environments/environment"
import { PlazosDenuncia } from '../model/plazosDenuncia';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-asignar-receptor',
  templateUrl: './asignar-receptor.component.html',
  styleUrls: ['./asignar-receptor.component.css'],
  providers: [DatePipe]
})
export class AsignarReceptorComponent implements OnInit {
  myForm: FormGroup;
  IsHiddenForm: boolean = false;
  @Input() denuncia: DatosDenuncia;
  receptores: Usuarios[];
  receptores2: Usuarios[];
  plazos: PlazosDenuncia[];
  receptor_select: Usuarios[];
  informes: Informes[];
  contador = 0;
  textoInforme: string;

  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService, private http: HttpClient, private datepipe: DatePipe) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.myForm = new FormGroup({
      selectReceptor: new FormControl('', [Validators.required]),
      textoInforme: new FormControl('', []),
    });

    let url = window.location.href.split("/", 6);
    let idDenuncia = url[5];

    this.apiService.searchUserByIdSocietyRole(this.auth.getSociedad(), "1", false, idDenuncia).subscribe((usuarios: Usuarios[]) => {
      this.receptores = usuarios;
    });

    this.apiService.searchUserByIdSocietyRole(this.auth.getSociedad(), "1", false, "nulo").subscribe((usuarios: Usuarios[]) => {
      this.receptores2 = usuarios;
    });

    this.apiService.searchInvesSelect(this.auth.getSociedad(), idDenuncia, "1").subscribe((usuarios2: Usuarios[]) => {
      this.receptor_select = usuarios2;
    });

    this.apiService.searchInformes(this.auth.getSociedad(), idDenuncia).subscribe((informes2: Informes[]) => {
      this.informes = informes2;
    });

    // console.log("this.receptor_select: ", this.receptor_select[0].n_user);
    // console.log("this.informes: ", this.informes[0].id_denuncia);

    //this.textoInforme = this.informes[0].informe_gestor;

  }

  get selectReceptor() {
    return this.myForm.get('selectReceptor');
  }

  get texto() {
    return this.myForm.get('textoInforme');
  }

  asignarReceptor() {
    if (this.contador > 4000) {
      this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
    } else {
      this.apiService.countInvesSelect(this.auth.getSociedad(), this.denuncia[0].id_denuncia, "", "1").subscribe(
        res => {
          if (res != 200) {
            this.apiService.sendReportByIdDenuncia(this.denuncia[0].id_denuncia, this.textoInforme, 'GESTOR', this.auth.getIdUser()).subscribe(
              res => {
                if (res == 200) {
                  this.toastr.success(this.translate.instant('texto_479'), this.translate.instant('texto_495'));
                  this.apiService.createUserByIdComplaint(this.auth.getSociedad(), this.selectReceptor.value, this.denuncia[0].id_denuncia, "1", '0', '0', true).subscribe(
                    res => {
                      if (res == 200) {
                        this.toastr.success(this.translate.instant('texto_496'), this.translate.instant('texto_495'));
                      } else {
                        // this.toastr.error(this.translate.instant('texto_497'), this.translate.instant('texto_495'));
                      }
                      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                        this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
                      });
                    },
                    err => {
                      this.toastr.error(this.translate.instant('texto_497'), this.translate.instant('texto_495'));
                    });
                } else {
                  this.toastr.error(this.translate.instant('texto_497'), this.translate.instant('texto_495'));
                }
              });
          } else {
            this.apiService.sendReportByIdDenuncia(this.denuncia[0].id_denuncia, this.textoInforme, 'GESTOR', this.auth.getIdUser()).subscribe(
              res => {
                if (res == 200) {
                  this.toastr.success(this.translate.instant('texto_479'), this.translate.instant('texto_495'));
                  this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
                  });
                }
              });
          }
        });
    }
  }

  deletereceptor(id_user: string) {
    this.apiService.countInvesSelect(this.auth.getSociedad(), this.denuncia[0].id_denuncia, id_user, "1").subscribe(
      res => {
        if (res == 200) {
          this.apiService.deleteInvesSelect(id_user, this.auth.getSociedad(), this.denuncia[0].id_denuncia, "1").subscribe(
            res => {
              if (res == 200) {
                this.toastr.success(this.translate.instant('texto_462'), this.translate.instant('texto_495'));
              } else {
                this.toastr.error(this.translate.instant('texto_464'), this.translate.instant('texto_495'));
              }
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
              });
            });
        } else {
          this.toastr.error(this.translate.instant('texto_464'), this.translate.instant('texto_495'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
        });
      });
  }

  onKey(event) {
    var datos = document.getElementById("textoInformeReceptor");
    for (var i = 0; i < 1; i++) {
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
  }

}
