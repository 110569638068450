import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { ApiService } from "../shared/services/api.service";
import { catchError, map, tap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root"
})
export class PasswordTokenGuard implements CanActivate {
  constructor(private apiService: ApiService, private router: Router,  private toastr: ToastrService) { }

  /**
   * This guard checks the URL for a `tokenPassword` parameter and verifies it with the backend API.
   * If the token is valid, the access token is stored in localStorage, and the route is activated and redirect to "reestablecer-contrasenia"
   */
  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {    
    // Get the token from the URL query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const tokenPassword = urlParams.get('tokenPassword');
    const tokenPasswordComplaint = urlParams.get('tokenPasswordComplaint');


    if (tokenPassword) {
      return this.apiService.verifyPasswordToken(tokenPassword).pipe(
        // Handle the response from the API service
        tap(response => {
          localStorage.setItem('token_reset_password', response.accessToken);
        }),
        // If the token is valid, allow the route activation
        map(() => true),
        // If an error occurs, deny the route activation
        catchError(() => {
          this.router.navigate(["inicio"]);
          return of(false);
        })
      );
    } else if (tokenPasswordComplaint){
      return this.apiService.verifyPasswordTokenComplaint(tokenPasswordComplaint).pipe(
        // Handle the response from the API service
        tap(response => {
          localStorage.setItem('token_password_compaint', response.accessToken);
        }),
        // If the token is valid, allow the route activation
        map(() => true),
        // If an error occurs, deny the route activation
        catchError(() => {
          this.router.navigate(["inicio"]);
          return of(false);
        })
      );
    } else {
      this.router.navigate(["inicio"]);
      return false;
    }
  }
}
