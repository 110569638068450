import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Canal } from 'src/app/enviar-denuncia/models/canal';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EnviarDenunciaService } from 'src/app/enviar-denuncia/services/enviar-denuncia.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { FinalizarDenunciaService } from 'src/app/enviar-denuncia/services/finalizar-denuncia.service';

@Component({
  selector: 'app-terminada-denuncia',
  templateUrl: './terminada-denuncia.component.html',
  styleUrls: ['./terminada-denuncia.component.css']
})
export class TerminadaDenunciaComponent implements OnInit {
  title = 'texto_143';
  canal: Canal;
  IsAnonimo: boolean = false;
  denuncia: DatosDenuncia[]; // Array to store complaint data
  idDenuncia: string = ''; // Variable to extract the id of the complaint from the url
  IdComplaintShow: string = ''; // Variable for complaint id to be displayed to the user.
  copied: boolean = false; // Flag to indicate if content has been copied
  language: string;

  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    private finalizarDenuncia: FinalizarDenunciaService,
    private enviarDenunciaService: EnviarDenunciaService,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private titleService: Title
  ) {
    // Set title based on selected language
    this.translate.get('texto_359', { value: this.language }).subscribe((text: string) => {
      this.titleService.setTitle(text);
    });
  }

  ngOnInit() {
    // Check if the navigation ID is 2, indicating a back navigation, and reload the page if so
    if (window.history.state.navigationId == 2) {
      window.location.reload();
    } else {
      let language = this.auth.getLanguage();
      if (language == "" || language == null) {
        language = 'es'; // Default to Spanish if no language is set
      }
      // Get the pin from the route parameters
      let pin = this.actRoute.snapshot.params.id;
      const randomLength = 5; // Length random characters
      const totalLength = pin.length; // Total length of string id

      // Extract pin_code between first and last 5 characters
      this.idDenuncia = pin.substring(randomLength, totalLength - randomLength);
      this.finalizarDenuncia.searchComplaintByPin(this.idDenuncia, language).subscribe((denuncia: DatosDenuncia[]) => {
        this.denuncia = denuncia;
        // Show id personalizable if it is enable, if isn't, shown normal id.
        if (this.denuncia[0].identificador_denuncia != null && this.denuncia[0].identificador_denuncia !== '') {
          this.IdComplaintShow = this.denuncia[0].identificador_denuncia;
        } else {
          this.IdComplaintShow = this.denuncia[0].id_denuncia;
        }
        // Check if the complaint is anonymous
        if (this.denuncia[0].denunciante_correo == "") {
          this.IsAnonimo = true;
        }
      });

      // Fetch canal information and set anonymity flag based on the retrieved data
      this.enviarDenunciaService.searchCanal().subscribe((canal: Canal) => {
        this.canal = canal;
        if (this.canal[0].valor == "1") {
          this.IsAnonimo = true;
        }
      });
    }
  }

  /**
   * Navigates to the 'consultar-denuncia' route by first navigating to a RefreshComponent to refresh the state.
   */
  consultar() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/ver-denuncia/consultar-denuncia']);
    });
  }

  /**
   * Hides the current view by logging out the user and navigating to the 'inicio' route.
   */
  hide() {
    this.auth.logoutSimple(); // Logs out the user
    this.router
      .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/inicio']);
      });
  }

  /**
   * Shows a success notification when content is copied successfully.
   */
  onCopied() {
    this.toastr.success(this.translate.instant('texto_718'));
  }
}
