import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Receptor } from 'src/app/shared/models/formData.model';
import { ReceptorInicial } from 'src/app/shared/models/formData.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { RolReceptor } from 'src/app/enviar-denuncia/models/rolReceptor';
import { TipoHecho } from 'src/app/shared/models/formData.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ReceptorService } from 'src/app/enviar-denuncia/services/receptor.service';
import { FormDataService } from 'src/app/enviar-denuncia/services/formData.service';
import { DatosService } from 'src/app/enviar-denuncia/services/datos.service';


export interface Receptores {
  id: string;
  viewValue: string;
}

@Component({
  selector: 'app-receptor',
  templateUrl: './receptor.component.html',
  styleUrls: ['./receptor.component.css'],
})
export class ReceptorComponent implements OnInit {
  @Input() datos: any;
  tipoHecho: TipoHecho;
  title = 'texto_343';
  inicial: boolean = false;
  atras: boolean = false;
  receptor: Receptor;
  receptorInicial: ReceptorInicial;
  receptorPropuesto: RolReceptor;
  //asignarReceptor = Receptor;
  form: any;
  index: number = 0;
  indexA: number = 0;
  receptores: RolReceptor[];
  contador = 0;

  gestionar_conflicto: string = '';
  clausulas_correo: string = '';

  constructor(
    private receptorService: ReceptorService,
    private router: Router,
    private translate: TranslateService,
    private auth: AuthService,
    private formDataService: FormDataService,
    private toastr: ToastrService,
    private dataService: DatosService,
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if (this.datos.idEmpresa != '' && this.datos.tipoDenuncia != '') {
      if (changes.datos && changes.datos.currentValue) {
        
        this.procesarDatos(changes.datos.currentValue);
        }
      }
    
  }

  ngOnInit() {
    
  }
  procesarDatos(datos: any) {

    console.log("Datos recibidos:", datos);
    let language = this.auth.getLanguage();
    if (language == '' || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    
    this.tipoHecho = this.formDataService.getTipoHecho();
    let empresa = this.tipoHecho.empresa;
    this.receptor = this.formDataService.getReceptor();
    console.log(this.receptor);
    this.receptorInicial = this.formDataService.getReceptorInicial();
    
    if (this.receptor.motivo == '' && this.receptor.receptor != '') {
      console.log('entro 1');
      this.inicial = false;
      console.log(this.datos.idEmpresa, this.datos.tipoDenuncia, this.datos.idioma),

        this.receptorService
          .searchUsersReceptors(this.datos.idEmpresa, this.datos.tipoDenuncia, this.datos.idioma)
          .subscribe((receptores: RolReceptor[]) => {
            this.receptores = receptores;
            // console.log('1', this.receptores);
            for (var i = 0; i < this.receptores.length; i++) {
              if (
                this.receptores[i].id_user == this.receptorInicial.receptorInicial
              ) {
                this.indexA = i;
                this.receptorInicial.receptorInicial = receptores[i].id_user;
                this.receptorInicial.motivoInicial = '';
                this.formDataService.setReceptorInicial(this.receptorInicial);
                break;
              }
            }
          });
         
    } else {
      //console.log("receptor: ", this.receptor.receptor)
      if (this.receptor.receptor == '' && this.datos.idEmpresa != null) {
        // if (this.receptor.receptor == '' && empresa != '') {
        console.log("Datos para buscar receptor random", this.datos.idEmpresa, this.datos.tipoDenuncia, this.datos.idioma)
        this.receptorService
          .searchUsersReceptorsRamdom(
            this.datos.idEmpresa, this.datos.tipoDenuncia, this.datos.idioma
          )
          .subscribe((receptores: RolReceptor[]) => {
            this.receptores = receptores;
            // console.log('2', this.receptores);
            this.indexA = 0;
            this.receptorInicial.receptorInicial = receptores[0].id_user;
            this.receptorInicial.motivoInicial = '';
            this.formDataService.setReceptorInicial(this.receptorInicial);
          });
      } else {
        console.log('entro 3');
        this.inicial = true;
        console.log("Datos para buscar receptor", this.datos.idEmpresa, this.datos.tipoDenuncia, this.datos.idioma)
        this.receptorService
          .searchUsersReceptors(this.datos.idEmpresa, this.datos.tipoDenuncia, this.datos.idioma)
          .subscribe((receptores: RolReceptor[]) => {
            this.receptores = receptores;
            //console.log('3', this.receptores);
          });
      }
    }

    this.gestionar_conflicto = localStorage.getItem('gestionar_conflicto');
    this.clausulas_correo = localStorage.getItem('clausulas_correo');
    //console.log(this.gestionar_conflicto);
    //console.log(this.clausulas_correo);
 
  }

  public save(form: any): boolean {
    if (!form.valid) {
      return false;
    }
    if (!this.inicial) {
      this.receptor.receptor = this.receptores[this.index].id_user;
      this.receptor.motivo = '';
      this.formDataService.setReceptor(this.receptor);
    } else {
      if (this.receptor.motivo == '') {
        this.toastr.error(
          'Es necesario añadir la descripción',
          'Parámetros incorrectos'
        );
        return false;
      } else {
        this.formDataService.setReceptor(this.receptor);
      }
    }

    return true;
  }

  public goToPrevious(form: any) {
    if (this.save(form)) {
      // Navigate to the personal page
      this.router.navigate(['/enviar-denuncia/denunciante']);
    }
  }

  public goToNext(form: any) {
    if (this.save(form)) {
      // Navigate to the work page
      this.router.navigate(['/enviar-denuncia/datos-denuncia']);
    }
  }

  public buscarReceptores() {
    let language = this.auth.getLanguage();
    if (language == '' || language == null) {
      language = 'es';
    }
    this.inicial = true;
    this.tipoHecho = this.formDataService.getTipoHecho();
    let empresa = this.tipoHecho.empresa;
    this.receptorService
      .searchUsersReceptors(empresa, this.tipoHecho.tipoDenuncia, language)
      .subscribe((receptores: RolReceptor[]) => {
        this.receptores = receptores;
      });
    this.receptor = this.formDataService.getReceptor();

  }

  public hide() {
    window.location.reload();
    //this.router.navigateByUrl('/#/login');
  }

  public deshacer() {
    let empresa = this.tipoHecho.empresa;
    this.receptor.receptor =
      this.formDataService.getReceptorInicial().receptorInicial;
    this.receptorService
      .searchUsersSocietyReceptors(empresa, this.receptor.receptor)
      .subscribe((receptores: RolReceptor[]) => {
        this.receptores = receptores;
      });
    this.inicial = false;
  }

  public onKey(event) {
    var datos = (document.getElementById('motivo') as HTMLInputElement).value;
    this.contador = datos.length;
  }
}
