import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { environment } from "../../environments/environment"

@Component({
  selector: 'app-validar-denuncia',
  templateUrl: './validar-denuncia.component.html',
  styleUrls: ['./validar-denuncia.component.css']
})
export class ValidarDenunciaComponent implements OnInit {
  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  @Input() denuncia: DatosDenuncia;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      validarDenuncia: new FormControl('', [])
    });
  }

  get validarDenuncia() {
    return this.validatingForm.get('validarDenuncia');
  }

  validar(){
      if(this.validarDenuncia.value == true){

        this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "1", this.auth.getSociedad(),"","").subscribe(
          res => {
            if(res == 200){
              this.toastr.success(this.translate.instant('texto_191'), this.translate.instant('texto_192'));
              if(this.denuncia[0].denunciante_correo != ""){

                let $clau = false;
                let clausulas_correo = localStorage.getItem("clausulas_correo");
                if(clausulas_correo == '1'){
                  $clau = true;
                }

                let url = environment.base + "/#/ver-denuncia/consultar-denuncia";
                let message = this.translate.instant('texto_576') + " "  +  this.denuncia[0].denunciante_nombre + " " +this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_604') + " "  + this.denuncia[0].id_denuncia + " "  + this.translate.instant('texto_650');
                this.apiService.sendEmail(this.denuncia[0].denunciante_correo,this.translate.instant('texto_651') + this.denuncia[0].id_denuncia, message,url,$clau, this.translate.instant('texto_651')).subscribe(
                  res => {
                    if(res == 200){
                    //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                    }else{
                      this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                    }
                });
              }
            }else{
              this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
            }
            this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
            });
        });
      }

  }
}
