import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//declare var gtag;
declare function gtag(...args: any[]): void;

/**
 * Service to control google analytics
 */
@Injectable({
  providedIn: 'root'
})
export class HeadGoogleAnalyticsService {
  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  private idGoogleAnalytics: string; // Client ID
  private renderer: Renderer2; //library used to interact with DOM HTML
  public flagGoogleActive: boolean = true;

  constructor(rendererFactory: RendererFactory2, private router: Router, private auth: AuthService, private httpClient: HttpClient) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // Getter to access idGoogleAnalytics
  get googleAnalyticsId(): string {
    return this.idGoogleAnalytics;
  }


  /**
   *
   * @param id_society
   * @returns
   */
  getIdGoogleValue() {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/dashboard/configuracionAvanzada/getIdGoogleValue.php`, options);
  }

  /**
   * Service to add google analitycs in the HEAD html
   * @param trackingId id from client google
   */
  addGoogleAnalyticsScript(trackingId: string) {
    // Script gtag.js
    const scriptTag = this.renderer.createElement('script');
    this.renderer.setAttribute(scriptTag, 'async', '');
    this.renderer.setAttribute(scriptTag, 'src', `https://www.googletagmanager.com/gtag/js?id=${trackingId}`);
    this.renderer.appendChild(document.head, scriptTag);

    // Script to configure gtag
    const inlineScript = this.renderer.createElement('script');
    const scriptContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${trackingId}');
    `;
    inlineScript.text = scriptContent;
    this.renderer.appendChild(document.head, inlineScript);
  }


  /**
    * Initialize the google analytics configuration and subscribe to navigation events
    */
  initializeAnalytics() {
    this.getIdGoogleValue().subscribe((idGoogleAnalytics: string) => {
      this.idGoogleAnalytics = idGoogleAnalytics;
      this.addGoogleAnalyticsScript(this.idGoogleAnalytics);

      const navEndEvents$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      );

      navEndEvents$.subscribe((event: NavigationEnd) => {
        // Wait for script to load and gtag to be defined
        if (typeof gtag !== 'undefined') {
          gtag('config', this.idGoogleAnalytics, {
            'page_path': event.urlAfterRedirects
          });
        } else {
          console.error('gtag is not defined');
        }
      });
    });
  }

}
