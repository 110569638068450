import { Component, EventEmitter, OnInit, Output, ViewChild, } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConfiguracionAvanzadaService } from 'src/app/dashboard/services/configuracion-avanzada.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Parametros } from 'src/app/dashboard/models/parametros';
import { environment } from 'src/environments/environment.prod';
import { FormDataService } from 'src/app/data/formData.service';
import { Textos } from 'src/app/shared/models/formData.model';
import { Textos2 } from 'src/app/dashboard/models/textos2';
import { Clausulas } from 'src/app/shared/models/formData.model';
import { Clausulas2 } from 'src/app/dashboard/models/clausulas2';
import { Idiomas } from 'src/app/dashboard/models/idiomas';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-configuracion-avanzada',
  templateUrl: './configuracion-avanzada.component.html',
  styleUrls: ['./configuracion-avanzada.component.css'],
})
export class ConfiguracionAvanzadaComponent implements OnInit {
  @Output() cambioSSO: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(MatSelect) matSelect: MatSelect;
  PHP_API_SERVER = environment.baseUrl;

  validatingForm: FormGroup;
  validatingFormImages: FormGroup;
  validatingFormMail: FormGroup;
  validatingFormColores: FormGroup;
  validatingFormTextos: FormGroup;
  validatingFormClausulas: FormGroup;
  validatingFormSSO: FormGroup;
  validatingFormMultisociedad: FormGroup;
  validatingFormIdiomas: FormGroup;
  emailF: string = '';
  dominioF: string = '';
  avisoF: string = '';
  //anonimoF: boolean = false;
  anonimoF: string = '';
  clausulasF: string = '';
  conflictoF: string = '';
  diasinvesF: string = '';
  diasdecisorF: string = '';
  diascomplianceF: string = '';
  diasverdenunciaF: string = '';

  logoF: string = '';
  inicio1F: string = '';
  inicio2F: string = '';
  inicio3F: string = '';
  fondo_denuncianteF: string = '';
  wizard1F: string = '';
  wizard2F: string = '';
  wizard3F: string = '';
  wizard4F: string = '';
  wizard5F: string = '';
  wizard6F: string = '';
  wizard7F: string = '';
  logindenuncianteF: string = '';
  cabeceramailF: string = '';

  nremitenteF: string = '';
  remitenteF: string = '';
  srv_mailF: string = '';
  puertoF: string = '';
  usr_mailF: string = '';
  pass_mailF: string = '';
  auth_mailF: boolean = false;
  sslF: boolean = false;

  colorbgseccion1F: string = '';
  colorbgseccion2F: string = '';
  colorbgseccion3F: string = '';
  colorprincipalF: string = '';
  colorsecundarioF: string = '';
  colortxtbtnF: string = '';
  colortxtseccion1F: string = '';
  colortxtseccion2F: string = '';
  colortxtseccion3F: string = '';
  colortxttituloF: string = '';
  colormenulateralF: string = '';
  colortxtcabecerasF: string = '';
  colortxtbotonesF: string = '';
  colortxtFondoF: string = '';

  parametros: Parametros;

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;

  color: string;

  selectedTipo: string = '';
  selectedClausulas: string = '';
  selectedConflicto: string = '';
  selectedTriaje: string = '';
  selectedCenters: string = ''; //checkbox centers
  selectedinputAutocomplete: string = ''; //checkbox inputAutocomplete

  textos: Textos;
  textos2: Textos2[];
  textoF: string = '';
  selectedIdTexto: string;

  filteredTextos: Textos2[];
  allTextos: Textos2[];

  clausulas: Clausulas;
  clausulas2: Clausulas2[];
  clausulaF: string = '';
  selectedIdClausula: string;

  rutaF: string = '';
  doc_terminosF: string = '';

  rutaPoliticaF: string = '';
  doc_politicaF: string = '';

  rutaPoliticaClienteF: string = '';
  doc_politicaClienteF: string = '';

  //fichero: boolean;
  selectedTerminos: string;

  //fichero politica privacidad: boolean;
  selectedPolitica: string;

  //fichero politica privacidad cliente: boolean;
  selectedPoliticaCliente: string;

  //fichero: boolean;
  selectedBoton1: string;
  doc_boton1F: string = '';

  selectedBoton2: string;
  doc_boton2F: string = '';

  selectedBoton3: string;
  doc_boton3F: string = '';

  selectedBoton4: string;
  doc_boton4F: string = '';

  // Declare variable to store the local storage value
  selectedPopUp: string;
  dias_visible: string = '';

  saml_enabledF: boolean = false;
  saml_idp_entity_idF: string = '';
  saml_idp_sso_urlF: string = '';
  saml_idp_sloF: string = '';
  saml_idp_certF: string = '';
  saml_idp_cert2F: string = '';
  saml_mapping_cnF: string = '';
  saml_mapping_snF: string = '';
  saml_mapping_emailF: string = '';
  saml_mapping_phoneF: string = '';
  saml_mapping_national_idF: string = '';
  saml_jitF: boolean = false;
  saml_sloF: boolean = false;
  saml_force_loginF: boolean = false;
  saml_debugF: boolean = false;
  saml_sp_entity_idF: string = '';
  saml_sp_pkF: string = '';
  saml_sp_certF: string = '';
  selectedsaml_sp_nameid_format: string = '';

  multiF: boolean = false;
  tieneSSOF: boolean = false;
  archivardenunciaF: boolean = false;
  idPersonalizableEnabledF: boolean = false;
  gAnalyticsEnabledF: boolean = false;

  hasSSO: boolean = false;
  esSADM: boolean = false;

  idiomas: Idiomas[];
  IsHiddenForm: boolean = false;
  id_idioma: string;
  n_idioma: string;
  bandera: string;
  comentario: string;
  Habilitado: string;
  Habilitadochk: boolean = false;

  ValorMostrar: string;
  emailErrors: string = ''; //Email Consultas Error

  //event handler for the select element's change event
  selectChangeHandler(event: any) {
    //update the ui
    this.selectedTipo = event.target.value;
  }

  //event handler for the select element's change event
  selectChangeHandler2(event2: any) {
    //update the ui
    this.selectedClausulas = event2.target.value;
  }

  //event handler for the select element's change event
  selectChangeHandler3(event3: any) {
    //update the ui
    this.selectedConflicto = event3.target.value;
  }

  //event handler for the select element's change event
  selectChangeHandler8(event8: any) {
    //update the ui
    this.selectedTriaje = event8.target.value;
  }

  //Event check centers
  selectChangeHandlerCenters(eventCenter: any) {
    //update the ui
    this.selectedCenters = eventCenter.target.value;
  }

  //Event check de Input Autocomplete
  selectChangeHandlerInputAutocomplete(eventInputAutocomplete: any) {
    //update the ui
    this.selectedinputAutocomplete = eventInputAutocomplete.target.value;
  }

  //event handler for the select element's change event
  selectChangeHandler4(event4: any) {
    //update the ui
    this.selectedTerminos = event4.target.value;
    /*console.log(this.selectedTerminos);
    if(this.selectedTerminos == "0"){
      this.fichero = true;
    }else{
      this.fichero = false;
    }*/
  }

  selectChangeHandler5(event5: any) {
    //update the ui
    this.selectedBoton1 = event5.target.value;
    //console.log(this.selectedBoton1);
    /*if(this.selectedTerminos == "0"){
      this.fichero = true;
    }else{
      this.fichero = false;
    }*/
  }

  selectChangeHandler6(event6: any) {
    //update the ui
    this.selectedBoton2 = event6.target.value;
    //console.log(this.selectedBoton1);
    /*if(this.selectedTerminos == "0"){
      this.fichero = true;
    }else{
      this.fichero = false;
    }*/
  }

  selectChangeHandler7(event7: any) {
    //update the ui
    this.selectedBoton3 = event7.target.value;
    //console.log(this.selectedBoton1);
    /*if(this.selectedTerminos == "0"){
      this.fichero = true;
    }else{
      this.fichero = false;
    }*/
  }

  selectChangeHandler13(event13: any) {
    //update the ui
    this.selectedBoton4 = event13.target.value;
    //console.log(this.selectedBoton1);
    /*if(this.selectedTerminos == "0"){
      this.fichero = true;
    }else{
      this.fichero = false;
    }*/
  }

  //event handler for the select element's change event
  selectChangeHandler9(event9: any) {
    //update the ui
    this.selectedsaml_sp_nameid_format = event9.target.value;
  }
  selectChangeHandler10(event10: any) {
    this.selectedPopUp = event10.target.value;
    //console.log("Variable popUp: ", this.selectedPopUp);

  }

  /**
   * Event handler for the change event of a select element.
   * Updates the status of selectedPolitica depending if user selected URL or Document.
   *
   * @param {Event} event11 - The event triggered by the select element when its value changes.
   */
  selectChangeHandler11(event11: Event): void {
    this.selectedPolitica = (event11.target as HTMLSelectElement).value;
  }

  /**
   * Event handler for the change event of a select element.
   * Updates the status of selectedPoliticaCliente depending if user selected URL or Document.
   *
   * @param {Event} event12 - The event triggered by the select element when its value changes.
   */
  selectChangeHandler12(event12: Event): void {
    this.selectedPoliticaCliente = (event12.target as HTMLSelectElement).value;
  }



  constructor(
    private configuracionAvanzadaService: ConfiguracionAvanzadaService,
    private formDataService: FormDataService,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    let idioma = this.auth.getLanguage();
    if (idioma == '' || idioma == null) {
      idioma = 'es';
    }

    if (localStorage.getItem('esSADM') == '1') {
      this.esSADM = true;
    }

    this.configuracionAvanzadaService
      .searchTodosIdiomas()
      .subscribe((idiomas: Idiomas[]) => {
        this.idiomas = idiomas;
      });

    this.configuracionAvanzadaService
      .searchAdvancedParam(this.auth.getSociedad())
      .subscribe((parametros: Parametros) => {
        this.parametros = parametros;
        //console.log(parametros)
        this.selectedTipo = this.parametros[0].valor;
        //this.anonimoF = this.parametros[0].valor;
        if (this.parametros[1].valor == '1') {
          this.auth_mailF = true;
        } else {
          this.auth_mailF = false;
        }
        this.cabeceramailF = this.parametros[2].valor;
        this.selectedClausulas = this.parametros[3].valor;
        this.colorbgseccion1F = this.parametros[4].valor;
        this.colorbgseccion2F = this.parametros[5].valor;
        this.colorbgseccion3F = this.parametros[6].valor;
        this.colorprincipalF = this.parametros[7].valor;
        this.colorsecundarioF = this.parametros[8].valor;
        this.colortxtbtnF = this.parametros[9].valor;
        this.colortxtseccion1F = this.parametros[10].valor;
        this.colortxtseccion2F = this.parametros[11].valor;
        this.colortxtseccion3F = this.parametros[12].valor;
        this.colortxttituloF = this.parametros[13].valor;
        this.selectedConflicto = this.parametros[14].valor;
        this.remitenteF = this.parametros[15].valor;
        //this.destination_file = this.parametros[16].valor;
        this.diascomplianceF = this.parametros[17].valor;
        this.diasdecisorF = this.parametros[18].valor;
        this.diasinvesF = this.parametros[19].valor;
        this.dominioF = this.parametros[20].valor;
        this.fondo_denuncianteF = this.parametros[21].valor;
        this.inicio1F = this.parametros[22].valor;
        this.inicio2F = this.parametros[23].valor;
        this.inicio3F = this.parametros[24].valor;
        this.logindenuncianteF = this.parametros[25].valor;
        this.logoF = this.parametros[26].valor;
        this.emailF = this.parametros[27].valor;
        this.nremitenteF = this.parametros[28].valor;
        this.pass_mailF = this.parametros[29].valor;
        this.avisoF = this.parametros[30].valor;
        this.puertoF = this.parametros[31].valor;
        this.srv_mailF = this.parametros[32].valor;
        if (this.parametros[33].valor == '1') {
          this.sslF = true;
        } else {
          this.sslF = false;
        }
        this.usr_mailF = this.parametros[34].valor;
        this.wizard1F = this.parametros[35].valor;
        this.wizard2F = this.parametros[36].valor;
        this.wizard3F = this.parametros[37].valor;
        this.wizard4F = this.parametros[38].valor;
        this.wizard5F = this.parametros[39].valor;
        this.wizard6F = this.parametros[40].valor;
        this.wizard7F = this.parametros[41].valor;
        this.selectedTerminos = this.parametros[42].valor;
        this.doc_terminosF = this.parametros[43].valor;
        this.rutaF = this.parametros[44].valor;
        this.diasverdenunciaF = this.parametros[45].valor;
        this.colormenulateralF = this.parametros[46].valor;
        this.colortxtcabecerasF = this.parametros[47].valor;
        this.colortxtbotonesF = this.parametros[48].valor;
        this.doc_boton1F = this.parametros[50].valor;
        this.selectedBoton1 = this.parametros[51].valor;
        this.doc_boton2F = this.parametros[52].valor;
        this.selectedBoton2 = this.parametros[53].valor;
        this.doc_boton3F = this.parametros[54].valor;
        this.selectedBoton3 = this.parametros[55].valor;
        this.selectedTriaje = this.parametros[56].valor;
        this.dias_visible = this.parametros[59].valor;
        this.selectedCenters = this.parametros[61].valor; //valor del centro en la posición 61
        this.selectedinputAutocomplete = this.parametros[62].valor;
        this.selectedPopUp = this.parametros[63].valor;
        // Get the value of variables related to the privacity policy in positions 64, 65 and 66.
        this.selectedPolitica = this.parametros[64].valor;
        this.doc_politicaF = this.parametros[65].valor;
        this.rutaPoliticaF = this.parametros[66].valor;
        this.colortxtFondoF = parametros[67].valor;
        // Get the value of variables related to the privacity policy of client in positions 74, 75 and 76.
        this.selectedPoliticaCliente = this.parametros[74].valor;
        this.doc_politicaClienteF = this.parametros[75].valor;
        this.rutaPoliticaClienteF = this.parametros[76].valor;
        this.selectedBoton4 = this.parametros[80].valor;
        this.doc_boton4F = this.parametros[79].valor;
        //Assign true or false to the “advanced options” card variables according to the value of the corresponding parameter.
        if (this.parametros[57].valor == '1') {
          this.multiF = true;
        } else {
          this.multiF = false;
        }
        if (this.parametros[58].valor == '1') {
          this.tieneSSOF = true;
          this.hasSSO = true;
          this.saml_enabledF = true;
        } else {
          this.tieneSSOF = false;
          this.hasSSO = false;
          this.saml_enabledF = false;
        }
        if (this.parametros[60].valor == '1') {
          this.archivardenunciaF = true;
        } else {
          this.archivardenunciaF = false;
        }
        if (this.parametros[68].valor == '1') {
          this.idPersonalizableEnabledF = true;
        } else {
          this.idPersonalizableEnabledF = false;
        }
        if (this.parametros[77].valor == '1') {
          this.gAnalyticsEnabledF = true;
        } else {
          this.gAnalyticsEnabledF = false;
        }

      });

    this.configuracionAvanzadaService
      .searchSSOdParam(this.auth.getSociedad())
      .subscribe((parametros: Parametros) => {
        this.parametros = parametros;
        if (this.parametros[0].valor == '1') {
          this.saml_enabledF = true;
        } else {
          this.saml_enabledF = false;
        }
        if (this.tieneSSOF) {
          this.saml_enabledF = true;
        }
        this.saml_idp_entity_idF = this.parametros[1].valor;
        this.saml_idp_sso_urlF = this.parametros[2].valor;
        this.saml_idp_sloF = this.parametros[3].valor;
        this.saml_idp_certF = this.parametros[4].valor;
        this.saml_idp_cert2F = this.parametros[5].valor;
        this.saml_mapping_cnF = this.parametros[6].valor;
        this.saml_mapping_snF = this.parametros[7].valor;
        this.saml_mapping_emailF = this.parametros[8].valor;
        this.saml_mapping_phoneF = this.parametros[9].valor;
        this.saml_mapping_national_idF = this.parametros[10].valor;
        if (this.parametros[11].valor == '1') {
          this.saml_jitF = true;
        } else {
          this.saml_jitF = false;
        }
        if (this.parametros[12].valor == '1') {
          this.saml_sloF = true;
        } else {
          this.saml_sloF = false;
        }
        if (this.parametros[13].valor == '1') {
          this.saml_force_loginF = true;
        } else {
          this.saml_force_loginF = false;
        }
        if (this.parametros[14].valor == '1') {
          this.saml_debugF = true;
        } else {
          this.saml_debugF = false;
        }
        this.saml_sp_entity_idF = this.parametros[15].valor;
        this.saml_sp_pkF = this.parametros[16].valor;
        this.saml_sp_certF = this.parametros[17].valor;
        this.selectedsaml_sp_nameid_format = this.parametros[18].valor;
      });

    this.textos = this.formDataService.getTexto();
    if (this.textos.id_texto != '') {
      this.configuracionAvanzadaService
        .searchTextos(this.textos.id_texto, idioma, this.auth.getSociedad())
        .subscribe((textos2: Textos2[]) => {
          this.textos2 = textos2;
          this.textoF = textos2[0].texto;
        });
    }

    this.clausulas = this.formDataService.getClausula();
    if (this.clausulas.id_clausula != '') {
      this.configuracionAvanzadaService
        .searchClausulas(this.clausulas.id_clausula, this.auth.getSociedad())
        .subscribe((clausulas2: Clausulas2[]) => {
          this.clausulas2 = clausulas2;
          this.clausulaF = clausulas2[0].clausula;
        });
    }

    this.validatingForm = new FormGroup({
      email: new FormControl('', this.validateEmails),
      dominio: new FormControl('', []),
      aviso: new FormControl('', []),
      anonimo: new FormControl('', []),
      calusulas: new FormControl('', []),
      triaje: new FormControl('', []),
      centros: new FormControl('', []),
      autocomplete: new FormControl('', []),
      conflicto: new FormControl('', []),
      diasinves: new FormControl('', []),
      diasdecisor: new FormControl('', []),
      diascompliance: new FormControl('', []),
      doc_terminos: new FormControl('', []),
      doc_politica: new FormControl('', []),
      doc_politica_cliente: new FormControl('', []),
      doc_boton1: new FormControl('', []),
      boton1: new FormControl('', []),
      doc_boton2: new FormControl('', []),
      boton2: new FormControl('', []),
      doc_boton3: new FormControl('', []),
      doc_boton4: new FormControl('', []),
      boton3: new FormControl('', []),
      boton4: new FormControl('', []),
      popUp: new FormControl('', []),
      ruta: new FormControl('', []),
      rutapolitica: new FormControl('', []),
      rutapoliticacliente: new FormControl('', []),
      terminos: new FormControl('', []),
      politica: new FormControl('', []),
      politicaCliente: new FormControl('', []),
      file: new FormControl('', []),
      fileSource: new FormControl('', []),
      fich: new FormControl('', []),
      fich1: new FormControl('', []),
      fich2: new FormControl('', []),
      fich3: new FormControl('', []),
      fich4: new FormControl('', []),
      fich5: new FormControl('', []),
      fich6: new FormControl('', []),
      fileSource1: new FormControl('', []),
      fileSource2: new FormControl('', []),
      fileSource3: new FormControl('', []),
      fileSource4: new FormControl('', []),
      fileSource5: new FormControl('', []),
      fileSource6: new FormControl('', []),
      diasverdenuncia: new FormControl('', []),
    });

    this.validatingFormColores = new FormGroup({
      colorprincipal: new FormControl('', []),
      colorsecundario: new FormControl('', []),
      colorbgseccion1: new FormControl('', []),
      colorbgseccion2: new FormControl('', []),
      colorbgseccion3: new FormControl('', []),
      colortxtbtn: new FormControl('', []),
      colortxtseccion1: new FormControl('', []),
      colortxtseccion2: new FormControl('', []),
      colortxtseccion3: new FormControl('', []),
      colortxttitulo: new FormControl('', []),
      colormenulateral: new FormControl('', []),
      colortxtcabeceras: new FormControl('', []),
      colortxtbotones: new FormControl('', []),
      colortxtFondo: new FormControl(''),
    });

    this.validatingFormImages = new FormGroup({
      file: new FormControl('', []),
      fileSource: new FormControl('', []),
      fich: new FormControl('', []),
      logo: new FormControl('', []),
      inicio1: new FormControl('', []),
      inicio2: new FormControl('', []),
      inicio3: new FormControl('', []),
      fondo_denunciante: new FormControl('', []),
      wizard1: new FormControl('', []),
      wizard2: new FormControl('', []),
      wizard3: new FormControl('', []),
      wizard4: new FormControl('', []),
      wizard5: new FormControl('', []),
      wizard6: new FormControl('', []),
      wizard7: new FormControl('', []),
      logindenunciante: new FormControl('', []),
      cabeceramail: new FormControl('', []),
    });

    this.validatingFormMail = new FormGroup({
      nremitente: new FormControl('', []),
      remitente: new FormControl('', []),
      srv_mail: new FormControl('', []),
      puerto: new FormControl('', []),
      usr_mail: new FormControl('', []),
      pass_mail: new FormControl('', []),
      auth_mail: new FormControl('', []),
      ssl: new FormControl('', []),
    });

    this.validatingFormMultisociedad = new FormGroup({
      multi: new FormControl('', []),
      tieneSSO: new FormControl('', []),
      archivardenuncia: new FormControl('', []),
      idPersonalizableEnabled: new FormControl('', []),
      gAnalyticsEnabled: new FormControl('', []),

    });

    this.validatingFormIdiomas = new FormGroup({
      frmnidioma: new FormControl(''),
      frmididioma: new FormControl(''),
      frmcomentario: new FormControl(''),
      chkhabilitado: new FormControl('', []),
      icono_idioma: new FormControl('', []),
      fileSourceIdioma: new FormControl('', []),
    });

    this.validatingFormTextos = new FormGroup({
      id_texto: new FormControl('', []),
      texto_value: new FormControl('', []),
    });

    this.validatingFormClausulas = new FormGroup({
      id_clausula: new FormControl('', []),
      clausula_value: new FormControl('', []),
    });

    this.validatingFormSSO = new FormGroup({
      saml_enabled: new FormControl('', []),
      saml_idp_entity_id: new FormControl('', []),
      saml_idp_sso_url: new FormControl('', []),
      saml_idp_slo: new FormControl('', []),
      saml_idp_cert: new FormControl('', []),
      saml_idp_cert2: new FormControl('', []),
      saml_mapping_cn: new FormControl('', []),
      saml_mapping_sn: new FormControl('', []),
      saml_mapping_email: new FormControl('', []),
      saml_mapping_phone: new FormControl('', []),
      saml_mapping_national_id: new FormControl('', []),
      saml_jit: new FormControl('', []),
      saml_slo: new FormControl('', []),
      saml_force_login: new FormControl('', []),
      saml_debug: new FormControl('', []),
      saml_sp_entity_id: new FormControl('', []),
      saml_sp_pk: new FormControl('', []),
      saml_sp_cert: new FormControl('', []),
      saml_sp_nameid_format: new FormControl('', []),
    });

    this.configuracionAvanzadaService
      .searchTextos2(this.textos.id_texto, false, this.auth.getLanguage(), this.auth.getSociedad())
      .subscribe((textos2: Textos2[]) => {
        this.textos2 = textos2;
        this.allTextos = textos2; // Inicializar allTextos con todos los textos
        this.filteredTextos = this.allTextos; // Inicializar filteredTextos con todos los textos
      });
  }

  onChangeTexto(event) {
    this.textoF = '';
    let selectedIdTexto = event.value;
    //console.log(selectedIdTexto);
    for (var i = 0; i < this.textos2.length; i++) {
      if (this.textos2[i].id_texto == selectedIdTexto) {
        this.textoF = this.textos2[i].texto;
        //console.log(this.textoF);
        break;
      }
    }
  }
  // Synchronizes the state of saml_enabledF with tieneSSOF.
  syncData(): void {
    this.saml_enabledF = this.tieneSSOF;
  }

  onClickTexto(event) {
    let idioma = this.auth.getLanguage();
    if (idioma == '' || idioma == null) {
      idioma = 'es';
    }
    this.configuracionAvanzadaService
      .searchTextos2(
        this.textos.id_texto,
        false,
        idioma,
        this.auth.getSociedad()
      )
      .subscribe((textos2: Textos2[]) => {
        this.textos2 = textos2;
        this.allTextos = textos2;
        this.filteredTextos = this.allTextos;
      });
  }

  ngAfterViewInit() {
    this.matSelect.open();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();

    this.filteredTextos = this.allTextos.filter(txt => {
      const idMatch = txt.id_texto.toLowerCase().includes(filterValue);
      const textoMatch = txt.texto.toLowerCase().includes(filterValue);
      return idMatch || textoMatch;
    });
  }

  onChangeClausula(event) {
    this.clausulaF = '';
    let selectedIdClausula = event.value;
    //console.log(selectedIdTexto);
    for (var i = 0; i < this.clausulas2.length; i++) {
      if (this.clausulas2[i].id_clausula == selectedIdClausula) {
        this.clausulaF = this.clausulas2[i].clausula;
        //console.log(this.textoF);
        break;
      }
    }
  }

  onClickClausula(event) {
    this.configuracionAvanzadaService
      .searchClausulas2(
        this.clausulas.id_clausula,
        false,
        this.auth.getSociedad()
      )
      .subscribe((clausulas2: Clausulas2[]) => {
        this.clausulas2 = clausulas2;
      });
  }

  get dominio() {
    return this.validatingForm.get('dominio');
  }

  get email() {
    return this.validatingForm.get('email');
  }

  get aviso() {
    return this.validatingForm.get('aviso');
  }

  get anonimo() {
    return this.validatingForm.get('anonimo');
  }

  get calusulas() {
    return this.validatingForm.get('calusulas');
  }

  get triaje() {
    return this.validatingForm.get('triaje');
  }

  get centros() {
    return this.validatingForm.get('centros');
  }

  get autocomplete() {
    return this.validatingForm.get('autocomplete');
  }

  get conflicto() {
    return this.validatingForm.get('conflicto');
  }

  get diasinves() {
    return this.validatingForm.get('diasinves');
  }

  get diasdecisor() {
    return this.validatingForm.get('diasdecisor');
  }

  get diascompliance() {
    return this.validatingForm.get('diascompliance');
  }

  get ruta() {
    return this.validatingForm.get('ruta');
  }

  get rutapolitica() {
    return this.validatingForm.get('rutapolitica');
  }

  get rutapoliticacliente() {
    return this.validatingForm.get('rutapoliticacliente');
  }

  get doc_terminos() {
    return this.validatingForm.get('doc_terminos');
  }

  get doc_politica() {
    return this.validatingForm.get('doc_politica');
  }

  get doc_politica_cliente() {
    return this.validatingForm.get('doc_politica_cliente');
  }

  get doc_boton1() {
    return this.validatingForm.get('doc_boton1');
  }

  get boton1() {
    return this.validatingForm.get('boton1');
  }

  get doc_boton2() {
    return this.validatingForm.get('doc_boton2');
  }

  get boton2() {
    return this.validatingForm.get('boton2');
  }

  get doc_boton3() {
    return this.validatingForm.get('doc_boton3');
  }

  get doc_boton4() {
    return this.validatingForm.get('doc_boton4');
  }

  get boton3() {
    return this.validatingForm.get('boton3');
  }

  get boton4() {
    return this.validatingForm.get('boton4');
  }

  get popUp() {
    return this.validatingForm.get('popUp');
  }

  get terminos() {
    return this.validatingForm.get('terminos');
  }
  get politica() {
    return this.validatingForm.get('politica');
  }
  get politicaCliente() {
    return this.validatingForm.get('politicaCliente');
  }
  get logo() {
    return this.validatingFormImages.get('logo');
  }

  get inicio1() {
    return this.validatingFormImages.get('inicio1');
  }

  get inicio2() {
    return this.validatingFormImages.get('inicio2');
  }

  get inicio3() {
    return this.validatingFormImages.get('inicio3');
  }

  get fondo_denunciante() {
    return this.validatingFormImages.get('fondo_denunciante');
  }

  get wizard1() {
    return this.validatingFormImages.get('wizard1');
  }

  get wizard2() {
    return this.validatingFormImages.get('wizard2');
  }

  get wizard3() {
    return this.validatingFormImages.get('wizard3');
  }

  get wizard4() {
    return this.validatingFormImages.get('wizard4');
  }

  get wizard5() {
    return this.validatingFormImages.get('wizard5');
  }

  get wizard6() {
    return this.validatingFormImages.get('wizard6');
  }

  get wizard7() {
    return this.validatingFormImages.get('wizard7');
  }

  get logindenunciante() {
    return this.validatingFormImages.get('logindenunciante');
  }

  get cabeceramail() {
    return this.validatingFormImages.get('cabeceramail');
  }

  get nremitente() {
    return this.validatingFormMail.get('nremitente');
  }

  get remitente() {
    return this.validatingFormMail.get('remitente');
  }

  get srv_mail() {
    return this.validatingFormMail.get('srv_mail');
  }

  get puerto() {
    return this.validatingFormMail.get('puerto');
  }

  get usr_mail() {
    return this.validatingFormMail.get('usr_mail');
  }

  get pass_mail() {
    return this.validatingFormMail.get('pass_mail');
  }

  get auth_mail() {
    return this.validatingFormMail.get('auth_mail');
  }

  get ssl() {
    return this.validatingFormMail.get('ssl');
  }

  get multi() {
    return this.validatingFormMultisociedad.get('multi');
  }

  get tieneSSO() {
    return this.validatingFormMultisociedad.get('tieneSSO');
  }

  get archivardenuncia() {
    return this.validatingFormMultisociedad.get('archivardenuncia');
  }
  get idPersonalizableEnabled() {
    return this.validatingFormMultisociedad.get('idPersonalizableEnabled');
  }

  get gAnalyticsEnabled() {
    return this.validatingFormMultisociedad.get('gAnalyticsEnabled');
  }

  get frmnidioma() {
    return this.validatingFormIdiomas.get('frmnidioma');
  }

  get frmididioma() {
    return this.validatingFormIdiomas.get('frmididioma');
  }

  get frmcomentario() {
    return this.validatingFormIdiomas.get('frmcomentario');
  }

  get chkhabilitado() {
    return this.validatingFormIdiomas.get('chkhabilitado');
  }

  get colorprincipal() {
    return this.validatingFormColores.get('colorprincipal');
  }

  get colorsecundario() {
    return this.validatingFormColores.get('colorsecundario');
  }

  get colorbgseccion1() {
    return this.validatingFormColores.get('colorbgseccion1');
  }

  get colormenulateral() {
    return this.validatingFormColores.get('colormenulateral');
  }

  get colortxtcabeceras() {
    return this.validatingFormColores.get('colortxtcabeceras');
  }

  get colortxtbotones() {
    return this.validatingFormColores.get('colortxtbotones');
  }

  get colorbgseccion2() {
    return this.validatingFormColores.get('colorbgseccion2');
  }

  get colorbgseccion3() {
    return this.validatingFormColores.get('colorbgseccion3');
  }

  get colortxtbtn() {
    return this.validatingFormColores.get('colortxtbtn');
  }

  get colortxtseccion1() {
    return this.validatingFormColores.get('colortxtseccion1');
  }

  get colortxtseccion2() {
    return this.validatingFormColores.get('colortxtseccion2');
  }

  get colortxtseccion3() {
    return this.validatingFormColores.get('colortxtseccion3');
  }

  get colortxttitulo() {
    return this.validatingFormColores.get('colortxttitulo');
  }

  get colortxtFondo() {
    return this.validatingFormColores.get('colortxtFondo');
  }

  get texto_value() {
    return this.validatingFormTextos.get('texto_value');
  }

  get id_texto() {
    return this.validatingFormTextos.get('id_texto');
  }

  get clausula_value() {
    return this.validatingFormClausulas.get('clausula_value');
  }

  get id_clausula() {
    return this.validatingFormClausulas.get('id_clausula');
  }

  get diasverdenuncia() {
    return this.validatingForm.get('diasverdenuncia');
  }

  get saml_enabled() {
    return this.validatingFormSSO.get('saml_enabled');
  }

  get saml_idp_entity_id() {
    return this.validatingFormSSO.get('saml_idp_entity_id');
  }

  get saml_idp_sso_url() {
    return this.validatingFormSSO.get('saml_idp_sso_url');
  }

  get saml_idp_slo() {
    return this.validatingFormSSO.get('saml_idp_slo');
  }

  get saml_idp_cert() {
    return this.validatingFormSSO.get('saml_idp_cert');
  }

  get saml_idp_cert2() {
    return this.validatingFormSSO.get('saml_idp_cert2');
  }

  get saml_mapping_cn() {
    return this.validatingFormSSO.get('saml_mapping_cn');
  }

  get saml_mapping_sn() {
    return this.validatingFormSSO.get('saml_mapping_sn');
  }

  get saml_mapping_email() {
    return this.validatingFormSSO.get('saml_mapping_email');
  }

  get saml_mapping_phone() {
    return this.validatingFormSSO.get('saml_mapping_phone');
  }

  get saml_mapping_national_id() {
    return this.validatingFormSSO.get('saml_mapping_national_id');
  }

  get saml_jit() {
    return this.validatingFormSSO.get('saml_jit');
  }

  get saml_slo() {
    return this.validatingFormSSO.get('saml_slo');
  }

  get saml_force_login() {
    return this.validatingFormSSO.get('saml_force_login');
  }

  get saml_debug() {
    return this.validatingFormSSO.get('saml_debug');
  }

  get saml_sp_entity_id() {
    return this.validatingFormSSO.get('saml_sp_entity_id');
  }

  get saml_sp_pk() {
    return this.validatingFormSSO.get('saml_sp_pk');
  }

  get saml_sp_cert() {
    return this.validatingFormSSO.get('saml_sp_cert');
  }

  get saml_sp_nameid_format() {
    return this.validatingFormSSO.get('saml_sp_nameid_format');
  }

  guardarDatos() {
    /*let valor1 = "0";
    if(this.anonimo.value == true){
      valor1 = "1";
    }*/
    let valor1 = '0';
    valor1 = this.selectedTipo;

    let valor2 = '';
    if (this.dominio.value != '') {
      valor2 = this.dominio.value;
    }

    let valor3 = '';
    if (this.email.value != '') {
      valor3 = this.email.value;
    }

    let valor4 = '';
    if (this.aviso.value != '') {
      valor4 = this.aviso.value;
    }

    let valor5 = false;
    let valor51 = '0';
    if (this.selectedClausulas == '1') {
      valor5 = true;
      valor51 = '1';
    }

    let valor6 = '0';
    valor6 = this.selectedConflicto;

    let valor7 = '';
    if (this.diasinves.value != '') {
      valor7 = this.diasinves.value;
    }

    let valor8 = '';
    if (this.diasdecisor.value != '') {
      valor8 = this.diasdecisor.value;
    }

    let valor9 = '';
    if (this.diascompliance.value != '') {
      valor9 = this.diascompliance.value;
    }

    let valor10 = '0';
    valor10 = this.selectedTerminos;

    let valor11 = '';
    if (this.doc_terminos.value != '') {
      valor11 = this.doc_terminos.value;
    }

    let valor12 = '';
    if (this.ruta.value != '') {
      valor12 = this.ruta.value;
    }

    let valor13 = '';
    if (this.diasverdenuncia.value != '') {
      valor13 = this.diasverdenuncia.value;
    }

    let valor14 = '0';
    valor14 = this.selectedBoton1;

    let valor15 = '0';
    valor15 = this.selectedBoton2;

    let valor16 = '0';
    valor16 = this.selectedBoton3;

    let valor33 = '0';
    valor33 = this.selectedBoton4;

    let valor17 = false;
    let valor171 = '0';
    if (this.selectedTriaje == '1') {
      valor17 = true;
      valor171 = '1';
    }

    let valor18 = '';
    if (this.doc_boton1.value != '') {
      valor18 = this.doc_boton1.value;
    }

    let valor19 = '';
    if (this.doc_boton2.value != '') {
      valor19 = this.doc_boton2.value;
    }

    let valor20 = '';
    if (this.doc_boton3.value != '') {
      valor20 = this.doc_boton3.value;
    }

    let valor34 = '';
    if (this.doc_boton4.value != '') {
      valor34 = this.doc_boton4.value;
    }

    let valor21 = false;
    let valor22 = '0';
    if (this.selectedCenters == '1') {
      valor21 = true;
      valor22 = '1';
    }


    let valor23 = false;
    let valor24 = '0';
    if (this.selectedinputAutocomplete == '1') {
      valor23 = true;
      valor24 = '1';
    }

    let valor25 = '0';
    valor25 = this.selectedPopUp

    //Value of selectedPolitica is asiigned to valor26.
    let valor26 = '0';
    valor26 = this.selectedPolitica;

    //Valor27 is the path of document.
    let valor27 = '';
    if (this.doc_politica.value != '') {
      valor27 = this.doc_politica.value;
    }

    //Valor28 is the url of politicas.
    let valor28 = '';
    if (this.rutapolitica.value != '') {
      valor28 = this.rutapolitica.value;
    }

    //Value of selectedPoliticaCliente is asiigned to valor29.
    let valor29 = '0';
    valor29 = this.selectedPoliticaCliente;

    //Valor30 is the path of document.
    let valor30 = '';
    if (this.doc_politica_cliente.value != '') {
      valor30 = this.doc_politica_cliente.value;
    }

    //Valor31 is the url of politica cliente.
    let valor31 = '';
    if (this.rutapoliticacliente.value != '') {
      valor31 = this.rutapoliticacliente.value;
    }
    this.configuracionAvanzadaService
      .updateAdvancedParam(
        this.auth.getSociedad(),
        valor1,
        valor2,
        valor3,
        valor4,
        valor51,
        valor6,
        valor7,
        valor8,
        valor9,
        valor10,
        valor11,
        valor12,
        valor13,
        valor14,
        valor15,
        valor16,
        valor171,
        valor22,
        valor24, /*,valor18,valor19,valor20*/
        valor25,
        valor26,
        valor27,
        valor28,
        valor29,
        valor30,
        valor31,
        valor33,
        valor34
      )
      .subscribe((res) => {
        if (res == 200) {
          /*console.log("valor10",valor10);
          console.log("valor14",valor14);
          console.log("valor15",valor15);
          console.log("valor16",valor16);
          console.log("valor12",this.validatingForm.get('fich1').value);
          console.log("valor18",this.validatingForm.get('fich2').value);
          console.log("valor19",this.validatingForm.get('fich3').value);
          console.log("valor20",this.validatingForm.get('fich4').value);   */
          if (valor10 == '0' && this.validatingForm.get('fich1').value != '') {
            this.subirDoc('1');
          }
          if (valor14 == '1' && this.validatingForm.get('fich2').value != '') {
            this.subirDoc('2');
          }
          if (valor15 == '1' && this.validatingForm.get('fich3').value != '') {
            this.subirDoc('3');
          }
          if (valor16 == '1' && this.validatingForm.get('fich4').value != '') {
            this.subirDoc('4');
          }
          if (valor26 == '0' && this.validatingForm.get('fich5').value != '') {
            this.subirDoc('5');
          }
          if (valor29 == '0' && this.validatingForm.get('fich6').value != '') {
            this.subirDoc('6');
          }
          //Cambiará el valor del Observable y mostrará el menú centros según el valor
          if (valor22 == '1') {
            this.auth.openSidebar(true); //muestra el menú
          } else {
            this.auth.openSidebar(false); //No muestra el menú
          }

          this.toastr.success(
            this.translate.instant('texto_290'),
            this.translate.instant('texto_292')
          );
          this.auth.setAnonimo(valor1);
          this.auth.setRemitente(valor2);
          this.auth.setConsultas(valor3);
          this.auth.setAviso(valor4);
          this.auth.setClausulas(valor5);
          this.auth.setConflicto(valor6);
          this.auth.setDiasInves(valor7);
          this.auth.setDiasDecisor(valor8);
          this.auth.setDiasCompliance(valor9);
          this.auth.setTerminos(valor10);
          this.auth.setDocTerminos(valor11);
          this.auth.setRuta(valor12);
          this.auth.setDiasverdenuncia(valor13);
          this.auth.setBoton1(valor14);
          this.auth.setBoton2(valor15);
          this.auth.setBoton3(valor16);
          this.auth.setPopUp(valor25);
          this.auth.setTriaje(valor17);
          this.auth.setCentro(valor21); //asignamos valor al centro
          this.auth.setInputAutocomplete(valor23);
          this.auth.setPolitica(valor26);
          this.auth.setDocPolitica(valor27);
          this.auth.setPoliticaRuta(valor28);
          this.auth.setPoliticaCliente(valor29);
          this.auth.setDocPoliticaCliente(valor30);
          this.auth.setPoliticaCliRuta(valor31);
          /*this.auth.setDocBoton1(valor18);
          this.auth.setDocBoton2(valor19);
          this.auth.setDocBoton3(valor20); */
        } else {
          this.toastr.error(
            this.translate.instant('texto_291'),
            this.translate.instant('texto_292')
          );
        }
        this.router
          .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/dashboard/layout/advanced-setting']);
          });
      });
  }

  guardarColores() {
    let valor1 = '';
    if (this.colorprincipal.value != '') {
      valor1 = this.colorprincipal.value;
    }
    let valor2 = '';
    if (this.colorsecundario.value != '') {
      valor2 = this.colorsecundario.value;
    }
    let valor3 = '';
    if (this.colorbgseccion2.value != '') {
      valor3 = this.colorbgseccion2.value;
    }
    let valor4 = '';
    if (this.colorbgseccion3.value != '') {
      valor4 = this.colorbgseccion3.value;
    }
    let valor5 = '';
    if (this.colortxtbtn.value != '') {
      valor5 = this.colortxtbtn.value;
    }
    let valor6 = '';
    if (this.colortxtseccion1.value != '') {
      valor6 = this.colortxtseccion1.value;
    }
    let valor7 = '';
    if (this.colortxtseccion2.value != '') {
      valor7 = this.colortxtseccion2.value;
    }
    let valor8 = '';
    if (this.colortxtseccion3.value != '') {
      valor8 = this.colortxtseccion3.value;
    }
    let valor9 = '';
    if (this.colortxttitulo.value != '') {
      valor9 = this.colortxttitulo.value;
    }
    let valor10 = '';
    if (this.colorbgseccion1.value != '') {
      valor10 = this.colorbgseccion1.value;
    }
    let valor11 = '';
    if (this.colormenulateral.value != '') {
      valor11 = this.colormenulateral.value;
    }
    let valor12 = '';
    if (this.colortxtcabeceras.value != '') {
      valor12 = this.colortxtcabeceras.value;
    }
    let valor13 = '';
    if (this.colortxtbotones.value != '') {
      valor13 = this.colortxtbotones.value;
    }
    let valor14 = '';
    if (this.colortxtFondo.value != '') {
      valor14 = this.colortxtFondo.value;
    }
    this.configuracionAvanzadaService
      .updateAdvancedParam3(
        this.auth.getSociedad(),
        valor1,
        valor2,
        valor3,
        valor4,
        valor5,
        valor6,
        valor7,
        valor8,
        valor9,
        valor10,
        valor11,
        valor12,
        valor13,
        valor14
      )
      .subscribe((res) => {
        if (res == 200) {
          this.toastr.success(
            this.translate.instant('texto_290'),
            this.translate.instant('texto_292')
          );
          this.auth.setColorprincipal(valor1);
          this.auth.setColorsecundario(valor2);
          this.auth.setColorbgseccion2(valor3);
          this.auth.setColorbgseccion3(valor4);
          this.auth.setColortxtbtn(valor5);
          this.auth.setColortxtseccion1(valor6);
          this.auth.setColortxtseccion2(valor7);
          this.auth.setColortxtseccion3(valor8);
          this.auth.setColortxttitulo(valor9);
          this.auth.setColorbgseccion1(valor10);
          this.auth.setColormenulateral(valor11);
          this.auth.setColortxtcabeceras(valor12);
          this.auth.setColortxtbotones(valor13);
          window.location.reload();
        } else {
          this.toastr.error(
            this.translate.instant('texto_291'),
            this.translate.instant('texto_292')
          );
          this.router
            .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/dashboard/layout/advanced-setting']);
            });
        }
      });
  }

  guardarDatosCorreo() {
    let valor1 = '';
    if (this.nremitente.value != '') {
      valor1 = this.nremitente.value;
    }
    let valor2 = '';
    if (this.remitente.value != '') {
      valor2 = this.remitente.value;
    }
    let valor3 = '';
    if (this.srv_mail.value != '') {
      valor3 = this.srv_mail.value;
    }
    let valor4 = '';
    if (this.puerto.value != '') {
      valor4 = this.puerto.value;
    }
    let valor5 = '';
    if (this.usr_mail.value != '') {
      valor5 = this.usr_mail.value;
    }
    let valor6 = '';
    if (this.pass_mail.value != '') {
      valor6 = this.pass_mail.value;
    }
    let valor7 = '0';
    if (this.ssl.value == true) {
      valor7 = '1';
    }
    let valor8 = '0';
    if (this.auth_mail.value == true) {
      valor8 = '1';
    }
    this.configuracionAvanzadaService
      .updateAdvancedParam2(
        this.auth.getSociedad(),
        valor1,
        valor2,
        valor3,
        valor4,
        valor5,
        valor6,
        valor7,
        valor8
      )
      .subscribe((res) => {
        if (res == 200) {
          this.toastr.success(
            this.translate.instant('texto_290'),
            this.translate.instant('texto_292')
          );
          this.auth.setNremitente(valor1);
          this.auth.setRemitente(valor2);
          this.auth.setSrv_mail(valor3);
          this.auth.setPuerto(valor4);
          this.auth.setUsr_mail(valor5);
          this.auth.setPass_mail(valor6);
          this.auth.setSsl(valor7);
          this.auth.setAuth_mail(valor8);
        } else {
          this.toastr.error(
            this.translate.instant('texto_291'),
            this.translate.instant('texto_292')
          );
        }
        this.router
          .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/dashboard/layout/advanced-setting']);
          });
      });
  }

  guardarMultisociedad() {
    let valor1 = this.multi.value ? '1' : '0';
    let valor2 = this.tieneSSO.value ? '1' : '0';
    let valor3 = this.archivardenuncia.value ? '1' : '0';
    let valor4 = this.idPersonalizableEnabled.value ? '1' : '0';
    let valor5 = this.gAnalyticsEnabled.value ? '1' : '0';

    this.configuracionAvanzadaService.updateAdvancedParam4(this.auth.getSociedad(), valor1, valor2, valor3, valor4, valor5)
      .subscribe((res) => {
        if (res == 200) {
          localStorage.setItem('idPersonalizable', valor4)
          this.toastr.success(this.translate.instant('texto_290'),
            this.translate.instant('texto_292')
          );
          this.auth.setTieneSSO(valor2);
          localStorage.setItem("HabSSO", valor2);
          // Llamar al nuevo método para actualizar saml_enabled
          this.configuracionAvanzadaService.updateSamlEnabled(this.auth.getSociedad(), valor2)
            .subscribe((res) => {
              if (res == 200) {
                // Actualizar el valor de saml_enabled en la autenticación
                this.auth.setsaml_enabled(valor2);
              }
              this.router.navigateByUrl('/RefreshComponent', {
                skipLocationChange: true,
              })
                .then(() => {
                  this.router.navigate(['/dashboard/layout/advanced-setting']);
                });
            });
        }
      });
  }

  guardarTextos() {
    let idioma = this.auth.getLanguage();
    if (idioma == '' || idioma == null) {
      idioma = 'es';
    }
    let valor1 = '';
    let valor2 = '';
    //console.log(this.texto_value.value);
    //console.log(this.id_texto.value);
    if (this.texto_value.value != '') {
      valor1 = this.id_texto.value;
      valor2 = this.texto_value.value;
    }
    this.configuracionAvanzadaService
      .updateTextos(valor1, valor2, idioma, this.auth.getSociedad())
      .subscribe((res) => {
        if (res == 200) {
          this.configuracionAvanzadaService
            .generarFicheroTextos(idioma, this.auth.getSociedad())
            .subscribe((res) => {
              if (res == 200) {
                this.toastr.success(
                  this.translate.instant('texto_525'),
                  this.translate.instant('texto_527')
                );
                this.auth.setId_texto(valor1);
                this.auth.setTexto(valor2);
                this.router
                  .navigateByUrl('/RefreshComponent', {
                    skipLocationChange: true,
                  })
                  .then(() => {
                    this.router.navigate([
                      '/dashboard/layout/advanced-setting',
                    ]);
                  });
              } else {
                this.toastr.error(
                  this.translate.instant('texto_526'),
                  this.translate.instant('texto_527')
                );
              }
            });
        } else {
          this.toastr.error(
            this.translate.instant('texto_526'),
            this.translate.instant('texto_527')
          );
        }
        this.router
          .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/dashboard/layout/advanced-setting']);
          });
      });
  }

  guardarClausulas() {
    let idioma = this.auth.getLanguage();
    if (idioma == '' || idioma == null) {
      idioma = 'es';
    }
    let valor1 = '';
    let valor2 = '';
    //console.log(this.texto_value.value);
    //console.log(this.id_texto.value);
    if (this.clausula_value.value != '') {
      valor1 = this.id_clausula.value;
      valor2 = this.clausula_value.value;
    }
    this.configuracionAvanzadaService
      .updateClausulas(valor1, valor2, this.auth.getSociedad())
      .subscribe((res) => {
        if (res == 200) {
          this.toastr.success(
            this.translate.instant('texto_528'),
            this.translate.instant('texto_530')
          );
          this.auth.setId_clausula(valor1);
          this.auth.setClausula(valor2);
        } else {
          this.toastr.error(
            this.translate.instant('texto_529'),
            this.translate.instant('texto_530')
          );
        }
        this.router
          .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/dashboard/layout/advanced-setting']);
          });
      });
  }
  guardarSSO() {
    let valor1 = this.saml_enabled.value ? '1' : '0';
    let valor2 = this.saml_idp_entity_id.value || '';
    let valor3 = this.saml_idp_sso_url.value || '';
    let valor4 = this.saml_idp_slo.value || '';
    let valor5 = this.saml_idp_cert.value || '';
    let valor6 = this.saml_idp_cert2.value || '';
    let valor7 = this.saml_mapping_cn.value || '';
    let valor8 = this.saml_mapping_sn.value || '';
    let valor9 = this.saml_mapping_email.value || '';
    let valor10 = this.saml_mapping_phone.value || '';
    let valor11 = this.saml_mapping_national_id.value || '';
    let valor12 = this.saml_jit.value ? '1' : '0';
    let valor13 = this.saml_slo.value ? '1' : '0';
    let valor14 = this.saml_force_login.value ? '1' : '0';
    let valor15 = this.saml_debug.value ? '1' : '0';
    let valor16 = this.saml_sp_entity_id.value || '';
    let valor17 = this.saml_sp_pk.value || '';
    let valor18 = this.saml_sp_cert.value || '';
    let valor19 = this.selectedsaml_sp_nameid_format || '0';

    this.configuracionAvanzadaService
      .updateSSOParam(
        this.auth.getSociedad(),
        valor1,
        valor2,
        valor3,
        valor4,
        valor5,
        valor6,
        valor7,
        valor8,
        valor9,
        valor10,
        valor11,
        valor12,
        valor13,
        valor14,
        valor15,
        valor16,
        valor17,
        valor18,
        valor19
      )
      .subscribe((res) => {
        if (res == 200) {
          this.toastr.success(
            this.translate.instant('texto_290'),
            this.translate.instant('texto_292')
          );
          // Actualizar el valor de saml_enabled en la autenticación
          this.auth.setsaml_enabled(valor1);
        } else {
          this.toastr.error(
            this.translate.instant('texto_291'),
            this.translate.instant('texto_292')
          );
        }
        this.router
          .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/dashboard/layout/advanced-setting']);
          });
      });
  }

  get f() {
    return this.validatingFormImages.controls;
  }

  onFileChange(event, fichero) {
    if (event.target.files.length > 0) {
      //console.log(" ---- fichero:" + fichero);
      const file = event.target.files[0];
      this.validatingFormImages.patchValue({
        fileSource: file,
        fich: fichero,
      });
      this.subirImagenes();
    }
  }

  onFileChangeII(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.validatingFormIdiomas.patchValue({
        fileSourceIdioma: file,
      });
      //this.subirIconoIdioma();
    }
  }

  openImg(url: string) {
    window.open(url, '_blank');
  }

  openDoc(url: string) {
    window.open(url, '_blank');
  }

  onFileChange2(event, fichero) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.validatingForm.patchValue({
        fileSource1: file,
        fich1: fichero,
      });
      //this.subirDoc();
    }
  }

  onFileChange3(event, fichero) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.validatingForm.patchValue({
        fileSource2: file,
        fich2: fichero,
      });
      //this.subirDoc();
    }
  }

  onFileChange4(event, fichero) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.validatingForm.patchValue({
        fileSource3: file,
        fich3: fichero,
      });
      //this.subirDoc();
    }
  }
  onFileChange5(event, fichero) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.validatingForm.patchValue({
        fileSource4: file,
        fich4: fichero,
      });
      //this.subirDoc();
    }
  }

  /**
   * Handles the change event when a file is selected in an input element.
   * Updates the form control values with the selected file and an additional file information.
   *
   * @param {Event} event - The event triggered by the file input element.
   * @param {any} fichero - doc_politica
   */
  onFileChange6(event: Event, fichero: any): void {
    if ((event.target as HTMLInputElement).files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      this.validatingForm.patchValue({
        fileSource5: file,
        fich5: fichero,
      });
      // this.subirDoc();
    }
  }

  /**
 * Handles the change event when politicas cliente is selected in an input element.
 * Updates the form control values with the selected file and an additional file information.
 *
 * @param {Event} event - The event triggered by the file input element.
 * @param {any} fichero - doc_politica_cliente
 */
  onFileChange7(event: Event, fichero: any): void {
    if ((event.target as HTMLInputElement).files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      this.validatingForm.patchValue({
        fileSource6: file,
        fich6: fichero,
      });
    }
  }


  subirImagenes() {
    const formData = new FormData();
    formData.append('file', this.validatingFormImages.get('fileSource').value);
    formData.append('id_user', this.auth.getIdUser());
    formData.append('fichero', this.validatingFormImages.get('fich').value);
    formData.append('id_sociedad', this.auth.getSociedad());
    //console.log(this.auth.getSociedad() + " ---- fich:" + this.validatingFormImages.get('fich').value);
    this.http
      .post(`${this.PHP_API_SERVER}/api/upload_images.php`, formData)
      .subscribe(
        (res) => {
          if (res == 200) {
            this.toastr.success(
              this.translate.instant('texto_202'),
              this.translate.instant('texto_203')
            );
            /*this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/dashboard/layout/advanced-setting']);
          });*/
            window.location.reload();
            //console.log("res:" + res);
          } else {
            this.toastr.error(
              this.translate.instant('texto_204'),
              this.translate.instant('texto_203')
            );
            this.router
              .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
              .then(() => {
                this.router.navigate(['/dashboard/layout/advanced-setting']);
              });
            //console.log("res:" + res);
          }
        },
        (err) => {
          this.toastr.error(
            this.translate.instant('texto_204'),
            this.translate.instant('texto_203')
          );
          this.router
            .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/dashboard/layout/advanced-setting']);
            });
        }
      );
  }

  subirIconoIdioma(id_idioma: string) {

    const formData = new FormData();
    formData.append(
      'file',
      this.validatingFormIdiomas.get('fileSourceIdioma').value
    );
    //formData.append('id_user', this.auth.getIdUser());
    formData.append('id_idioma', id_idioma);
    this.http
      .post(`${this.PHP_API_SERVER}/api/upload_iconoidioma.php`, formData)
      .subscribe(
        (res) => {
          if (res == 200) {
            this.toastr.success(
              this.translate.instant('texto_660'),
              this.translate.instant('texto_661')
            );
          } else {
            this.toastr.error(
              this.translate.instant('texto_662'),
              this.translate.instant('texto_661')
            );
          }
          this.router
            .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/dashboard/layout/advanced-setting']);
            });
        },
        (err) => {
          this.toastr.error(
            this.translate.instant('texto_662'),
            this.translate.instant('texto_661')
          );
          this.router
            .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/dashboard/layout/advanced-setting']);
            });
        }
      );
  }

  subirDoc(doc) {
    const formData = new FormData();
    if (doc == '1') {
      formData.append('fichero', this.validatingForm.get('fich1').value);
      formData.append('file', this.validatingForm.get('fileSource1').value);
    } else if (doc == '2') {
      formData.append('fichero', this.validatingForm.get('fich2').value);
      formData.append('file', this.validatingForm.get('fileSource2').value);
    } else if (doc == '3') {
      formData.append('fichero', this.validatingForm.get('fich3').value);
      formData.append('file', this.validatingForm.get('fileSource3').value);
    } else if (doc == '4') {
      formData.append('fichero', this.validatingForm.get('fich4').value);
      formData.append('file', this.validatingForm.get('fileSource4').value);
    } else if (doc == '5') {
      formData.append('fichero', this.validatingForm.get('fich5').value);
      formData.append('file', this.validatingForm.get('fileSource5').value);
    } else if (doc == '6') {
      formData.append('fichero', this.validatingForm.get('fich6').value);
      formData.append('file', this.validatingForm.get('fileSource6').value);
    }
    formData.append('id_user', this.auth.getIdUser());
    formData.append('nomboton1', this.translate.instant('texto_467'));
    formData.append('nomboton2', this.translate.instant('texto_487'));
    formData.append('nomboton3', this.translate.instant('texto_489'));
    formData.append('nempresa', this.translate.instant('texto_6'));
    formData.append('id_sociedad', this.auth.getSociedad());
    this.http
      .post(`${this.PHP_API_SERVER}/api/upload_images.php`, formData)
      .subscribe(
        (res) => {
          if (res == 200) {
            this.toastr.success(
              this.translate.instant('texto_202'),
              this.translate.instant('texto_203')
            );
          } else {
            this.toastr.error(
              this.translate.instant('texto_204'),
              this.translate.instant('texto_203')
            );
          }
          this.router
            .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/dashboard/layout/advanced-setting']);
            });
        },
        (err) => {
          this.toastr.error(
            this.translate.instant('texto_204'),
            this.translate.instant('texto_203')
          );
        }
      );
  }

  hideModal() {
    this.IsHiddenForm = false;
  }

  editIdioma(idioma: Idiomas) {
    this.IsHiddenForm = true;

    this.id_idioma = idioma.id_idioma;
    this.n_idioma = idioma.n_idioma;
    this.bandera = idioma.bandera;
    this.comentario = idioma.comentario;
    this.Habilitado = idioma.habilitado;

    this.Habilitadochk = false;
    if (this.Habilitado == '1') {
      this.Habilitadochk = true;
    }

    //console.log("Habilitado: ", this.Habilitado);
    //console.log("Habilitadochk: ", this.Habilitadochk);
  }

  updateIdioma(
    id_idioma: string,
    n_idioma: string,
    comentario: string,
    Habilitado: string
  ) {
    let valor2 = n_idioma;
    if (this.frmnidioma.value != '' && this.frmnidioma.value != n_idioma) {
      valor2 = this.frmnidioma.value;
    }

    let valor3 = Habilitado;
    let valor32 = '0';
    if (this.chkhabilitado.value == true) {
      valor32 = '1';
    }
    if (valor32 != valor3) {
      valor3 = valor32;
    }

    let valor4 = comentario;
    if (
      this.frmcomentario.value != '' &&
      this.frmcomentario.value != comentario
    ) {
      valor4 = this.frmcomentario.value;
    }

    this.configuracionAvanzadaService
      .updateIdioma(id_idioma, valor2, valor4, valor3)
      .subscribe((res) => {
        if (res == 200) {

          if (this.validatingFormIdiomas.get('fileSourceIdioma').value != '') {
            this.subirIconoIdioma(id_idioma);
          }
          this.toastr.success(
            this.translate.instant('texto_663'),
            this.translate.instant('texto_661')
          );
        } else {
          this.toastr.error(
            this.translate.instant('texto_664'),
            this.translate.instant('texto_661')
          );
        }
        this.router
          .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/dashboard/layout/advanced-setting']);
          });
      });
  }


  /**
   * Custom validation function form emails separated by ";"
   * @param control field value
   * @returns
   */
  validateEmails(control: AbstractControl): { [key: string]: any } | null {
    const emailList = control.value ? control.value.split(';').map(email => email.trim()) : [];
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Search for invalid emails
    const invalidEmails = emailList.filter(email => !emailPattern.test(email));

    // If there are email invalids, return an object with the error
    if (invalidEmails.length > 0) {
      return { 'invalidEmails': invalidEmails.join(', ') };
    }

    // If there are no errors, return null
    return null;
  }
}
