import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Personal } from 'src/app/shared/models/formData.model';
import { FormDataService } from 'src/app/enviar-denuncia/services/formData.service';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'mt-wizard-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css'],
})
export class PersonalComponent implements OnInit {
  form: any;
  title = 'texto_357';
  denuncia: DatosDenuncia[];
  personalList: Personal[] = [];
  //  Temporarily holds the information of a new witness before adding it to the personalList array.
  testigo: Personal = new Personal();

  // Define a local reference to the form using @ViewChild
  @ViewChild('personalForm') personalForm: NgForm;

  constructor(
    private router: Router,
    private formDataService: FormDataService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    //get the list of witnesses
    this.personalList = this.formDataService.getPersonal();
  }

  //Method to validate and add new personal information to a list, then reset the form
  addPersonal() {
    if (this.personalForm.valid) {
      const { firstName, lastName, email, phone, description } = this.testigo;
      const newTestigo: Personal = { firstName, lastName, email, phone, description };
      this.personalList.push(newTestigo);
      this.personalForm.reset();
    }
  }
  // Method to delete a witness from the list based on the given index
  deletePersonal(index: number) {
    this.personalList.splice(index, 1);
  }

  save(form: any): boolean {
    if (!form.valid) {
        return false;
    }

    this.formDataService.setPersonal(this.personalList);
    return true;
}

goToPrevious(form: any) {
  if (this.save(form)) {
      // Navigate to the personal page
      this.router.navigate(['/enviar-denuncia/datos-denuncia']);
  }
}

  goToNext(form: any) {
    if (this.save(form)) {
        // Navigate to the work page
        this.router.navigate(['/enviar-denuncia/archivos']);
    }
}

  hide() {
    this.auth.logoutSimple();
    this.router
      .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/inicio']);
      });
  }


  // Function to check if any field in the form has data and if email and phone are valid
  isFormFilled(): boolean {
    const { firstName, lastName, email, phone, description } = this.testigo;
    return (
      (firstName && firstName.trim() !== '') ||
      (lastName && lastName.trim() !== '') ||
      (email && /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email)) ||
      (phone && /^[0-9]{1,25}$/.test(phone)) ||
      (description && description.trim() !== '')
    );
  }

}
