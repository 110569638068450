import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConfiguracionAvanzadaService } from 'src/app/dashboard/services/configuracion-avanzada.service';
import { Parametros } from 'src/app/model/parametros';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-id-cliente-ganalytics',
  templateUrl: './id-cliente-ganalytics.component.html',
  styleUrls: ['./id-cliente-ganalytics.component.css']
})
export class IdClienteGanalyticsComponent implements OnInit {

  validatingForm: FormGroup;
  gAnalyticsEnabled: boolean = false;
  idClienteAnalytics: string = ''; 
  idClienteAnalyticsF: string = ''; //Value from database
  parametros: Parametros;
  esSADM: boolean = false;

  constructor(
    private fb: FormBuilder,
    private configuracionAvanzadaService: ConfiguracionAvanzadaService,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router) { }

  ngOnInit(): void {

    if (localStorage.getItem('esSADM') == '1') {
      this.esSADM = true;
    }
    
    // Initialize the FormGroup with the necessary controls and validations.
    this.validatingForm = this.fb.group({
      idClienteAnalytics: ['', [Validators.required]]
    });

    //Determine if Google Analytics option is enabled
    this.configuracionAvanzadaService
      .searchAdvancedParam(this.auth.getSociedad())
      .subscribe((parametros: Parametros) => {
        this.parametros = parametros;
        this.idClienteAnalyticsF = this.parametros[78].valor;
        if (this.parametros[77].valor == '1') {
          this.gAnalyticsEnabled = true;
        } else {
          this.gAnalyticsEnabled = false;
        }
      })
  }

  submit(): void {
    if (this.validatingForm.valid) {
      const idCliente = this.validatingForm.get('idClienteAnalytics').value;

      this.configuracionAvanzadaService.saveIdClientGAnalytics(this.auth.getSociedad(), idCliente).subscribe(res => {
        if (res && res.code === 200) {
          this.toastr.success(
            this.translate.instant('texto_290'),
            this.translate.instant('texto_292')
          );
        } else {
          this.toastr.error(
            this.translate.instant('texto_291'),
            this.translate.instant('texto_292')
          );
        }
        this.router
          .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/dashboard/layout/advanced-setting']);
          });
      })
    } 
  }
}
