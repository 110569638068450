<section style="position: absolute; width: 100%; z-index: 1;" class="image-container line-spacing"
    style="background-image: url(/assets/img/inicio/inicio-1.jpg);">
    <div class="container" style="padding: 0;">
        <div class="board">
            <button type="button" style="z-index:100; float: right;" class="btn btn-dark" (click)="hide();"
                [attr.aria-label]="'texto_735' | translate">X</button>

            <!-- Breadcrumbs -->
            <div class="breadcrum-div">
                <nav aria-label="breadcrumb" class="nav">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' | translate }}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ 'texto_717' | translate }}</li>
                    </ol>
                </nav>
            </div>
            <!-- End Breadcrumbs -->

            <!-- Navigation Area (Circular Tabs) -->
            <msw-navbar></msw-navbar>
            <!-- End Navigation Area (Circular Tabs) -->

            <!-- Content Area -->
            <div class="tab-content">

                <!-- Title and Subtitle -->
                <div class="text-center mb-4">
                    <h2 _ngcontent-pnl-c368 class="head text-center">{{title | translate }}</h2>
                </div>

                <form [formGroup]="validatingForm" style="margin-left: 25px; margin-right: 25px;">

                    <!-- Texts 768 -->
                    <div class="row justify-content-center">
                        <div class="col-md-6">
                            <div style="text-align: right;">
                                <span style="font-size: 0.75rem; display:block; line-height: 1.5; text-align:justify">
                                    {{ 'texto_768' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>


                    <div class="row justify-content-center">
                        <div class="col-md-6 mx-auto">
                            <div class="form-group">
                                <label class="control-label" for="passwordComplaint"
                                    style="display: block; text-align: left; margin-bottom: 5px;">
                                    {{ 'texto_387' | translate }}
                                </label>
                                <div style="position: relative;">
                                    <input formControlName="passwordComplaint"
                                        [type]="passwordVisible ? 'text' : 'password'" class="form-control"
                                        id="passwordComplaint" placeholder="{{ 'texto_387' | translate }}">
                                    <i class="fa"
                                        [ngClass]="{'fa-eye': !passwordVisible, 'fa-eye-slash': passwordVisible}"
                                        (click)="togglePasswordVisibility()"
                                        style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer;"></i>
                                    <mdb-success
                                        style="position: absolute; right: 0; top: 100%; margin-top: 5px; font-size: 0.7rem !important;"
                                        *ngIf="validatingForm.get('passwordComplaint')?.valid">
                                        {{ 'texto_62' | translate }}
                                    </mdb-success>
                                    <mdb-error
                                        style="position: absolute; right: 0; top: 100%; margin-top: 5px; font-size: 0.7rem !important;"
                                        *ngIf="validatingForm.get('passwordComplaint')?.invalid && (validatingForm.get('passwordComplaint')?.dirty || validatingForm.get('passwordComplaint')?.touched)">
                                        {{ 'texto_61' | translate }}
                                    </mdb-error>
                                </div>
                                <!-- Details password -->
                                <div class="d-flex justify-content-center" *ngIf="validatingForm.get('passwordComplaint')?.invalid && (validatingForm.get('passwordComplaint')?.dirty || validatingForm.get('passwordComplaint')?.touched)">
                                    <div class="card mt-3 text-center">
                                        <div class="card-body">
                                            <ul class="password-criteria list-unstyled">
                                                <li [ngClass]="{'valid': hasUpperCase, 'invalid': !hasUpperCase}">
                                                    <i class="fa"
                                                        [ngClass]="{'fa-check-circle': hasUpperCase, 'fa-times-circle': !hasUpperCase}"></i>
                                                    {{ 'texto_791' | translate }}
                                                </li>
                                                <li [ngClass]="{'valid': hasLowerCase, 'invalid': !hasLowerCase}">
                                                    <i class="fa"
                                                        [ngClass]="{'fa-check-circle': hasLowerCase, 'fa-times-circle': !hasLowerCase}"></i>
                                                    {{ 'texto_792' | translate }}
                                                </li>
                                                <li [ngClass]="{'valid': hasNumber, 'invalid': !hasNumber}">
                                                    <i class="fa"
                                                        [ngClass]="{'fa-check-circle': hasNumber, 'fa-times-circle': !hasNumber}"></i>
                                                    {{ 'texto_793' | translate }}
                                                </li>
                                                <li [ngClass]="{'valid': hasSpecialChar, 'invalid': !hasSpecialChar}">
                                                    <i class="fa"
                                                        [ngClass]="{'fa-check-circle': hasSpecialChar, 'fa-times-circle': !hasSpecialChar}"></i>
                                                    {{ 'texto_794' | translate }}
                                                </li>
                                                <li [ngClass]="{'valid': hasMinLength, 'invalid': !hasMinLength}">
                                                    <i class="fa"
                                                        [ngClass]="{'fa-check-circle': hasMinLength, 'fa-times-circle': !hasMinLength}"></i>
                                                    {{ 'texto_795' | translate }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Details password -->
                            </div>
                        </div>
                    </div>

                    <!-- Repeated password input -->
                    <div class="row justify-content-center">
                        <div class="col-md-6 mx-auto">
                            <div class="form-group">
                                <label class="control-label" for="repeatedPasswordComplaint"
                                    style="display: block; text-align: left; margin-bottom: 5px;">
                                    {{ 'texto_770' | translate }}
                                </label>
                                <div style="position: relative;">
                                    <input formControlName="repeatedPasswordComplaint"
                                        [type]="repeatedPasswordVisible ? 'text' : 'password'" class="form-control"
                                        id="repeatedPasswordComplaint" placeholder="{{ 'texto_770' | translate }}">
                                    <i class="fa"
                                        [ngClass]="{'fa-eye': !repeatedPasswordVisible, 'fa-eye-slash': repeatedPasswordVisible}"
                                        (click)="toggleRepeatedPasswordVisibility()"
                                        style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer;"></i>
                                    <mdb-success
                                        style="position: absolute; right: 0; top: 100%; margin-top: 5px; font-size: 0.7rem !important;"
                                        *ngIf="validatingForm.get('repeatedPasswordComplaint')?.valid && validatingForm.get('repeatedPasswordComplaint')?.value === validatingForm.get('passwordComplaint')?.value">
                                        {{ 'texto_62' | translate }}
                                    </mdb-success>
                                    <mdb-error
                                        style="position: absolute; right: 0; top: 100%; margin-top: 5px; font-size: 0.7rem !important;"
                                        *ngIf="validatingForm.get('repeatedPasswordComplaint')?.dirty && validatingForm.get('repeatedPasswordComplaint')?.value && validatingForm.get('repeatedPasswordComplaint')?.value !== validatingForm.get('passwordComplaint')?.value">
                                        {{ 'texto_61' | translate }}
                                    </mdb-error> 
                                </div>
                                <!-- Password Details -->
                                <div class="d-flex justify-content-center"
                                    *ngIf="validatingForm.get('repeatedPasswordComplaint')?.dirty && validatingForm.get('repeatedPasswordComplaint')?.value && validatingForm.get('repeatedPasswordComplaint')?.value !== validatingForm.get('passwordComplaint')?.value">
                                    <div class="card mt-3 text-center">
                                        <div class="card-body">
                                            <ul class="password-criteria list-unstyled">
                                                <li class="invalid">
                                                    <i class="fa fa-times-circle"></i>
                                                    {{ 'texto_223' | translate }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Email to recover password -->
                    <div *ngIf="!email; else emailIsPresent" class="row justify-content-center">
                        <div class="col-md-6 mx-auto">
                            <span style="font-size: 0.75rem; display:block; line-height: 1.5; text-align:justify">
                                {{ 'texto_769' | translate }}
                            </span>
                            <div class="form-group">
                                <label class="control-label" for="email"
                                    style="display: block; text-align: left; margin-bottom: 5px;">
                                    {{ 'texto_82' | translate }}
                                </label>
                                <div style="position: relative;">
                                    <input formControlName="email" class="form-control" id="email"
                                        placeholder="{{ 'texto_82' | translate }}">
                                    <mdb-error id="email-error" role="alert"
                                        *ngIf="validatingForm.get('email')?.invalid && (validatingForm.get('email')?.dirty || validatingForm.get('email')?.touched)"
                                        style="position: absolute; right: 0; top: 100%; font-size: 0.75rem; color: red;">
                                        {{ 'texto_61' | translate }}
                                    </mdb-error>
                                    <mdb-success
                                        style="position: absolute; right: 0; top: 100%; margin-top: 5px; font-size: 0.7rem !important;"
                                        *ngIf="validatingForm.get('email')?.valid && validatingForm.get('email')?.value">
                                        {{ 'texto_62' | translate }}
                                    </mdb-success>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #emailIsPresent>
                        <div class="row justify-content-center">
                            <div class="col-md-6 mx-auto">
                                <span style="font-size: 0.75rem; display:block; line-height: 1.5; text-align:justify">
                                    {{ 'texto_785' | translate }}
                                </span>
                            </div>
                        </div>
                    </ng-template>

                    <!-- Save Button -->
                    <div class="text-center mt-4">
                        <button [disabled]="!validatingForm.valid && validatingForm.get('repeatedPasswordComplaint')?.value !== validatingForm.get('passwordComplaint')?.value"
                        (click)="crearDenuncia(validatingForm)" mdbBtn
                            rounded="true" class="btn-next" mdbWavesEffect>
                            {{ 'texto_384' | translate }}
                        </button>
                    </div>
                    <input type="hidden" id="finaliced" name="finaliced" value="">
                </form>
            </div>
        </div>
    </div>
</section>