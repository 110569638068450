import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Users } from '../../../model/users';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../services/login.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  users: Users;
  email: string;
  password: string;
  HabilitarSSO: boolean = false;
  loginOk: boolean = false;
  loginOkBlock: boolean = false;
  loginNoUser: boolean = false;
  language: string;
  incorrectPassword: boolean = false;
  showRecoveryFields = false;
  emailVerified = false; // Agregamos una variable para verificar si el correo fue verificado


  recoveryEmailFormControl: FormControl;  


  @ViewChild("myModalInfo", { static: false }) myModalInfo: TemplateRef<any>;

  constructor(
    private titleService: Title,
    private loginService: LoginService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

    this.translate.get('texto_252', { value: this.language }).subscribe((text: string) => {
      this.titleService.setTitle(text);
    });
  }

  validatingForm: FormGroup;
  ngOnInit() {
    if (this.language == "" || this.language == null) {
      this.language = 'es';
    }
    this.translate.setDefaultLang(this.language);

    if (localStorage.getItem("HabSSO") == "1") {
      this.HabilitarSSO = true;
    } else {
      this.HabilitarSSO = false;
    }

    this.recoveryEmailFormControl = new FormControl('', [
      Validators.email,
      Validators.required
    ]);

    this.validatingForm = new FormGroup({
      loginFormModalEmail: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      loginFormModalPassword: new FormControl('', [Validators.required])
    });
  }

  showRecovery() {
    this.showRecoveryFields = true;
    this.loginOkBlock = false;
    this.loginNoUser = false;
  }

  navigateToButton() {
    const element = document.getElementById('mainContent');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  /**
 * Verifies if the email is registered, and if it is, sends an email to recover the password.
 */
  verifyEmail(): void {
    const email = this.recoveryEmailFormControl.value;
  
    // Check if the email form control is valid
    if (this.recoveryEmailFormControl.valid) {
      // Verify if the email is registered
      this.loginService.verifyEmail(email).subscribe({
        next: (res) => {
          // If the user is locked
          if (res.user.is_lock === "1") {
            this.loginOkBlock = true;
          } else {
            // If the email is registered and not locked, proceed to send the recovery email
            this.loginService.sendEmailToRecoverPassword(email, "restablecer-contrasenia", "tokenPassword").subscribe({
              next: () => {
                this.toastr.info(
                  this.translate.instant('texto_715'),
                  this.translate.instant('texto_194'),
                  {
                    timeOut: 0,
                    closeButton: true,
                  }
                );
              }
            });
            this.loginNoUser = false;
            this.loginOkBlock = false;
          }
        },
        error: (errorResponse) => {
          // If the email is not registered (404) or there is a server error
          if (errorResponse.status === 404 && errorResponse.error.message === "Email not found") {
            this.loginNoUser = true;
          } else {
            //server error
            this.toastr.error(
              this.translate.instant('Server Error')
            );
          }
        }
      });
    }
  }
  

  login() {

    this.loginService.login(this.loginFormModalEmail.value, this.loginFormModalPassword.value)
      .subscribe({
        next: () => {
          this.toastr.info(
            this.translate.instant('texto_715'),
            this.translate.instant('texto_194'), {
            timeOut: 0,
            closeButton: true,
          });

          this.loginOk = false;
          this.loginNoUser = false;
          this.loginOkBlock = false;
          this.incorrectPassword = false;
          this.showRecoveryFields = false;
        },
        error: (errorResponse) => {
          if (errorResponse.status === 401) {
            if (errorResponse.error.message == "Password Incorrect") {
              this.showIncorrectPassword();
            } else if (errorResponse.error.message == "User incorrect") {
              this.showInvalidUserError();
            } else {
              this.showNotUserError();
            }
          }
          if (errorResponse.status === 423) {
            this.showUserBlockedError();
          }
        }
      });
  }

  private showNotUserError() {
    this.loginOk = false;
    this.loginNoUser = true;
    this.loginOkBlock = false;
    this.incorrectPassword = false;
  }

  private showInvalidUserError() {
    this.loginOk = false;
    this.loginNoUser = true;
    this.loginOkBlock = false;
    this.incorrectPassword = false;
  }

  private showUserBlockedError() {
    this.loginOk = false;
    this.loginNoUser = false;
    this.loginOkBlock = true;
    this.incorrectPassword = false;
  }

  private showIncorrectPassword() {
    this.loginOk = false;
    this.loginNoUser = false;
    this.loginOkBlock = false;
    this.incorrectPassword = true;
  }

  login2() {
    console.log("Invocado login2(). Valor de HabilitarSSO:", this.HabilitarSSO);
    this.loginService.loginSingleSignOn();
  }

  get loginFormModalEmail() {
    return this.validatingForm.get('loginFormModalEmail');
  }

  get loginFormModalPassword() {
    return this.validatingForm.get('loginFormModalPassword');
  }
}