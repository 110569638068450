<button style="background-image: url(/../../assets/img/botones/Boton_Desestimar\ Denuncia.png);" class="btn btn-rounded btn-cabecera" matTooltip="{{'texto_174' | translate }}" type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
     <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
        <!--Content-->
        <div class="modal-content">
    
            <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
                <div class="card-header card-header-info">
                  <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_448' | translate }}</h5>
                </div>
                <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()"aria-label="Cerrar modal">X</button> 
                <div>
                  <mat-checkbox id="consulta" name="consulta" [formControl]="consulta" (change)="onCheckboxChange()">{{ 'texto_449' | translate }}</mat-checkbox>
                </div>
                <!-- Mat-Select emails list -->
                <div *ngIf="showSelect">
                  <mat-form-field>
                    <mat-label>{{ 'texto_803' | translate }}</mat-label>
                    <mat-select [formControl]="selectedEmail">
                      <mat-option *ngFor="let email of listEmails" [value]="email">{{ email }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <label class="control-label" for="textoDesestimacion">{{ 'texto_450' | translate }}</label>
                <div class="md-form" >
                    <ngx-wig id="textoDesestimacion" [formControl]="textoDesestimacion" rows="8" [buttons]="'bold, italic, link, underline'" (keyup)="onKey($event)"></ngx-wig>
                    <span style="float: left;font-weight: bold;font-size: 0.75rem">{{contador}} {{ 'texto_69' | translate }} 4000</span>                  
                </div>
                <div class="md-form mb-3">
                    <form [formGroup]="myForm" style="width:100%;display:contens;color:white;margin-top: 26px;">
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="dragFile($event)">
                            <input [(ngModel)]="archivos"
                            formControlName="file"
                            accept=".eml,.msg,.zip .pptx,.pptx,.pptm,.potx,.potm,.ppam,.ppsx,.ppsm,.xlsx,.xlsm,.xltx,.xltm,.xlam,image/gif,image/jpeg,image/jpg,image/png,video/*,audio/*,.pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            id="file" hidden type="file" #fileInput (change)="uploadFile($event)">
                        </div>
                        <div  class="files-list" *ngFor="let file of files;let i= index">
                              <p> {{ file }} </p>
                              <button class="delete-file" (click)="deleteAttachment(i)">
                                <img src="../../assets/img/borrar-fichero.png">
                              </button>
                        </div>
                    </form>
                  </div> 
            </div>
            <div class="text-center mt-3">
                <button (click)="addDesestimacion()" style="color:#fff" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_451' | translate }}</button>
            </div>
        </div>
    </div>
</div>