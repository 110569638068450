import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment"
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs'; //Observable usado para el valor Centros
import { map } from 'rxjs/operators';
import { Users } from '../../model/users';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userSubject: BehaviorSubject<Users>;
  public user: Observable<Users>;

  constructor(private router: Router, private http: HttpClient) { }

  private accessTokenPassword: string | null = null;

  // TODO: Unused user observable
  public get userValue(): Users {
    return this.userSubject.value;
  }

  //Observable que tendrá el valor para mostrar el menú de centros, dependiendo el valor: true OR false
  public sidebarOpened = new BehaviorSubject<boolean>(false);
  sidebarOpened$ = this.sidebarOpened.asObservable();
  openSidebar(value: boolean): void {
    this.sidebarOpened.next(value); //se asigna el valor de true
  }

  sendToken(id_user: string, name: string, surname: string, email: string, photo: string) {
    localStorage.setItem("id_user_session", id_user)
    localStorage.setItem("name_user", name)
    localStorage.setItem("surname_user", surname)
    localStorage.setItem("email_user", email)
    localStorage.setItem("avatar", photo)
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }

  logoutSimple() {
    localStorage.removeItem("access_token2");
    localStorage.removeItem("id_user_session")
    localStorage.removeItem("name_user")
    localStorage.removeItem("surname_user")
    localStorage.removeItem("email_user")
    localStorage.removeItem("avatar")
    localStorage.removeItem("role")
    localStorage.removeItem("sociedad")
    localStorage.removeItem("name_sociedad")
    localStorage.removeItem("language")
    localStorage.removeItem("anonimo")
    localStorage.removeItem("remitente")
    localStorage.removeItem("aviso")
    localStorage.removeItem("consultas")
    localStorage.removeItem("archdenuncia")
    this.router.navigate(["/inicio"]);
    window.location.reload();
  }

  getAccessTokenPassword(): string | null {
    return localStorage.getItem("token_reset_password")
  }

  getAccessTokenPasswordComplaint(): string | null {
    return localStorage.getItem("token_password_compaint")
  }


  getRole() {
    return localStorage.getItem("role")
  }

  getToken() {
    return localStorage.getItem("access_token2")
  }

  setToken(token: string) {
    localStorage.setItem("access_token2", token);
  }

  getRefreshToken() {
    return localStorage.getItem("refresh_token")
  }

  setRefreshToken(token: string) {
    localStorage.setItem("refresh_token", token);
  }

  getIdUser() {
    return localStorage.getItem("id_user_session")
  }

  getNameUser() {
    return localStorage.getItem("name_user")
  }

  setRole(role) {
    localStorage.setItem("role", role);
  }

  setNameUser(name) {
    localStorage.setItem("name_user", name);
  }

  setNameSociedad(name) {
    localStorage.setItem("name_sociedad", name);
  }

  getNameSociedad() {
    return localStorage.getItem("name_sociedad")
  }

  getSurnameUser() {
    return localStorage.getItem("surname_user")
  }

  setSurnameUser(surname) {
    localStorage.setItem("surname_user", surname)
  }

  getEmailUser() {
    return localStorage.getItem("email_user")
  }

  setEmailUser(email) {
    localStorage.setItem("email_user", email)
  }

  getPhotoUser() {
    let photo = localStorage.getItem("avatar");
    if (photo == "" || photo == "null") {
      photo = environment.baseAvatar + "avatar.png";
      localStorage.setItem("avatar", photo)
    }
    return photo;
  }

  setPhotoUser(photo) {
    localStorage.setItem("avatar", photo)
  }

  setSociedad(name) {
    localStorage.setItem("sociedad", name);
  }

  getSociedad() {
    return localStorage.getItem("sociedad")
  }

  getLanguage() {
    return localStorage.getItem("language")
  }

  setLanguage(language) {
    localStorage.setItem("language", language);
  }

  getAnonimo() {
    return localStorage.getItem("anonimo")
  }

  setAnonimo(anonimo) {
    localStorage.setItem("anonimo", anonimo);
  }

  setNremitente(nremitente) {
    localStorage.setItem("nremitente", nremitente);
  }

  setMulti(multi) {
    localStorage.setItem("multi", multi);
  }

  setTieneSSO(tieneSSO) {
    localStorage.setItem("tieneSSO", tieneSSO);
  }


  setRemitente(remitente) {
    localStorage.setItem("remitente", remitente);
  }

  setSrv_mail(srv_mail) {
    localStorage.setItem("srv_mail", srv_mail);
  }

  setPuerto(puerto) {
    localStorage.setItem("puerto", puerto);
  }

  setUsr_mail(usr_mail) {
    localStorage.setItem("usr_mail", usr_mail);
  }

  setPass_mail(pass_mail) {
    localStorage.setItem("pass_mail", pass_mail);
  }

  setSsl(ssl) {
    localStorage.setItem("ssl", ssl);
  }

  setAuth_mail(auth_mail) {
    localStorage.setItem("auth_mail", auth_mail);
  }

  setColorprincipal(colorprincipal) {
    localStorage.setItem("colorprincipal", colorprincipal);
  }

  setColorsecundario(colorsecundario) {
    localStorage.setItem("colorsecundario", colorsecundario);
  }

  setColorbgseccion1(colorbgseccion1) {
    localStorage.setItem("colorbgseccion1", colorbgseccion1);
  }

  setColormenulateral(colormenulateral) {
    localStorage.setItem("colormenulateral", colormenulateral);
  }

  setColortxtcabeceras(colortxtcabeceras) {
    localStorage.setItem("colortxtcabeceras", colortxtcabeceras);
  }

  setColortxtbotones(colortxtbotones) {
    localStorage.setItem("colortxtbotones", colortxtbotones);
  }

  setColorbgseccion2(colorbgseccion2) {
    localStorage.setItem("colorbgseccion2", colorbgseccion2);
  }

  setColorbgseccion3(colorbgseccion3) {
    localStorage.setItem("colorbgseccion3", colorbgseccion3);
  }

  setColortxtbtn(colortxtbtn) {
    localStorage.setItem("colortxtbtn", colortxtbtn);
  }

  setColortxtseccion1(colortxtseccion1) {
    localStorage.setItem("colortxtseccion1", colortxtseccion1);
  }

  setColortxtseccion2(colortxtseccion2) {
    localStorage.setItem("colortxtseccion2", colortxtseccion2);
  }

  setColortxtseccion3(colortxtseccion3) {
    localStorage.setItem("colortxtseccion3", colortxtseccion3);
  }

  setColortxttitulo(colortxttitulo) {
    localStorage.setItem("colortxttitulo", colortxttitulo);
  }

  getConsultas() {
    return localStorage.getItem("consultas")
  }

  setConsultas(consultas) {
    localStorage.setItem("consultas", consultas);
  }

  getAviso() {
    return localStorage.getItem("aviso")
  }

  getCentros() {
    return localStorage.getItem('centros')
  }

  getIdPersonalizable() {
    return localStorage.getItem('idPersonalizable')
  }

  getInputAutocomplete() {
    return localStorage.getItem('inputAutocomplete')
  }

  //Obtiene el valor Centro del localStorage
  getClausulas() {
    return localStorage.getItem("clausulas")
  }

  getConflicto() {
    return localStorage.getItem("conflicto")
  }

  getDiasInves() {
    return localStorage.getItem("diasinves")
  }

  getDiasDecisor() {
    return localStorage.getItem("diasdecisor")
  }

  getDiasCompliance() {
    return localStorage.getItem("diascompliance")
  }

  setAviso(aviso) {
    localStorage.setItem("aviso", aviso);
  }

  setArchdenuncia(archdenuncia) {
    localStorage.setItem("archdenuncia", archdenuncia);
  }

  setClausulas(clausulas) {
    localStorage.setItem("clausulas", clausulas);
  }

  setConflicto(conflicto) {
    localStorage.setItem("conflicto", conflicto);
  }

  setDiasInves(diasinves) {
    localStorage.setItem("diasinves", diasinves);
  }

  setDiasDecisor(diasdecisor) {
    localStorage.setItem("diasdecisor", diasdecisor);
  }

  setDiasCompliance(diascompliance) {
    localStorage.setItem("diascompliance", diascompliance);
  }

  setTerminos(terminos) {
    localStorage.setItem("terminos", terminos);
  }

  setDocTerminos(docterminos) {
    localStorage.setItem("docterminos", docterminos);
  }

  setPolitica(politica) {
    localStorage.setItem("politica", politica);
  }

  setDocPolitica(docpolitica) {
    localStorage.setItem("docpolitica", docpolitica);
  }

  setPoliticaCliente(politicacliente) {
    localStorage.setItem("politicacliente", politicacliente);
  }

  setDocPoliticaCliente(docpoliticacliente) {
    localStorage.setItem("docpoliticacliente", docpoliticacliente);
  }

  setBoton1(boton1) {
    localStorage.setItem("boton1", boton1);
  }

  setDocBoton1(docboton1) {
    localStorage.setItem("docboton1", docboton1);
  }

  setBoton2(boton2) {
    localStorage.setItem("boton2", boton2);
  }

  setDocBoton2(docboton2) {
    localStorage.setItem("docboton2", docboton2);
  }

  setBoton3(boton3) {
    localStorage.setItem("boton3", boton3);
  }


  setPopUp(popUp) {
    localStorage.setItem("popUp", popUp);
  }

  setTriaje(triaje) {
    localStorage.setItem("triaje", triaje);
  }

  //Setea el valor a la variable "centro" en localStorage
  setCentro(centro) {
    localStorage.setItem("centro", centro);
  }

  setInputAutocomplete(input) {
    localStorage.setItem("inputAutocomplete", input);
  }

  setDocBoton3(docboton3) {
    localStorage.setItem("docboton3", docboton3);
  }

  setRuta(ruta) {
    localStorage.setItem("ruta", ruta);
  }

  setPoliticaRuta(rutapolitica) {
    localStorage.setItem("rutapolitica", rutapolitica);
  }

  setPoliticaCliRuta(rutapoliticacliente) {
    localStorage.setItem("rutapoliticacliente", rutapoliticacliente);
  }

  setDiasverdenuncia(diasverdenuncia) {
    localStorage.setItem("diasverdenuncia", diasverdenuncia);
  }

  setId_texto(id_texto) {
    localStorage.setItem("id_texto", id_texto);
  }

  setTexto(texto) {
    localStorage.setItem("texto", texto);
  }

  setId_clausula(id_clausula) {
    localStorage.setItem("id_clausula", id_clausula);
  }

  setClausula(clausula) {
    localStorage.setItem("clausula", clausula);
  }

  setsaml_enabled(saml_enabled) {
    localStorage.setItem("saml_enabled", saml_enabled);
  }
  setsaml_idp_entity_id(saml_idp_entity_id) {
    localStorage.setItem("saml_idp_entity_id", saml_idp_entity_id);
  }
  setsaml_idp_sso_url(saml_idp_sso_url) {
    localStorage.setItem("saml_idp_sso_url", saml_idp_sso_url);
  }
  setsaml_idp_slo(saml_idp_slo) {
    localStorage.setItem("saml_idp_slo", saml_idp_slo);
  }
  setsaml_idp_cert(saml_idp_cert) {
    localStorage.setItem("saml_idp_cert", saml_idp_cert);
  }
  saml_idp_cert2(saml_idp_cert2) {
    localStorage.setItem("saml_idp_cert2", saml_idp_cert2);
  }
  setsaml_mapping_cn(saml_mapping_cn) {
    localStorage.setItem("saml_mapping_cn", saml_mapping_cn);
  }
  setsaml_mapping_sn(saml_mapping_sn) {
    localStorage.setItem("saml_mapping_sn", saml_mapping_sn);
  }
  setsaml_mapping_email(saml_mapping_email) {
    localStorage.setItem("saml_mapping_email", saml_mapping_email);
  }
  setsaml_mapping_phone(saml_mapping_phone) {
    localStorage.setItem("saml_mapping_phone", saml_mapping_phone);
  }
  setsaml_mapping_national_id(saml_mapping_national_id) {
    localStorage.setItem("saml_mapping_national_id", saml_mapping_national_id);
  }
  setsaml_jit(saml_jit) {
    localStorage.setItem("saml_jit", saml_jit);
  }
  setsaml_slo(saml_slo) {
    localStorage.setItem("saml_slo", saml_slo);
  }
  setsaml_force_login(saml_force_login) {
    localStorage.setItem("saml_force_login", saml_force_login);
  }
  setsaml_debug(saml_debug) {
    localStorage.setItem("saml_debug", saml_debug);
  }
  setsaml_sp_entity_id(saml_sp_entity_id) {
    localStorage.setItem("saml_sp_entity_id", saml_sp_entity_id);
  }
  setsaml_sp_pk(saml_sp_pk) {
    localStorage.setItem("saml_sp_pk", saml_sp_pk);
  }
  setsaml_sp_cert(saml_sp_cert) {
    localStorage.setItem("saml_sp_cert", saml_sp_cert);
  }
  setsaml_sp_nameid_format(saml_sp_nameid_format) {
    localStorage.setItem("saml_sp_nameid_format", saml_sp_nameid_format);
  }


  login(username: string, password: string) {
    return this.http.post<any>(`${environment.baseUrl}/api/login`, { username, password }, { withCredentials: true })
      .pipe(map(user => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  apilogout() {
    this.http.post<any>(`${environment.baseUrl}/users/revoke-token`, {}, { withCredentials: true }).subscribe();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }


  refreshToken() {
    return this.http.post<any>(`${environment.baseUrl}/users/refresh-token`, {}, { withCredentials: true })
      .pipe(map((user) => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
  }


  // helper methods

  private refreshTokenTimeout;

  // TODO: erronea implementación de token (caduca con timeout en front), no hay back correspondiente y parece que no se usa
  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
