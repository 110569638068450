import { Component, OnInit, Input, Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
import { Denuncia } from '../model/denuncia';
import { TranslateService } from '@ngx-translate/core';
import { Empresa } from '../model/empresa';
import { Centro } from '../model/centro';
import { Archivos, DatosDenunciante, GrabarDenuncia, ReceptorInicial, TipoHecho } from '../data/formData.model';
import { Relacion } from '../dashboard/models/relacion';
import { Receptor } from '../model/receptor';
import { RolReceptor } from '../model/rolReceptor';
import { FormDataService } from '../data/formData.service';
import { Pin } from '../model/pin';
import { ToastrService } from 'ngx-toastr';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { Router } from '@angular/router';
import { Abogado } from '../model/abogado';
import { Canal } from '../model/canal';
import { SpinnerService } from '../services/spinner.service';
import { Personal } from '../data/formData.model';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { AutocompleteWordService } from '../services/autocomplete-word.service';
import { of } from 'rxjs';
import { Documento } from '../model/documento';
import { HttpClient } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { EnviarDenunciaService } from 'src/app/enviar-denuncia/services/enviar-denuncia.service';

declare const webkitSpeechRecognition: any;

@Injectable()
@Component({
  selector: 'app-add-audio-recorder',
  templateUrl: './add-audio-recorder.component.html',
  styleUrls: ['./add-audio-recorder.component.scss']
})
export class AddAudioRecorderComponent implements OnInit {
  @Input() formData;
  //general variables
  hoy = new Date();
  terminos2: string;
  personal: Personal;
  documentConditions: string; //document terms and conditions
  url: string;
  title = 'texto_78';
  IsAnonimo: boolean = false;
  canal: Canal;
  IsHidden = true;
  society: string;
  relaciones: Relacion[]; //model Relacion
  pin: Pin; //save pin from id complaint
  pin_code: string; //save pin code from complaint
  denuncia: DatosDenuncia[]; //Model Datosdenuncia
  isExterna: boolean = false; //saves the type of complaint management
  abogados: Abogado[]; //Model Abogados
  denuncias: Denuncia[]; //Model Denuncias
  Showbutton: boolean = true; //show button 'Proponer otro receptor'
  Showselect: boolean = false; //show select 'proponer otro receptor'
  typeButton: boolean = false; //type of button to be displayed
  ShowSetReceptor: boolean = false; //Show proposed recipient automatically
  Receptoraleatorio: string;
  showSelected: number = 0; //Indicator to show the selected centers
  triaje: string = "";
  dataComplaint: GrabarDenuncia;
  complaints: Denuncia[];
  companies: Empresa[];
  centers: Centro[]; //obtains existing centers
  tipoHecho: TipoHecho;
  selectedCenter: null | string; //table centros
  complaintType: null | string; //table complaint type
  relationType: null | string; //table relatiom type
  selectedCompany: string;
  tipoDenunciantes: Relacion;
  fase_inicial: string;
  receptor: Receptor[];
  receptorInicial: ReceptorInicial;
  receptorPropuesto: RolReceptor;
  receptores: RolReceptor[];
  relacion: DatosDenunciante;
  description: string = ""; //description complaint type
  //Show Menus Variables
  showMenu1 = true; //Show Menú 1 (Selected Empresa, Tipo Denuncia)
  showMenu2 = false; //Show Menú 2 (Data User)
  showMenu3 = false; //Show Menú 3 (Date and CheckBox)
  showMenu4: boolean = false; //Show menú 4 (Upload Documents)
  showMenu5: boolean = false; //Show menú 5 (Complaint Data)
  showButtonMenu2 = false; //boton siguiente menu 2
  showButtonMenu1 = true; //boton siguiente menu 1
  //Audio recording variables
  idiomas = [
    { value: 'es-ES', label: 'Español (ES)' },
    { value: 'en-US', label: 'Inglés (US)' },
    { value: 'fr-FR', label: 'Francés (FR)' },
    { value: 'it-IT', label: 'Italiano (IT)' },
    { value: 'pt-PT', label: 'Portugués (PT)' },
    { value: 'ca-ES', label: 'Catalán (ES)' },
    { value: 'eu-ES', label: 'Euskera (ES)' }
  ];
  idiomaSeleccionado: string = 'es-ES';
  recognition = new webkitSpeechRecognition(); //lib Speech Recognition
  isStoppedSpeechRecog = false; //status stop record
  text = 'no hay información'; //text about record
  tempWords: any; //words temp
  showButtonRec: boolean = false; //shows the record button
  showInformation: boolean = false; //show recording information
  isAudioRecording = false; //status animation recording
  isNotCompleteInformationAudio: boolean = false; //status button siguiente
  audioRecordedTime = ''; //record time audio
  showModalAudioRecording = false; //Show Modal Audio Recording
  // Autocomplete variables
  hideerrorentrycompanies: boolean = false;
  inputValueAutocomplete: string = '';
  resultErrorAutocomplete: any;
  colorfieldAutocomplete: string = 'primary'; // Valor predeterminado
  showInputAutocomplete: boolean = false;
  enablebuttonNext: boolean = false;
  searchInput = new FormControl();
  suggestions: any;
  // Upload Documents Variables
  files: any = [];
  documento: Documento[];
  archivos: Archivos;
  lastModified: string = "0";
  myForm = new FormGroup({
    file: new FormControl('', []),
    fileSource: new FormControl('', []),
    descripcion: new FormControl('', []),
  });
  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";

  constructor(
    private searchService: AutocompleteWordService,
    private apiService: ApiService,
    private enviarDenunciaService: EnviarDenunciaService,
    private auth: AuthService,
    private translate: TranslateService,
    private formDataService: FormDataService,
    private toastr: ToastrService,
    private router: Router,
    private spinnerSvc: SpinnerService,
    private http: HttpClient
  ) {
    this.setupAutoComplete();
    this.hoy.setDate(this.hoy.getDate());
  }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }

    this.init();//Inicialize the Api Recognition
    this.translate.setDefaultLang(language);//Inicialize translate
    this.showInputAutocomplete = this.auth.getInputAutocomplete() == '1' ? true : false;
    this.documentConditions = localStorage.getItem("documento"); //Get document "Términos y condiciones"
    this.url = localStorage.getItem("url"); //Get URL "Términos y condiciones" document

    this.enviarDenunciaService.searchCanal().subscribe((canal: Canal) => {
      this.canal = canal;
      if (this.canal[0].valor == "1") {
        this.IsAnonimo = true;
      }
      if (this.canal[0].valor == "2") {
        this.IsHidden = false;
      }
    });

    //Get Documents By Complaint
    this.archivos = this.formDataService.getArchivos();
    if (this.archivos.nombreArchivo != "") {
      this.apiService.searchDocumentsByComplaint(this.archivos.nombreArchivo, false, '1').subscribe((documento: Documento[]) => {
        this.documento = documento;
        this.lastModified = this.documento[0].id_denuncia;
        this.files.push(this.documento[0].n_archivo);
      });
    }

    //Get Data Centers By Show The Option Select
    if (this.auth.getCentros() == '1') {
      this.showSelected = 1; //Indicador para mostrar el selected de centros
    } else {
      this.showSelected = 0; //Indicador para mostrar el selected de centros
    }

    //Get data Complaint from FormData.service
    this.dataComplaint = this.formDataService.getDataComplaint();

    //Get Complaints Type For Society
    this.society = this.auth.getNameSociedad();
    this.apiService.searchComplaintByUserSociety(this.auth.getSociedad(), language).subscribe((complaint: Denuncia[]) => {
      this.complaints = complaint;
    });

    //Get Company For Society
    this.enviarDenunciaService.searchCompanies(this.auth.getSociedad()).subscribe((company: Empresa[]) => {
      this.companies = company;
    });

    //Add Witness
    this.personal = this.formDataService.getPersonal();

  }//fin ngOnInit

  //Method Witness Save
  saveWitness(form: any): boolean {
    if (!form.valid) {
      return false;
    }
    this.formDataService.setPersonal(this.personal);  //Add Information Witness
    this.showMenu4 = true; //show Menu 4
    this.showMenu3 = false; //hidde Menu 3
  }

  //Show Modal Audio recording
  showRecorder(mode: false | true | null) {
    this.isAudioRecording = false;
    this.showModalAudioRecording = mode;
    this.showButtonRec = false;
    this.isStoppedSpeechRecog = true;
    // Iniciar el retraso antes de llamar a stop()
    // setTimeout(() => {
    //   this.recognition.stop();
    // }, 1000);
  }

  //Change Idioma from Speech Recognition
  onIdiomaSeleccionadoChange(): void {
    this.init();
  }

  //Inicialize the Api Recognition
  init(): void {
    this.recognition = new webkitSpeechRecognition();
    this.recognition.continuous = true; // Grabación continua
    this.recognition.interimResults = true; // Obtener resultados provisionale
    this.recognition.lang = this.idiomaSeleccionado;

    //this.recognition.onresult = (event) => {
    this.recognition.addEventListener('result', (e: any) => {
      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript;
    });
  }

  //start button, audio recording
  start(): void {
    this.isStoppedSpeechRecog = false;
    this.recognition.start();
    this.recognition.addEventListener('end', () => {
      if (this.isStoppedSpeechRecog) {
        this.recognition.stop();
      } else {
        this.wordConcat();
        //this.recognition.start();
        var palabraBuscada = 'coma';
        var resultado = this.text.toLowerCase().includes(palabraBuscada.toLowerCase()); //gets the word "coma" in the sentence
        if (resultado) {
          this.text = this.text + ","; //add "," in the sentence
          var palabras = this.text.trim().split(' ');
          var ultimaPalabra = palabras[palabras.length - 2] //gets the penultimate word

          if (ultimaPalabra === 'coma' || ultimaPalabra === ',coma') {
            //Check if there are at least two words before trying to delete the second to last one
            if (palabras.length >= 2) {
              //Eliminate the penultimate word
              palabras.splice(-2, 1);
              this.text = palabras.join(' ');
            }
          }
        }
      }
    });
  }

  //stop audio recording
  stop(): void {
    this.isAudioRecording = false;
    this.isStoppedSpeechRecog = true;
    this.showButtonRec = false; //hidde button recording
    this.wordConcat();
    this.checkAudioFieldCompleted(this.text); //set Value for hidde button from 'siguiente'
    // Iniciar el retraso antes de llamar a stop()
    setTimeout(() => {
      this.recognition.stop();
    }, 2000);

  }

  //concatenate words from audio recording
  wordConcat(): void {
    this.text = this.text + this.tempWords + ' ';
    this.tempWords = ' ';
  }

  //method that inicialize audio recording
  startService(): void {
    this.text = '';
    this.isAudioRecording = true;
    this.showButtonRec = true;
    this.start();
  }

  //clear text data from audio recording
  clearAudioRecordedData() {
    this.text = '';
  }

  //Show Modal Information Transcription
  showInformationTransciption(mode: false | true | null) {
    this.showInformation = mode;
  }


  menu2() {
    this.showMenu1 = false;
    this.showMenu2 = true;
    this.showButtonMenu1 = false;
    this.showButtonMenu2 = true;
    this.showButtonMenu1 = false;
  }

  menu3(form: any) {
    this.showMenu3 = true;
    this.showMenu2 = false;
    if (form) {
      this.dataComplaint.denuncianteFirstName = form.value.denuncianteFirstName.toString();
      this.dataComplaint.denuncianteEmail = form.value.denuncianteEmail;
      this.dataComplaint.denuncianteLastName = form.value.denuncianteLastName;
      this.dataComplaint.denunciantePhone = form.value.denunciantePhone;
    }
  }

  saveAudioRecordedData(form: any) {
    //console.log(this.isNotCompleteInformationAudio);
    if (this.isNotCompleteInformationAudio) {
      if (form) {
        let day = form.value.fechaDatosDenuncia._i.date;
        let month = form.value.fechaDatosDenuncia._i.month + 1;
        let year = form.value.fechaDatosDenuncia._i.year;
        let fecha = year + '-' + month + '-' + day;
        this.dataComplaint.fechaDatosDenuncia = fecha.toString();
        this.dataComplaint.transcripcion = this.text;
        this.save();
      } else {
        //this.save();
      }
    } else {
      this.toastr.error(this.translate.instant('texto_712'), this.translate.instant('texto_264'));
    }
  }


  //Save complaint
  save(): any {

    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    var formData = new FormData();

    //Verify that audio translation (thia.text) exists to continuo save complaint
    if(this.text){

    //Adds the initial receptor to receptor if receptor is empty.
    if (this.dataComplaint.receptor == "") {
      this.dataComplaint.receptor = this.dataComplaint.receptorInicial
    }

    // Navigate to the work page
    this.formData = this.formDataService.getFormData();
    let nombreArchivo = this.formData.nombreArchivo;

    this.enviarDenunciaService.searchComplaintByUserSocietyID(this.dataComplaint.sociedad, this.dataComplaint.tipoDenuncia, false, language).subscribe((denuncia: Denuncia[]) => {
      this.denuncias = denuncia;

      if (this.denuncias[0].tipo_gestion == '2') {
        this.isExterna = true;
      }

      //Get the value Clausulas
      let $clau = false;
      let clausulas_correo = localStorage.getItem("clausulas_correo");
      if (clausulas_correo == '1') {
        $clau = true;
      }
      /**
       * ES EXTERNA
       */

      if (this.isExterna) {
        let generatepin = "";
        this.fase_inicial = '6'
        formData.append('idFase', this.fase_inicial);
        // this.http.post(`${this.PHP_API_SERVER}/api/Denuncias/createAudioComplaint.php`, formData).subscribe((pin: Pin) => {
        //   this.spinnerSvc.show();
        this.apiService.createComplaint(
          this.dataComplaint.sociedad,
          this.dataComplaint.idCentro,
          this.dataComplaint.tipoDenuncia,
          this.dataComplaint.denuncianteFirstName,
          this.dataComplaint.denuncianteLastName,
          '',
          this.dataComplaint.denuncianteEmail,
          this.dataComplaint.denunciantePhone,
          this.dataComplaint.fechaDatosDenuncia,
          this.dataComplaint.relacion,
          this.dataComplaint.transcripcion,
          this.fase_inicial,
          this.dataComplaint.idEmpresa,
          '',
          this.formDataService.getPersonal().firstName,
          this.formDataService.getPersonal().lastName,
          this.formDataService.getPersonal().email,
          this.formDataService.getPersonal().phone,
          this.formDataService.getPersonal().description,
          nombreArchivo,
          this.dataComplaint.receptor,
          '',
          this.dataComplaint.receptorInicial,
          ''
        ).subscribe((pin: Pin) => {
          this.pin = pin;
          if (this.pin.pin_code != "0") {
            this.toastr.success(this.translate.instant('texto_262'), this.translate.instant('texto_263'));
            this.pin_code = this.pin.pin_code;
            generatepin = this.makeid(5) + this.pin_code + this.makeid(5);
            this.apiService.updateParamComplaintById(this.pin.id_denuncia).subscribe(
              res => {
                if (res == 200) {
                  if (this.formData.denuncianteEmail != "") {
                    let url = environment.base + "/#/ver-denuncia/consultar-denuncia";
                    let message = this.translate.instant('texto_576') + " " + this.formData.denuncianteFirstName + " " + this.formData.denuncianteLastName + this.translate.instant('texto_604') + this.pin.id_denuncia + " y pin " + this.pin.pin_code + this.translate.instant('texto_625');
                    this.apiService.sendEmail(this.formData.denuncianteEmail, this.translate.instant('texto_623'), message, url, $clau, this.translate.instant('texto_624')).subscribe(
                      res => {
                        if (res == 200) {
                          //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                        } else {
                          this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                        }
                      });
                  }
                }
              });

            this.apiService.searchLayerByIdSociety(this.denuncias[0].id_gestor, this.formData.empresa).subscribe((abogado: Abogado[]) => {
              this.abogados = abogado;
              let message = this.translate.instant('texto_576') + " " + this.abogados[0].n_abogado + this.translate.instant('texto_626');
              let message2 = this.translate.instant('texto_644');
              if (this.formData.denuncianteEmail != "") {
                message2 = message2 + this.translate.instant('texto_627') + this.formData.denuncianteFirstName + " " + this.formData.denuncianteLastName + "<br>" +
                  this.translate.instant('texto_628') + this.formData.denuncianteDNI + "<br>" +
                  this.translate.instant('texto_629') + this.formData.denuncianteEmail + "<br>" +
                  this.translate.instant('texto_630') + this.formData.denunciantePhone + "<br>";

              }
              let language = this.auth.getLanguage();
              if (language == "" || language == null) {
                language = 'es';
              }
              this.translate.setDefaultLang(language);
              let tipoDenuncia = "";
              this.enviarDenunciaService.searchComplaintByUserSocietyID(this.formData.empresa, this.formData.tipoDenuncia, false, language).subscribe((denuncia: Denuncia[]) => {
                this.denuncias = denuncia;
                tipoDenuncia = denuncia[0].nombre;
                let tipoRelacion = "";
                this.apiService.searchRelationTypeById(this.formData.denuncianteRelacion, this.formData.empresa, language).subscribe((relacion: Relacion[]) => {
                  this.relaciones = relacion;
                  tipoRelacion = relacion[0].nombre;
                  let message3 = this.translate.instant('texto_632') + this.formData.fechaDatosDenuncia.format('DD/MM/YYYY') + "<br>" +
                    this.translate.instant('texto_633') + tipoRelacion + "<br>" +
                    this.translate.instant('texto_634') + tipoDenuncia + "<br>" +
                    this.translate.instant('texto_635') + this.formData.descripcionDatosDenuncia + "<br>" +
                    this.translate.instant('texto_636') + this.formData.comentario + "<br>";

                  this.apiService.sendEmail(this.abogados[0].email, this.translate.instant('texto_631'), message + "<br>" + message2 + "<br>" + message3, '', $clau, this.translate.instant('texto_631')).subscribe(
                    res => {
                      if (res == 200) {
                        //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                      } else {
                        this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                      }
                    });
                });
              });

            });

            this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['finalizada/', generatepin]);
            });
          } else {
            this.toastr.error(this.translate.instant('texto_264'), this.translate.instant('texto_263'));
            this.auth.logoutSimple();
            this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['login']);
            });
          }
        },
          (err: any) => {
            this.toastr.error(this.translate.instant('texto_264'), this.translate.instant('texto_263'));
          }
        );


      } else {
        //this.spinnerSvc.show();
        //Set data fase_inicial depends the value of the Triaje
        this.fase_inicial = '0'
        this.triaje = localStorage.getItem("triaje");
        if (this.triaje == '1') {
          this.fase_inicial = '11'
        }

        formData.append('idFase', this.fase_inicial);

        //Send data Backend
        let generatepin = "";
        this.apiService.createComplaint(
          this.dataComplaint.sociedad,
          this.dataComplaint.idCentro,
          this.dataComplaint.tipoDenuncia,
          this.dataComplaint.denuncianteFirstName,
          this.dataComplaint.denuncianteLastName,
          '',
          this.dataComplaint.denuncianteEmail,
          this.dataComplaint.denunciantePhone,
          this.dataComplaint.fechaDatosDenuncia,
          this.dataComplaint.relacion,
          this.dataComplaint.transcripcion,
          this.fase_inicial,
          this.dataComplaint.idEmpresa,
          '',
          this.formDataService.getPersonal().firstName,
          this.formDataService.getPersonal().lastName,
          this.formDataService.getPersonal().email,
          this.formDataService.getPersonal().phone,
          this.formDataService.getPersonal().description,
          nombreArchivo,
          this.dataComplaint.receptor,
          '',
          this.dataComplaint.receptorInicial,
          ''
        ).subscribe((pin: Pin) => {
          //this.spinnerSvc.show();
          this.pin = pin;
          //console.log(pin);
          if (this.pin.pin_code != "0") {
            this.toastr.success(this.translate.instant('texto_262'), this.translate.instant('texto_263'));
            this.pin_code = this.pin.pin_code;
            generatepin = this.makeid(5) + this.pin_code + this.makeid(5);
            if (this.dataComplaint.denuncianteEmail != "") {
              let url = environment.base + "/#/ver-denuncia/consultar-denuncia";
              let message = this.translate.instant('texto_576') + " " + this.translate.instant('texto_637') + this.pin.pin_code + this.translate.instant('texto_645') + this.pin.id_denuncia + this.translate.instant('texto_638');

              this.apiService.sendEmail(this.dataComplaint.denuncianteEmail, this.translate.instant('texto_623'), message, url, $clau, this.translate.instant('texto_624')).subscribe(
                res => {
                  if (res == 200) {
                    this.apiService.searchComplaintByPin(this.pin_code, language).subscribe((denuncia: DatosDenuncia[]) => {
                      this.denuncia = denuncia;
                      let url2 = environment.base + "/#/editar-denuncia/" + this.denuncia[0].id_denuncia;

                      this.apiService.searchReceptorByIdComplaint(this.denuncia[0].id_denuncia, true).subscribe((receptor: Receptor[]) => {
                        this.receptor = receptor;

                        let messageReceptor = this.translate.instant('texto_576') + " " + this.receptor[0].id_user + this.translate.instant('texto_639') + this.denuncia[0].id_denuncia + this.translate.instant('texto_640');
                        let asunto = this.translate.instant('texto_641');
                        if (this.triaje == '1') {
                          asunto = this.translate.instant('texto_642');
                        }
                        this.apiService.sendEmail(this.receptor[0].mail_user, asunto, messageReceptor, url2, $clau, asunto).subscribe(
                          res => {
                            if (res == 200) {
                              //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                            } else {
                              this.spinnerSvc.hide();
                              this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                            }
                          });
                      });
                    });
                  } else {
                    this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                    //this.spinnerSvc.hide();
                  }

                });
            } else {
              let language = this.auth.getLanguage();
              if (language == "" || language == null) {
                language = 'es';
              }
              this.apiService.searchComplaintByPin(this.pin_code, language).subscribe((denuncia: DatosDenuncia[]) => {
                this.denuncia = denuncia;
                // console.log(this.denuncia);
                this.apiService.searchReceptorByIdComplaint(this.denuncia[0].id_denuncia, true).subscribe((receptor: Receptor[]) => {
                  this.receptor = receptor;
                  //console.log(this.receptor);
                  let url2 = environment.base + "/#/editar-denuncia/" + this.denuncia[0].id_denuncia;

                  let asunto = this.translate.instant('texto_641');
                  if (this.triaje == '1') {
                    asunto = this.translate.instant('texto_642');
                  }
                  let messageReceptor = this.translate.instant('texto_576') + " " + this.receptor[0].id_user + this.translate.instant('texto_646') + this.denuncia[0].id_denuncia + this.translate.instant('texto_643');
                  this.apiService.sendEmail(this.receptor[0].mail_user, asunto, messageReceptor, url2, $clau, asunto).subscribe(
                    res => {
                      if (res == 200) {

                        this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                      } else {

                        this.spinnerSvc.hide();
                        this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                      }
                    });
                });
              });
            }
            this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['finalizada/', generatepin]);
            });

          } else {
            this.toastr.error(this.translate.instant('texto_264'), this.translate.instant('texto_263'));
            this.auth.logoutSimple();
            this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['login']);
            });
          }

          this.spinnerSvc.hide();
        },
          (err: any) => {
            this.toastr.error(this.translate.instant('texto_264'), this.translate.instant('texto_263'));
          });
      } //fin if is externa
    });//Fin searchComplaintByUserSocietyID

  } else {
    this.toastr.error(this.translate.instant('texto_712'), this.translate.instant('texto_264'));
  }
  }


  hide() {
    window.location.reload();
  }


  //Event That Changes When Selecting a Company in the Selected from HTML
  onChange(event) {
    //configuration of language
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    //QSC_CENTROS data
    let Companyid = event.value.id_empresa; //Company ID
    let Societyid = event.value.id_sociedad; //Society ID
    this.description = ""; // description

    this.searchCentersAndComplaint(Companyid, Companyid, Societyid);
  }

  //Event That Get the Value from Complaint Type
  onChangeComplaint(event) {
    //configuration of language
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.description = "";
    let selectedComplaint = event.value;
    this.dataComplaint.tipoDenuncia = selectedComplaint; //upload model
    //console.log(this.description);
    for (var i = 0; i < this.complaints.length; i++) {
      if (this.complaints[i].id_tp_denuncia == selectedComplaint) {
        this.description = this.complaints[i].descripcion;
        break;
      }
    }
    //Add Initial receptor by complaint type and change when the user changed type complaint
    this.apiService.searchUsersReceptorsRamdom(this.dataComplaint.sociedad, this.dataComplaint.tipoDenuncia, language).subscribe((receptores: RolReceptor[]) => {
      this.receptores = receptores;
      //console.log('2', this.receptores);
      this.Receptoraleatorio = JSON.stringify(receptores[0].n_user + ' ' + receptores[0].ape_user); //receptor inicial
      this.dataComplaint.receptorInicial = receptores[0].id_user; //set receptor initial
      //console.log('receptorInicial', this.dataComplaint.receptorInicial);

      if (this.receptores == null) {
        this.apiService.searchUsersReceptors(this.dataComplaint.sociedad, this.dataComplaint.tipoDenuncia, language).subscribe((receptores: RolReceptor[]) => {
          //console.log('entro 3', receptores);
          this.receptores = receptores;
        });
      }
    });

  }

  //Event That Get the Value from Center
  onChangeCenter(event) {
    //QSC_CENTROS data
    let idCentro = event.value.id_centro;
    this.dataComplaint.idCentro = idCentro; // set model id centro
  }

  //Event That Get the Value from Center
  onChangeRelation(event) {
    //configuration of language
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    //QSC_CENTROS data
    let typeRelationId = event.value;
    this.dataComplaint.relacion = typeRelationId; //set model type relation

    this.apiService.searchUsersReceptorsRamdom(this.dataComplaint.sociedad, this.dataComplaint.tipoDenuncia, language).subscribe((receptores: RolReceptor[]) => {
      this.receptores = receptores;
      //console.log('2', this.receptores);
      this.Receptoraleatorio = JSON.stringify(receptores[0].n_user + ' ' + receptores[0].ape_user); //receptor inicial
      this.dataComplaint.receptorInicial = receptores[0].id_user; //set receptor initial
      //console.log('receptorInicial', this.dataComplaint.receptorInicial);

      if (this.receptores == null) {
        this.apiService.searchUsersReceptors(this.dataComplaint.sociedad, this.dataComplaint.tipoDenuncia, language).subscribe((receptores: RolReceptor[]) => {
          //console.log('entro 3', receptores);
          this.receptores = receptores;
        });
      }
    });


    if (this.typeButton != true) {
      this.ShowSetReceptor = true; //Show button 'proponer otro receptor' and show receptor automatic
    }

  }

  //Event That Get the Value from Receptor
  onChangeReceptor(event) {
    let receptor = event.value;
    this.dataComplaint.receptor = receptor; //Set receptor
    //console.log('receptor', this.dataComplaint.receptor);

  }

  // Get the value by receptor
  onClickReceptor(event) {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.apiService.searchUsersReceptors(this.dataComplaint.sociedad, this.dataComplaint.tipoDenuncia, language).subscribe((receptores: RolReceptor[]) => {
      this.receptores = receptores;
    });
  }

  //Event That works when clicked "Proponer otro receptor"
  buscarReceptores() {
    this.Showbutton = false; //hidde button "proponer otro receptor"
    this.Showselect = true; //show select "elegir receptores"
    this.ShowSetReceptor = false; //hidde receptor "prouesto automáticamente"
    this.typeButton = true; //show button 'Proponer receptor automáticamente'
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.apiService.searchUsersReceptors(this.dataComplaint.sociedad, this.dataComplaint.tipoDenuncia, language).subscribe((receptores: RolReceptor[]) => {
      this.receptores = receptores;
      //console.log(this.receptores)
    });
  }

  //Event That works when clicked "Proponer receptor automáticamente"
  receptoresAutomatico() {
    this.dataComplaint.receptor = "";
    this.Showbutton = true;
    if (this.Showbutton = true) {
      this.typeButton = false;
    }
    this.Showselect = false;
    this.ShowSetReceptor = true;
  }

  private makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return() {
    this.showMenu1 = true; //show menu 1
    this.showMenu2 = false; //hide menu 2
    this.showMenu3 = false;
    this.showMenu5 = false;
    this.showMenu4 = false;
    this.showButtonMenu2 = false; //hide button menu 2
    this.showButtonMenu1 = true; //hide button menu 1
  }

  returnMenu3(form: any) {
    if (this.saveDocument(form)) {
      this.showMenu2 = false; //hide menu 2
      this.showMenu3 = true;
      this.showMenu4 = false; //show menu 1
      this.showMenu5 = false;
      this.showButtonMenu2 = true; //hide button menu 2
      this.showButtonMenu1 = false; //hide button menu 1
    }
  }

  returnMenu4() {
    this.showMenu2 = false; //hide menu 2
    this.showMenu3 = false;
    this.showMenu4 = true; //show menu 1
    this.showMenu5 = false;
    this.showButtonMenu2 = true; //hide button menu 2
    this.showButtonMenu1 = false; //hide button menu 1
  }

  returnMenu2() {
    this.showMenu2 = true; //hide menu 2
    this.showMenu3 = false;
    this.showMenu4 = false; //show menu 1
    this.showButtonMenu2 = true; //hide button menu 2
    this.showButtonMenu1 = false; //hide button menu 1
  }

  // Check if the audio recording field is completed.
  checkAudioFieldCompleted(text: string) {
    // Set Value for hide button from 'siguiente'
    //console.log('checkAudioFieldCompleted', text);
    if (text === undefined || text === 'undefined') {  // <-- Cambiado de 'undefined' a undefined
      //console.log('entro1', text);
      this.isNotCompleteInformationAudio = false;
    } else if (text === "no hay información") {
      //console.log('entro12', text);
      this.isNotCompleteInformationAudio = false;
    } else if (text === null || !text) {
      // console.log('entro3', text);
      this.isNotCompleteInformationAudio = false;
    } else {
      // console.log('entro4', text);
      this.isNotCompleteInformationAudio = true;
    }
  }

  /**
 * Evaluate the changes from autocomplete input
 */
  private setupAutoComplete() {
    this.searchInput.valueChanges
      .pipe(
        debounceTime(300), // Wait 300ms after last key press
        switchMap(query => {
          if (query && query.length >= 4) {
            return this.searchService.getAutocompleteSuggestions(query); // Find the word
          } else {
            return of([]); // observable void
          }
        })
      )
      .subscribe(arrayNames => {
        this.suggestions = arrayNames;
      });
  }

  /**
   * Handle the selection event company
   * @param suggestion
   * @param suggestionId
   * @param suggestionSociedad
   */
  selectSuggestion(suggestion: string, suggestionId: string, suggestionSociedad: string) {
    this.searchInput.setValue(suggestion);
    this.searchCentersAndComplaint(suggestion, suggestionId, suggestionSociedad);
    this.suggestions = []; // Hide suggestions after selection
    this.autocompletionerrorhandling(suggestion);
  }

  /**
 * Input Change from Autocomplete input
 * @param value // value from autocomplete input
 */
  onInputChange(value: string): void {
    this.inputValueAutocomplete = value;
    if (this.inputValueAutocomplete.length >= 4) {
      this.autocompletionerrorhandling(this.inputValueAutocomplete);
      if (this.suggestions) {
        //Checks if the company exists by writing it to the autocomplete input
        const companiesfound = this.suggestions.find(empresa => empresa.n_empresa === this.inputValueAutocomplete);
        if (companiesfound != '' && companiesfound != null && companiesfound != undefined) {
          this.searchCentersAndComplaint(companiesfound.n_empresa, companiesfound.id_empresa, companiesfound.id_sociedad);
        }
      }
    } else {
      this.hideerrorentrycompanies = false
    }
  }

  /**
   * Handle autocomplete input errors
   * @param word //searched character
   */
  autocompletionerrorhandling(word: string) {
    this.searchService.getAutocompleteSuggestionsByWord(word).subscribe(resultado => {
      this.resultErrorAutocomplete = resultado;
      if (this.resultErrorAutocomplete != 0 && this.resultErrorAutocomplete != null) {
        this.hideerrorentrycompanies = false
        this.colorfieldAutocomplete = 'primary';
        this.enablebuttonNext = false;
      } else {
        this.hideerrorentrycompanies = true
        this.colorfieldAutocomplete = 'warn';
        this.enablebuttonNext = true;
      }
    });
  }

  /**
 * Search for the centers and types of complaints according to the selected company
 * @param suggestion //name company
 * @param suggestionId //id company
 * @param suggestionSociedad  //sociedad from company
 */
  searchCentersAndComplaint(suggestion: string, suggestionId: string, suggestionSociedad: string) {
    //Get language from canal
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    if (suggestionId && suggestionSociedad) {
      //upload data model
      this.dataComplaint.idEmpresa = suggestionId;
      this.dataComplaint.sociedad = suggestionSociedad;

      //Search Centers By Company ID
      this.enviarDenunciaService.searchCentersbyidCompany(suggestionId, suggestionSociedad).subscribe((centers: Centro[]) => {
        this.centers = centers;
      });

      //Search Complaint By Society ID
      this.enviarDenunciaService.searchTypeComplaint(suggestionSociedad, false, language).subscribe((complaint: Denuncia[]) => {
        this.complaints = complaint;
      });

      //Get Company Relation For Society
      this.apiService.searchTypeRelation(suggestionSociedad, false, language).subscribe((tipoDenunciantes: Relacion) => {
        this.tipoDenunciantes = tipoDenunciantes;
      });


    }
  }

  // UPLOAD DOCUMENTS
  saveDocument(form: any): boolean {
    if (!form.valid) {
      return false;
    }

    this.formDataService.setArchivos(this.archivos);
    return true;
  }

  goToPrevious(form: any) {
    if (this.saveDocument(form)) {
      this.showMenu5 = false;
      this.showMenu4 = true;
    }
  }

  goToNext(form: any) {
    this.submit();
    if (this.saveDocument(form)) {
      this.showMenu5 = true;
      this.showMenu4 = false;
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.archivos.nombreArchivo = "";
      this.myForm.patchValue({
        fileSource: file
      });

      //this.submit();
    }
  }

  onFileChangeDrag(event) {
    if (event.length > 0) {
      const file = event[0];
      this.myForm.patchValue({
        fileSource: file
      });

      //this.submit();
    }
  }

  get descripcion() {
    return this.myForm.get('descripcion');
  }

  submit() {
    if (this.archivos.nombreArchivo == "") {
      const formData = new FormData();
      let descripcion = this.descripcion.value.replace(/<[^>]*>/g, '');
      descripcion = descripcion.replace(/'/g, '');

      formData.append('file', this.myForm.get('fileSource').value);
      formData.append('id_sociedad', this.dataComplaint.sociedad);
      formData.append('id_denuncia', this.lastModified);
      formData.append('comentario', descripcion);
      //formData.append('id_usuario', this.formDataService.getDenunciante().denuncianteEmail);
      formData.append('id_usuario', 'Denunciante');
      this.archivos.nombreArchivo = this.myForm.get('fileSource').value.lastModified;
      if (this.myForm.get('fileSource').value != "") {
        this.http.post(`${this.PHP_API_SERVER}/api/uploadDocument.php`, formData).subscribe(
          res => {
            if (res == 200) {
              this.archivos.nombreArchivo = this.lastModified;
              // this.toastr.success('Archivos cargados correctamente', 'Cargando ...');
            } else {
              this.toastr.error(this.translate.instant('texto_202'), this.translate.instant('texto_203'));
            }
          },
          err => {
            this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
          })
      }
    }
  }

  dragFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      // const element = event[index];
      //const file = event.target.files[0];

      this.files.push(element.name)
      if (this.files.length > 1) {
        this.files.pop(element.name)
        this.toastr.error('Sólo puede subir un archivo, si desea añadir más archivos lo podrá hacer más adelante', 'Cargando ...');
      } else {
        if (element.size / 1000000 > 16) {
          this.files.pop(element.name)
          alert('Tamaño máximo de archivo es de 16Mb');
        } else {
          this.lastModified = element.lastModified
          this.onFileChangeDrag(event);
        }

      }
    }
  }

  uploadFile(event) {
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      // const element = event[index];
      //const file = event.target.files[0];

      this.files.push(element.name)
      if (this.files.length > 1) {
        this.files.pop(element.name)
        this.toastr.error('Sólo puede subir un archivo, si desea añadir más archivos lo podrá hacer más adelante', 'Cargando ...');
      } else {
        if (element.size / 1000000 > 16) {
          this.files.pop(element.name)
          alert('Tamaño máximo de archivo es de 16Mb');
        } else {
          this.lastModified = element.lastModified
          this.onFileChange(event);
        }
      }
    }
  }

  deleteAttachment(index) {
    this.archivos.nombreArchivo = "";
    this.files.splice(index, 1)
    const formData = new FormData();
    let file = this.myForm.get('fileSource').value;
    if (this.myForm.get('fileSource').value == "") {
      formData.append('delete_file', this.documento[0].n_archivo);
    }
    formData.append('file', file);
    formData.append('id_sociedad', this.dataComplaint.sociedad);
    formData.append('id_denuncia', this.lastModified);
    formData.append('comentario', this.descripcion.value);
    formData.append('id_usuario', this.formDataService.getDenunciante().denuncianteEmail);

    // this.http.post(`${this.PHP_API_SERVER}/api/deleteDocument.php`, formData).subscribe(
    //   res => {
    //     if(res == 200){
    //       this.toastr.success('Archivos eliminado correctamente', 'Cargando ...');
    //     }else{
    //       this.toastr.error('Error al eliminar los archivos', 'Cargando ...');
    //     }
    // })
  }



}
