import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { FormDataService } from 'src/app/enviar-denuncia/services/formData.service';
import { EnviarDenunciaService } from 'src/app/enviar-denuncia/services/enviar-denuncia.service';
import { Denuncia } from 'src/app/enviar-denuncia/models/denuncia';
import { FinalizarDenunciaService } from 'src/app/enviar-denuncia/services/finalizar-denuncia.service';
import { Pin } from 'src/app/enviar-denuncia/models/pin';
import { environment } from 'src/environments/environment';
import { Abogado } from 'src/app/enviar-denuncia/models/abogado';
import { Relacion } from 'src/app/enviar-denuncia/models/relacion copy';
import { Receptor } from 'src/app/enviar-denuncia/models/receptor';
import { Observable } from 'rxjs';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { PasswordComplaint } from 'src/app/shared/models/formData.model';
@Component({
  selector: 'app-asignar-contrasenia',
  templateUrl: './asignar-contrasenia.component.html',
  styleUrls: ['./asignar-contrasenia.component.css']
})
export class AsignarContraseniaComponent implements OnInit {
  @Input() formData; // Input property to receive form data 
  title = 'texto_784'; 
  validatingForm: FormGroup; 
  passwordVisible = false; // Flag to toggle the visibility of the password field
  repeatedPasswordVisible = false; // Flag to toggle the visibility of the repeated password field
  passwordComplaint: PasswordComplaint; // Object to store the password complaint details
  finalizada: boolean = false; 
  denuncias: Denuncia[]; 
  isExterna: boolean = false; 
  pin: Pin; 
  pin_code: string; 
  abogados: Abogado[]; 
  relaciones: Relacion[]; 
  fase_inicial: string; 
  triaje: string = ''; 
  finaliza: any = {
    terminos: false, 
    proteccionDatos: false 
  };
  denuncia: DatosDenuncia[]; 
  receptor: Receptor[]; 
  email: boolean = false; // Indicates if the user has provided an email in a previous phase
  hasUpperCase: boolean = false;
  hasLowerCase: boolean = false;
  hasNumber: boolean = false;
  hasSpecialChar: boolean = false;
  hasMinLength: boolean = false;

  constructor(
    private router: Router, 
    private auth: AuthService, 
    private toastr: ToastrService, 
    private translate: TranslateService, 
    private fb: FormBuilder, 
    private titleService: Title, 
    private formDataService: FormDataService, 
    private enviarDenunciaService: EnviarDenunciaService,
    private finalizarDenunciaService: FinalizarDenunciaService 
  ) {
    // Set title based on the selected language
    this.translate.get('texto_359').subscribe((text: string) => {
      this.titleService.setTitle(text);
    });

    // Initialize the form group with controls and their validators
    this.validatingForm = this.fb.group({
      passwordComplaint: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_]).+$') // Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character
      ]],
      repeatedPasswordComplaint: ['', [
        Validators.required, 
        Validators.minLength(8), 
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_]).+$') // Must match the same pattern as the first password
      ]],
      email: ['', [
        Validators.pattern('[A-Za-z0-9!@#$%^&*()_+\\-=\\[\\]{};:\'",.<>?/`~]+') //Email password
      ]],
    });
  }

  ngOnInit() {
    let language = this.auth.getLanguage(); 
    if (language === "" || language === null) {
      language = 'es'; // Default to Spanish if no language is set
    }

    this.passwordComplaint = this.formDataService.getPasswordComplaint(); // Get password complaint data from the service
    this.formData = this.formDataService.getFormData(); // Retrieve the form data from the service
    if (this.formData?.denuncianteEmail?.trim()) {
      this.email = true; // Set email flag to true if an email is provided in the denunciante form
    }

    this.validatingForm.get('passwordComplaint')?.valueChanges.subscribe((password: string) => {
      this.checkPasswordStrength(password);
    });

    this.toastr.info(
      this.translate.instant('texto_796'),
      this.translate.instant('texto_789'), {
      timeOut: 0,
      closeButton: true,
    });
  }

    /**
   * Function to validate strength password
   */
    checkPasswordStrength(password: string): void {
      const uppercasePattern = /[A-Z]/;
      const lowercasePattern = /[a-z]/;
      const numberPattern = /\d/;
      const specialCharPattern = /[\W_]/;
  
      this.hasUpperCase = uppercasePattern.test(password);
      this.hasLowerCase = lowercasePattern.test(password);
      this.hasNumber = numberPattern.test(password);
      this.hasSpecialChar = specialCharPattern.test(password);
      this.hasMinLength = password.length >= 8;
    }

  // Toggle the visibility of the password field
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible; 
  }

   // Toggle the visibility of the repeated password field
  toggleRepeatedPasswordVisibility() {
    this.repeatedPasswordVisible = !this.repeatedPasswordVisible;
  }

  /**
   * Fuction to save complaint data in form Data service
   * @param form form with complaint data
   * @returns 
   */
  save(form: any): boolean {
    if (form.valid) {
      const password = this.validatingForm.get('passwordComplaint')?.value; // Get the value of the password field
      const repeatedPassword = this.validatingForm.get('repeatedPasswordComplaint')?.value; // Get the value of the repeated password field
      const emailFromForm = this.validatingForm.get('email')?.value; // Get the value of the email field

      // Check if password and repeated password match and the form is valid
      if (password === repeatedPassword && this.validatingForm.valid) {
        this.passwordComplaint.password = password; // Set the password in the passwordComplaint object

        // If an email has been added in the whistleblower data phase, take it, if not, retrieve it from the form.
        if (this.email) {
          this.passwordComplaint.emailRecovery = this.formData?.denuncianteEmail; 
        } else {
          this.passwordComplaint.emailRecovery = emailFromForm; 
        }
      }

      // Save password and email to recover password in the service
      this.formDataService.setPasswordComplaint(this.passwordComplaint); 
      this.formData = this.formDataService.getFormData(); 
      return true; 
    } else {
      return false; 
    }
  }

  /**
   * Save complaint in database
   * Send emails necessary
   * @param form 
   */
  crearDenuncia(form: any) {
    if (this.save(form) && this.finalizada === false) {
      this.finalizada = true;
      for (
        var i = 0;
        i < document.getElementsByClassName('grecaptcha-badge').length;
        i++
      ) {
        var div = document.getElementsByClassName('grecaptcha-badge')[i];
        div.setAttribute('style', 'display:none');
      }
      // Navigate to the work page
      this.formData = this.formDataService.getFormData();
      let nombreArchivo = this.formData.nombreArchivo;

      let language = this.auth.getLanguage();
      if (language == '' || language == null) {
        language = 'es';
      }
      this.translate.setDefaultLang(language);
      let generatepin = '';

      this.enviarDenunciaService
        .searchComplaintByUserSocietyID(
          this.formData.empresa,
          this.formData.tipoDenuncia,
          false,
          language
        )
        .subscribe((denuncia: Denuncia[]) => {
          this.denuncias = denuncia;

          if (this.denuncias[0].tipo_gestion == '2') {
            this.isExterna = true;
          }
          let finalizadaDenuncia = (<HTMLInputElement>(
            document.getElementById('finaliced')
          )).value;

          let $clau = false;
          let clausulas_correo = localStorage.getItem('clausulas_correo');
          if (clausulas_correo == '1') {
            $clau = true;
          }
          const testigos = this.formData.testigos.map((testigo) => ({
            nombre_testigo: testigo.firstName,
            apellido_testigo: testigo.lastName,
            email_testigo: testigo.email,
            phone_testigo: testigo.phone,
            descripcion_testigo: testigo.description,
          }));
          if (this.isExterna) {
            this.finalizarDenunciaService
              .createComplaint(
                this.formData.empresa,
                this.formData.idCentro,
                this.formData.tipoDenuncia,
                this.formData.denuncianteFirstName,
                this.formData.denuncianteLastName,
                this.formData.denuncianteDNI,
                this.formData.denuncianteEmail,
                this.formData.denunciantePhone,
                this.formData.fechaDatosDenuncia,
                this.formData.denuncianteRelacion,
                this.formData.descripcionDatosDenuncia,
                '6',
                this.formData.identificadorEmpresa,
                this.formData.comentario,
                nombreArchivo,
                this.formData.receptor,
                this.formData.motivo,
                this.formData.receptorInicial,
                finalizadaDenuncia,
                testigos,
                this.formData.password,
                this.formData.emailRecovery
              )

              .subscribe(
                (pin: Pin) => {
                  this.pin = pin;
                  if (this.pin.pin_code != '0') {
                    this.toastr.success(
                      this.translate.instant('texto_262'),
                      this.translate.instant('texto_263')
                    );
                    this.pin_code = this.pin.pin_code;
                    generatepin =
                      this.makeid(5) + this.pin.id_denuncia + this.makeid(5);
                    this.finalizarDenunciaService
                      .updateParamComplaintById(this.pin.id_denuncia)
                      .subscribe((res) => {
                        if (res == 200) {
                          //save files in list
                          this.submitFiles(this.pin.id_denuncia);
                          if (this.formData.denuncianteEmail != '') {
                            let url =
                              environment.base +
                              '/ver-denuncia/consultar-denuncia';
                            let message =
                              this.translate.instant('texto_576') +
                              ' ' +
                              this.formData.denuncianteFirstName +
                              ' ' +
                              this.formData.denuncianteLastName +
                              this.translate.instant('texto_604') + " " +
                              this.pin.id_denuncia + " " +
                              this.translate.instant('texto_625');
                            this.finalizarDenunciaService
                              .sendEmail(
                                this.formData.denuncianteEmail,
                                this.translate.instant('texto_623'),
                                message,
                                url,
                                $clau,
                                this.translate.instant('texto_624')
                              )
                              .subscribe((res) => {
                                if (res == 200) {
                                  //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                } else {
                                  this.toastr.error(
                                    this.translate.instant('texto_193'),
                                    this.translate.instant('texto_195')
                                  );
                                }
                              });
                          }
                        }
                      });

                    this.finalizarDenunciaService
                      .searchLayerByIdSociety(
                        this.denuncias[0].id_gestor,
                        this.formData.empresa
                      )
                      .subscribe((abogado: Abogado[]) => {
                        this.abogados = abogado;
                        let message =
                          this.translate.instant('texto_576') +
                          ' ' +
                          this.abogados[0].n_abogado +
                          this.translate.instant('texto_626');
                        let message2 = this.translate.instant('texto_644');
                        if (this.formData.denuncianteEmail != '') {
                          message2 =
                            message2 +
                            this.translate.instant('texto_627') +
                            this.formData.denuncianteFirstName +
                            ' ' +
                            this.formData.denuncianteLastName +
                            '<br>' +
                            this.translate.instant('texto_628') +
                            this.formData.denuncianteDNI +
                            '<br>' +
                            this.translate.instant('texto_629') +
                            this.formData.denuncianteEmail +
                            '<br>' +
                            this.translate.instant('texto_630') +
                            this.formData.denunciantePhone +
                            '<br>';
                        }
                        let language = this.auth.getLanguage();
                        if (language == '' || language == null) {
                          language = 'es';
                        }
                        this.translate.setDefaultLang(language);
                        let tipoDenuncia = '';
                        this.enviarDenunciaService
                          .searchComplaintByUserSocietyID(
                            this.formData.empresa,
                            this.formData.tipoDenuncia,
                            false,
                            language
                          )
                          .subscribe((denuncia: Denuncia[]) => {
                            this.denuncias = denuncia;
                            tipoDenuncia = denuncia[0].nombre;
                            let tipoRelacion = '';
                            this.finalizarDenunciaService
                              .searchRelationTypeById(
                                this.formData.denuncianteRelacion,
                                this.formData.empresa,
                                language
                              )
                              .subscribe((relacion: Relacion[]) => {
                                this.relaciones = relacion;
                                tipoRelacion = relacion[0].nombre;
                                let message3 =
                                  this.translate.instant('texto_632') +
                                  this.formData.fechaDatosDenuncia.format(
                                    'DD/MM/YYYY'
                                  ) +
                                  '<br>' +
                                  this.translate.instant('texto_633') +
                                  tipoRelacion +
                                  '<br>' +
                                  this.translate.instant('texto_634') +
                                  tipoDenuncia +
                                  '<br>' +
                                  this.translate.instant('texto_635') +
                                  this.formData.descripcionDatosDenuncia +
                                  '<br>' +
                                  this.translate.instant('texto_636') +
                                  this.formData.comentario +
                                  '<br>';

                                this.finalizarDenunciaService
                                  .sendEmail(
                                    this.abogados[0].email,
                                    this.translate.instant('texto_631'),
                                    message +
                                    '<br>' +
                                    message2 +
                                    '<br>' +
                                    message3,
                                    '',
                                    $clau,
                                    this.translate.instant('texto_631')
                                  )
                                  .subscribe((res) => {
                                    if (res == 200) {
                                      //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                    } else {
                                      this.toastr.error(
                                        this.translate.instant('texto_193'),
                                        this.translate.instant('texto_195')
                                      );
                                    }
                                  });
                              });
                          });
                      });

                    this.router
                      .navigateByUrl('/RefreshComponent', {
                        skipLocationChange: true,
                      })
                      .then(() => {
                        this.router.navigate([
                          '/enviar-denuncia/finalizada/',
                          generatepin,
                        ]);
                      });

                  } else {
                    this.toastr.error(
                      this.translate.instant('texto_264'),
                      this.translate.instant('texto_263')
                    );
                    this.auth.logoutSimple();
                    this.router
                      .navigateByUrl('/RefreshComponent', {
                        skipLocationChange: true,
                      })
                      .then(() => {
                        this.router.navigate(['login']);
                      });
                  }
                },
                (err: any) => {
                  this.toastr.error(
                    this.translate.instant('texto_264'),
                    this.translate.instant('texto_263')
                  );
                }
              );
          } else {
            this.fase_inicial = '0';
            this.triaje = localStorage.getItem('triaje');
            if (this.triaje == '1') {
              this.fase_inicial = '11';
            }

            this.finalizarDenunciaService
              .createComplaint(
                this.formData.empresa,
                this.formData.identificadorCentro,
                this.formData.tipoDenuncia,
                this.formData.denuncianteFirstName,
                this.formData.denuncianteLastName,
                this.formData.denuncianteDNI,
                this.formData.denuncianteEmail,
                this.formData.denunciantePhone,
                this.formData.fechaDatosDenuncia,
                this.formData.denuncianteRelacion,
                this.formData.descripcionDatosDenuncia,
                this.fase_inicial,
                this.formData.identificadorEmpresa,
                this.formData.comentario,
                nombreArchivo,
                this.formData.receptor,
                this.formData.motivo,
                this.formData.receptorInicial,
                finalizadaDenuncia,
                testigos,
                this.formData.password,
                this.formData.emailRecovery
                //this.testigos
              )
              .subscribe(
                (pin: Pin) => {
                  this.pin = pin;
                  if (this.pin.pin_code != '0') {
                    this.toastr.success(
                      this.translate.instant('texto_262'),
                      this.translate.instant('texto_263')
                    );
                    this.pin_code = this.pin.pin_code;
                    generatepin =
                      this.makeid(5) + this.pin.id_denuncia + this.makeid(5);
                    //save files in list
                    this.submitFiles(this.pin.id_denuncia);
                    if (this.formData.denuncianteEmail != '') {
                      let url =
                        environment.base + '/#/ver-denuncia/consultar-denuncia';
                      let message =
                        this.translate.instant('texto_576') +
                        ' ' +
                        this.formData.denuncianteFirstName +
                        ' ' +
                        this.formData.denuncianteLastName +
                        this.translate.instant('texto_637') + " " +
                        this.pin.pin_code + " " +
                        this.translate.instant('texto_645') + " " +
                        this.pin.id_denuncia + " " +
                        this.translate.instant('texto_638');
                      this.finalizarDenunciaService
                        .sendEmail(
                          this.formData.denuncianteEmail,
                          this.translate.instant('texto_623'),
                          message,
                          url,
                          $clau,
                          this.translate.instant('texto_624')
                        )
                        .subscribe((res) => {
                          if (res == 200) {
                            this.finalizarDenunciaService
                              .searchComplaintByPin(this.pin.id_denuncia, language)
                              .subscribe((denuncia: DatosDenuncia[]) => {
                                this.denuncia = denuncia;
                                let url2 =
                                  environment.base +
                                  '/#/editar-denuncia/' +
                                  this.denuncia[0].id_denuncia;
                                this.finalizarDenunciaService
                                  .searchReceptorByIdComplaint(
                                    this.denuncia[0].id_denuncia,
                                    true
                                  )
                                  .subscribe((receptor: Receptor[]) => {
                                    this.receptor = receptor;
                                    let messageReceptor =
                                      this.translate.instant('texto_576') +
                                      ' ' +
                                      this.receptor[0].id_user +
                                      this.translate.instant('texto_639') + " " +
                                      this.denuncia[0].id_denuncia + " " +
                                      this.translate.instant('texto_640');
                                    let asunto =
                                      this.translate.instant('texto_641');
                                    if (this.triaje == '1') {
                                      asunto =
                                        this.translate.instant('texto_642');
                                    }
                                    this.finalizarDenunciaService
                                      .sendEmail(
                                        this.receptor[0].mail_user,
                                        asunto,
                                        messageReceptor,
                                        url2,
                                        $clau,
                                        asunto
                                      )
                                      .subscribe((res) => {
                                        if (res == 200) {
                                          //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                        } else {
                                          this.toastr.error(
                                            this.translate.instant('texto_193'),
                                            this.translate.instant('texto_195')
                                          );
                                        }
                                      });
                                  });
                              });
                          } else {
                            this.toastr.error(
                              this.translate.instant('texto_193'),
                              this.translate.instant('texto_195')
                            );
                          }
                        });
                    } else {
                      let language = this.auth.getLanguage();
                      if (language == '' || language == null) {
                        language = 'es';
                      }
                      this.finalizarDenunciaService

                        .searchComplaintByPin(this.pin.id_denuncia, language)
                        .subscribe((denuncia: DatosDenuncia[]) => {
                          this.denuncia = denuncia;
                          this.finalizarDenunciaService
                            .searchReceptorByIdComplaint(
                              this.denuncia[0].id_denuncia,
                              true
                            )
                            .subscribe((receptor: Receptor[]) => {
                              this.receptor = receptor;
                              let url2 =
                                environment.base +
                                '/#/editar-denuncia/' +
                                this.denuncia[0].id_denuncia;
                              let asunto = this.translate.instant('texto_641');
                              if (this.triaje == '1') {
                                asunto = this.translate.instant('texto_642');
                              }
                              let messageReceptor =
                                this.translate.instant('texto_576') +
                                ' ' +
                                this.receptor[0].id_user +
                                this.translate.instant('texto_646') + " " +
                                this.denuncia[0].id_denuncia + " " +
                                this.translate.instant('texto_643');
                              this.finalizarDenunciaService
                                .sendEmail(
                                  this.receptor[0].mail_user,
                                  asunto,
                                  messageReceptor,
                                  url2,
                                  $clau,
                                  asunto
                                )
                                .subscribe((res) => {
                                  if (res == 200) {
                                    //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                  } else {
                                    this.toastr.error(
                                      this.translate.instant('texto_193'),
                                      this.translate.instant('texto_195')
                                    );
                                  }
                                });
                            });
                        });
                    }

                    this.router
                      .navigateByUrl('/RefreshComponent', {
                        skipLocationChange: true,
                      })
                      .then(() => {
                        this.router.navigate([
                          '/enviar-denuncia/finalizada/',
                          generatepin,
                        ]);
                      });
                  } else {
                    this.toastr.error(
                      this.translate.instant('texto_264'),
                      this.translate.instant('texto_263')
                    );
                    this.auth.logoutSimple();
                    this.router
                      .navigateByUrl('/RefreshComponent', {
                        skipLocationChange: true,
                      })
                      .then(() => {
                        this.router.navigate(['login']);
                      });
                  }
                },
                (err: any) => {
                  this.toastr.error(
                    this.translate.instant('texto_264'),
                    this.translate.instant('texto_263')
                  );
                }
              );
          }

          /* this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['finalizada/', generatepin]);
            });*/
        });
    }
  }

  submitFiles(id_denuncia) {
    //get files list
    let archivos = this.formDataService.getArchivos();
    //console.log(archivos);
    if (archivos.length > 0) {
      archivos.forEach(archivo => {
        const formData = new FormData();
        //set data to formData
        formData.append('file', archivo.nombreArchivo);
        formData.append('id_sociedad', this.formDataService.getTipoHecho().empresa);
        formData.append('id_denuncia', id_denuncia);
        formData.append('comentario', archivo.descripcionArchivo);
        formData.append('id_usuario', 'Denunciante');
        //send to the UploadFile service the parameter fomData
        this.finalizarDenunciaService.uploadFile(formData).subscribe(
          (response) => {
            //console.log('Respuesta del servicio:', response);
          },
          (error) => {
            //console.error('Error al subir el archivo:', error);
          }
        );
      });
    }

  }

  hide() {
    this.auth.logoutSimple();
    // //window.location.reload();
    this.router
      .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/inicio']);
      });
  }

  private makeid(length) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  navigateToNewPage() {
    //Replace the current history state with a new entry for the root URL ('/')
    this.auth.logoutSimple();
    window.history.replaceState(null, '', '/');
  }
}
