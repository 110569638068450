import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common'

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { GestionUsuariosService } from 'src/app/dashboard/services/gestion-usuarios.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Usuarios } from 'src/app/dashboard/models/usuarios';
import { Rol } from 'src/app/dashboard/models/rol';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css'],
  providers: [
    DatePipe,
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class UpdateUserComponent implements OnInit {
  id: string;
  validatingForm: FormGroup;
  urlPhoto: string;
  users: Usuarios[];
  roles: Rol[];
  email: string;
  username: string;
  name: string;
  surname: string;
  is_lock: boolean;
  password: string;
  sociedad: string;
  IsHiddenForm: boolean = false;
  constructor(
    private usuarioService: GestionUsuariosService,
    private apiService: ApiService,
    private auth: AuthService,
    private toastr: ToastrService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private datepipe: DatePipe) { }

  id_rol: string;
  n_rol: string;
  fec_inicio: string;
  fec_fin: string;

  ngOnInit() {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.sociedad = this.auth.getNameSociedad();
    this.id = this.actRoute.snapshot.params.id;
    this.usuarioService.searchUserById(this.id).subscribe((usuarios: Usuarios[]) => {
      this.users = usuarios;
      this.username = usuarios[0].id_user;
      this.email = usuarios[0].mail_user;
      this.name = usuarios[0].n_user;
      this.surname = usuarios[0].ape_user;
      this.is_lock = usuarios[0].is_lock;
      this.password = usuarios[0].n_password;
      this.urlPhoto = usuarios[0].foto;
      if (this.urlPhoto === null || this.urlPhoto === '') {
        this.urlPhoto = environment.baseAvatar + "avatar.png";
      }
    });

    this.apiService.searchRoleByUserSociety(this.id, this.auth.getSociedad(), '0').subscribe((roles: Rol[]) => {
      this.roles = roles;
    });


    this.validatingForm = new FormGroup({
      signupFormModalId: new FormControl('', [
        Validators.required,
      ]),
      signupFormModalEmail: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      signupFormModalName: new FormControl('', Validators.required),
      signupFormModalSurname: new FormControl('', Validators.required),

      signupFormModalNrol: new FormControl('', [Validators.required]),
      fechaInicio: new FormControl('', [Validators.required]),
      fechaFin: new FormControl('', []),
    });
  }


  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get signupFormModalSurname() {
    return this.validatingForm.get('signupFormModalSurname');
  }

  get signupFormModalEmail() {
    return this.validatingForm.get('signupFormModalEmail');
  }

  get signupFormModalNrol() {
    return this.validatingForm.get('signupFormModalNrol');
  }

  get fechaInicio() {
    return this.validatingForm.get('fechaInicio');
  }

  get fechaFin() {
    return this.validatingForm.get('fechaFin');
  }



  actualizarDatos() {
    let usernameData = this.signupFormModalId.value;
    if (usernameData == "") {
      usernameData = this.username;
    }
    let error = false;
    let emailData = (document.getElementById("form-email") as HTMLInputElement).value;
    if (emailData == "" || !this.isMail(emailData)) {
      error = true;
    }

    let nameData = (document.getElementById("form-name") as HTMLInputElement).value;
    if (nameData == "") {
      error = true;
    }

    let surnameData = (document.getElementById("form-surname") as HTMLInputElement).value;
    if (surnameData == "") {
      error = true;
    }

    if (error) {
      this.toastr.error(this.translate.instant('texto_205'), this.translate.instant('texto_198'));

    } else {
      this.usuarioService.updateUser(usernameData, emailData, nameData, surnameData).subscribe(
        res => {
          if (res == 200) {
            this.toastr.success(this.translate.instant('texto_206'), this.translate.instant('texto_198'));
          } else {
            this.toastr.error(this.translate.instant('texto_207'), this.translate.instant('texto_198'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/dashboard/layout/user/', this.id]);
          });
        });
    }

  }

  bloquearUsuario(id_user: string) {
    this.usuarioService.blockUser(id_user, "1", this.auth.getIdUser()).subscribe(
      res => {
        if (res == 200) {
          this.toastr.success(this.translate.instant('texto_209'), this.translate.instant('texto_211'));
        } else {
          this.toastr.error(this.translate.instant('texto_210'), this.translate.instant('texto_211'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/user/', this.id]);
        });
      });
  }

  desbloquearUsuario(id_user: string) {
    this.usuarioService.blockUser(id_user, "0", this.auth.getIdUser()).subscribe(
      res => {
        if (res == 200) {
          this.toastr.success(this.translate.instant('texto_212'), this.translate.instant('texto_214'));
        } else {
          this.toastr.error(this.translate.instant('texto_213'), this.translate.instant('texto_214'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/user/', this.id]);
        });
      });
  }

  /**
   * Function to send a mail with the instructions to reset password and unlock user
   * @param email user's email
   * @param id_user id_user who will be unlocked
   */
  enviarEmail(email: string, id_user:string) {
    // Determina si se deben incluir las cláusulas en el correo
    const clausulasCorreo = localStorage.getItem("clausulas_correo") === '1';
  
    // URL base del login
    const url = environment.base + "/login";
  
    /* Message content
    Estimado/a Usuario
    Se ha realizado una petición para reestablecer tu contraseña, para ello por favor sigue estos pasos: 
    1. Visita el apartado Recuperar contraseña en el Canal Ético, ubicado en la pantalla Iniciar Sesión
    2. Ingresa tu dirección de correo electrónico registrada: dirección
    3. Sigue las instrucciones para asignar una nueva contraseña. 
    */
    let message = this.translate.instant('texto_576') + " " + this.name + " " + this.surname + "<br>" +
      this.translate.instant('texto_777') + "<br><br>" +
      "1. " + this.translate.instant('texto_578') + "<br>" +
      "2. " + this.translate.instant('texto_579') + " " + this.email + "<br>" +
      "3. " + this.translate.instant('texto_775') + "<br>";

    this.apiService.sendEmail(
      email,
      this.translate.instant('texto_767'),
      message,
      url,
      clausulasCorreo,
      this.translate.instant('texto_767')
    ).subscribe(
      res => {
        if (res === 200) {
          this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
          //Unlock user
          this.usuarioService.blockUser(id_user, "0", this.auth.getIdUser()).subscribe(
            res => {
              if (res == 200) {
                this.toastr.success(this.translate.instant('texto_212'), this.translate.instant('texto_214'));
              } else {
                this.toastr.error(this.translate.instant('texto_213'), this.translate.instant('texto_214'));
              }
            });
        } else {
          this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/user/', this.id]);
        });
      }
    );
  }
  

  deleteRole(id_rol: string, fec_inicio: string) {
    this.usuarioService.deleteRoleByUserSociety(this.id, this.auth.getSociedad(), id_rol, fec_inicio).subscribe(
      res => {
        if (res == 200) {
          this.toastr.success(this.translate.instant('texto_217'), this.translate.instant('texto_219'));
        } else {
          this.toastr.error(this.translate.instant('texto_218'), this.translate.instant('texto_219'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/user/', this.id]);
        });
      });
  }

  makeKey(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  isMail(str) {
    return typeof str === 'string' && /^[\w+\d+._]+\@[\w+\d+_+]+\.[\w+\d+._]{2,8}$/.test(str);
  }

  showRol(layer: Rol) {
    this.IsHiddenForm = true;

    this.id_rol = layer.id_rol;
    this.n_rol = layer.n_rol;
    this.fec_inicio = layer.fec_inicio;
    //this.fec_inicio = this.fec_inicio.substring(8, 10) + '/' + this.fec_inicio.substring(5, 7) + '/' + this.fec_inicio.substring(0, 4);
    this.fec_inicio = this.fec_inicio.substring(0, 4) + '/' + this.fec_inicio.substring(5, 7) + '/' + this.fec_inicio.substring(8, 10);
    this.fec_fin = layer.fec_fin;
  }

  hideModal() {
    this.IsHiddenForm = false;
  }

  updateRol(id_rol: string) {
    let fec_inicio = this.fec_inicio.substring(0, 4) + '/' + this.fec_inicio.substring(5, 7) + '/' + this.fec_inicio.substring(8, 10);
    let fec_fin = this.fechaFin.value;
    if (this.fechaInicio.value == "") {
      fec_inicio = this.fec_inicio;
    }
    if (this.fechaFin.value == "") {
      fec_fin = this.fec_fin;
    }


    //let fechaFinValue = this.datepipe.transform(fec_fin, 'yyyy-MM-dd');
    let fechaFinValue = this.datepipe.transform(fec_fin, 'yyyyMMdd');
    if (fechaFinValue == "" || fechaFinValue == null) {
      fechaFinValue = "2150-01-01";
    }
    //let fechaInicioValue = this.datepipe.transform(fec_inicio, 'yyyy-MM-dd');
    let fechaInicioValue = this.datepipe.transform(fec_inicio, 'yyyyMMdd');
    let re = /\-/gi;

    let FF = fechaFinValue.replace(re, "");
    let FI = fechaInicioValue.replace(re, "");
    if (Number(FI) > Number(FF)) {
      this.toastr.error(this.translate.instant('texto_307'), this.translate.instant('texto_310'));
    } else {
      this.usuarioService.updateRolUser(this.auth.getSociedad(), this.id, id_rol, fechaInicioValue, fechaFinValue).subscribe(
        res => {
          if (res == 200) {
            this.toastr.success(this.translate.instant('texto_240'), this.translate.instant('texto_239'));
          } else {
            this.toastr.error(this.translate.instant('texto_254'), this.translate.instant('texto_239'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/dashboard/layout/user/', this.id]);
          });
        });
    }
  }

}
