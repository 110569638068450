<section style="position: absolute;width: 100%;z-index: 1;" class="image-container line-spacing"
  style="background-image: url(/assets/img/inicio/inicio-1.jpg);">
  <div class="container" style="padding: 5px;">
    <div class="board">
      <button type="button" style="z-index:100;float: right;" class="btn btn-dark" (click)="hide();"
        [attr.aria-label]="'texto_735' | translate">X</button>

      <!-- Breadcrumbs -->
      <div class="breadcrum-div">
        <nav aria-label="breadcrumb" class="nav">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' |
                translate }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{
              'texto_717' | translate }}</li>
          </ol>
        </nav>
      </div>
      <!-- End Breadcrumbs -->

      <!-- Navigation Area (Circular Tabs) -->
      <msw-navbar></msw-navbar>
      <!-- End Navigation Area (Circular Tabs) -->

      <!-- Content Area -->
      <div class="tab-content">
        <form #personalForm="ngForm" class="editForm" novalidate>
          <div class="tab-pane fade in active">
            <h2 class="head text-center">{{ title | translate }}</h2>
            <br>

            <div style="display: flex; align-items: center;">
            </div>
            <div class="container-testigps">
              <!-- Detalles del testigo -->
              <div class='row'>
                <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                  <div class="row" style="margin-top: -30px;">
                    <div class='col-xs-12 col-sm-6'>
                      <div class="form-group" style="width:100%;display:contens;color:white">
                        <label class="control-label" for="firstname">{{
                          'texto_35' | translate
                          }}</label>
                        <input class="form-control input-md" #firstname="ngModel" id="firstname" name="firstname"
                          type="text" placeholder="{{ 'texto_35' | translate }}" autocomplete="given-name"
                          [(ngModel)]="testigo.firstName">
                      </div>
                    </div>
                    <div class='col-xs-12 col-sm-6'>
                      <div class="form-group">
                        <label class="control-label" for="lastname">{{'texto_36' | translate}}</label>
                        <input class="form-control input-md" #lastname="ngModel" id="lastname" name="lastname"
                          type="text" placeholder="{{ 'texto_36' | translate }}" autocomplete="family-name"
                          [(ngModel)]="testigo.lastName">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class='col-xs-12 col-sm-6'>
                      <div class="form-group">
                        <label class="control-label" for="email"> {{'texto_82' | translate}}</label>
                        <input class="form-control input-md" #email="ngModel" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                          id="email" name="email" type="text" placeholder="{{'texto_82' | translate}}" autocomplete="email"
                          [(ngModel)]="testigo.email"
                          [attr.aria-invalid]="email.invalid && (email.dirty || email.touched)">
                        <div *ngIf="email.invalid " class="alert alert-danger">
                          <div style="font-size:12px">
                            {{ 'texto_106' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-xs-12 col-sm-6'>
                      <div class="form-group">
                        <label class="control-label" for="phone">{{ 'texto_66' |translate}}</label>
                        <input class="form-control input-md" #phone="ngModel" id="phone" name="phone" maxlength="25"
                          pattern="[0-9+]{1,25}" type="text" placeholder="{{ 'texto_66' | translate }}"
                          autocomplete="tel" [(ngModel)]="testigo.phone"
                          [attr.aria-invalid]="phone.invalid && (phone.dirty || phone.touched)">
                        <div *ngIf="phone.invalid " class="alert alert-danger">
                          <div style="font-size:12px">
                            {{ 'texto_105' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class='col-xs-12 col-sm-12'>
                      <label class="control-label" for="description">{{'texto_95' | translate }}</label>
                      <textarea maxlength="4000" class="form-control input-md" #description="ngModel" id="description"
                        name="description" type="text" placeholder="{{ 'texto_95' | translate }}"
                        [(ngModel)]="testigo.description" rows="6"></textarea>
                    </div>
                  </div>
                  <!-- Botón para agregar más testigos -->
                  <div class="form-group text-center" style="margin-top: 30px;">
                    <button  mdbBtn rounded="true" class="btn-add" mdbWavesEffect
                      (click)="addPersonal()" [disabled]="!isFormFilled() || !personalForm.valid">
                      {{'texto_104'| translate}}</button>
                  </div>

                  <!-- Lista de testigos -->
                  <div class="row">
                    <div class="col-md-12 list" *ngIf="personalList.length > 0">
                      <label>{{ 'texto_500' | translate }}:</label>
                      <div class="table-responsive  table-witnesses">
                        <table class="table" style="width: 120%;">
                          <thead>
                            <tr>
                              <th>{{ 'texto_35' | translate }}</th>
                              <th>{{ 'texto_36' | translate }}</th>
                              <th> {{'texto_38' | translate}}</th>
                              <th>{{ 'texto_66' |translate}}</th>
                              <th>{{'texto_63' | translate }}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let testigo of personalList; let i = index">
                              <td>{{ testigo.firstName }}</td>
                              <td>{{ testigo.lastName }}</td>
                              <td>{{ testigo.email }}</td>
                              <td>{{ testigo.phone }}</td>
                              <td>{{ testigo.description }}
                              </td>
                              <td class="td-actions" style="width:40px;">
                                <span class="delete-icon" (click)="deletePersonal(i)">
                                  <i class="fa fa-trash text-danger"></i>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    style="font-size: 0.75rem;line-height: 1.5;border: 0px solid black;padding-top: 10px; padding-top: 10px; text-align: justify;">
                    <p>{{ 'texto_758' | translate }}</p>
                  </div>

                  <!-- <ul class="list-group-sm">
                    <ng-container
                      *ngFor="let testigo of personalList; let i = index">
                      <li class="list-group-item"
                        *ngIf="testigo.firstName || testigo.lastName || testigo.email || testigo.phone || testigo.description">
                        <span *ngIf="testigo.firstName || testigo.lastName">{{
                          testigo.firstName }} {{ testigo.lastName }} </span>
                        <span *ngIf="testigo.email">{{ testigo.email }} </span>
                        <span *ngIf="testigo.phone">{{ testigo.phone }} </span>
                        <span *ngIf="testigo.description">{{ testigo.description
                          }} </span>
                        <span class="delete-icon" (click)="deletePersonal(i)">
                          <i class="fa fa-trash text-danger"></i>
                        </span>
                      </li>
                    </ng-container>
                  </ul> -->

                  <!-- <ul class="list-group-sm">
                    <ng-container
                      *ngFor="let testigo of personalList; let i = index">
                      <li class="list-group-item"
                        *ngIf="testigo.firstName || testigo.lastName || testigo.email || testigo.phone || testigo.description">
                        <span *ngIf="testigo.firstName || testigo.lastName">{{ testigo.firstName }} {{ testigo.lastName }} </span>
                        <span *ngIf="testigo.email">{{ testigo.email }} </span>
                        <span *ngIf="testigo.phone">{{ testigo.phone }} </span>
                        <span *ngIf="testigo.description">{{ testigo.description }} </span>
                        <span class="delete-icon" (click)="deletePersonal(i)">
                          <i class="fa fa-trash text-danger"></i>
                        </span>
                      </li>
                    </ng-container>
                  </ul> -->

                  <div class="form-group text-center" style="margin-top: 30px;">
                  </div>
                </div>
              </div>
            </div>

            <!-- Botones de navegación -->
            <div class="form-group text-center" style="margin-top: 30px;">
              <button (click)="goToPrevious(personalForm)"  mdbBtn rounded="true" class="btn-back"
                mdbWavesEffect>
                <span style="margin-right:10px;" class="glyphicon glyphicon-arrow-left"></span>{{ 'texto_142' |
                translate }}
              </button>

              <button [disabled]="!personalForm.valid" (click)="goToNext(personalForm)"  mdbBtn
                rounded="true" class="btn-next" mdbWavesEffect type="button">{{ 'texto_141' | translate }} <span
                  style="margin-left:10px;" class="glyphicon glyphicon-arrow-right"></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>