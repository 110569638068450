import { Component, OnInit, Input, HostListener, ElementRef, Renderer2, RendererFactory2 } from '@angular/core';
import { FormDataService } from './data/formData.service';
import { AuthService } from './shared/services/auth.service';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Parametros } from './model/parametros';
import { ApiService } from './shared/services/api.service';
import { environment } from "../environments/environment";
import { filter, switchMap, tap } from 'rxjs/operators';
import { NavigationService } from './shared/services/navigation.Service';
import { Title } from '@angular/platform-browser';
import { HeadGoogleAnalyticsService } from './shared/services/head-google-analytics.service';

@Component({
  selector: 'homepage-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit {
  title = 'CanalDenuncia.app';
  IsHiddenLanding = false;
  IsFormulario = false;

  // variable to control the menu status
  collapse: boolean = true;
  @Input() formData;
  IsHidden = true;
  href: string = "";

  parametros: Parametros;
  colorprincipal: string = "";
  colorsecundario: string = "";
  colortxtbtn: string = "";
  colortxttitulo: string = "";
  colortxtfondo: string = "";
  colorbgseccion1: string = "";
  colortxtseccion1: string = "";
  colorbgseccion2: string = "";
  colortxtseccion2: string = "";
  colorbgseccion3: string = "";
  colortxtseccion3: string = "";
  clausulas_correo: string = "";
  gestionar_conflicto: string = "";
  triaje: string = "";
  centros: string = ""; //valor del check Centros
  inputAutocomplete: string = "";
  diasinves: string = "";
  diasdecisor: string = "";
  diascompliance: string = "";
  terminos: string = "";
  url: string = "";
  documento: string = "";

  politicaEnabled: string = "";
  urlpolitica: string = "";
  docpolitica: string = "";

  politicaClienteEnabled: string = "";
  urlpoliticacliente: string = "";
  docpoliticacliente: string = "";

  colormenulateral: string = "";
  colortxtcabeceras: string = "";
  colortxtbotones: string = "";
  version: string = "";
  boton1: string = "";
  docboton1: string = "";
  boton2: string = "";
  docboton2: string = "";
  boton3: string = "";
  docboton3: string = "";
  logo: string = "";
  HabSSO: string = "";
  forzarloSSO: string = "";
  forzarSSO: string = "";
  dateYear: number = 0;
  language: string;
  popUp: string = "";
  buttonsDisabled: string = "";
  IdPersonalizableEnabled: string = "";
  googleAnalytics: string = "";

  constructor(
    private apiService: ApiService,
    private formDataService: FormDataService,
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private navigationService: NavigationService,
    private titleService: Title,
    private headService: HeadGoogleAnalyticsService
  ) {
    translate.addLangs(['en', 'es', 'pt', 'it']);
    translate.setDefaultLang('es'); this.getDateYear();

    // Set title based on selected language
    this.translate.get('texto_6', { value: this.language }).subscribe((text: string) => {
      this.titleService.setTitle(text);
    });

  }

  ngOnInit() {
    this.language = this.auth.getLanguage();
    if (this.language == "" || this.language == null) {
      this.language = 'es';
    }
    this.translate.setDefaultLang(this.language);
    this.setHtmlLangAttribute(this.language); //changed Language in Index.html Lang atribute

    let web = environment.base;
    const location = window.location.href;

    //Get token login from url
    const token = location.split('token=')[1];

    if (!token) {
      this.configClientParams(web);
      this.initStuff();
    }

    // Calls the method to check window width
    this.checkWindowWidth();

    //if google analitycs is active
    if (localStorage.getItem('googleAnalytics') == "1") {
      try {
        this.headService.initializeAnalytics();
      } catch (error) {
        console.error('Error al cargar Google Analytics:', error);
      }
    }

  }

  //Method for update atribute lang in index.html
  setHtmlLangAttribute(lang: string) {
    document.documentElement.lang = lang;
  }

  ngOnDestroy() {
    // Removes the event listener when the component is destroyed
    window.removeEventListener('resize', this.checkWindowWidth);
  }

  /**
   * Get Year For All Rights Reserved
   */
  getDateYear() {
    const currenDate = new Date();
    this.dateYear = currenDate.getFullYear();
  }

  /**
 * This method handles sidebar visibility, form data retrieval, and navigation based on the presence
 * of an authentication token and URL segments. It performs the following actions:
 *
 * 1. Opens or closes the sidebar based on the `Centros` value.
 * 2. Retrieves form data using the form data service.
 * 3. Checks if an access token is present in local storage to determine navigation behavior.
 * 4. Parses the current URL to decide the appropriate navigation path.
 * 5. Navigates to different routes based on the extracted URL segments and conditions.
 */
  private initStuff() {
    // Open the sidebar if the Centros feature is enabled
    this.auth.openSidebar(this.auth.getCentros() === '1');

    // Retrieve form data
    this.formData = this.formDataService.getFormData();

    // Get the access token and URL segments
    const token = localStorage.getItem("access_token2");
    const urlSegments = window.location.href.split("/", 6);
    const path = urlSegments[4];
    const subPath = urlSegments[5];
    const passwordToken = urlSegments[urlSegments.length - 1];

    // If an access token (login) is present, navigate to the dashboard
    if (token) {
      this.IsHiddenLanding = true;
      this.IsFormulario = true;
      this.router.navigate(['dashboard']);
    } else {
      this.IsHiddenLanding = true;

        // If the URL contains 'restablecer-contrasenia-denuncia', navigate to the password reset page
        if (passwordToken.includes('restablecer-contrasenia-denuncia')) {
            this.router.navigate(['restablecer-contrasenia-denuncia']);
            return;
        // If the URL contains 'restablecer-contrasenia', navigate to the password complaint reset page
        } else if (passwordToken.includes('restablecer-contrasenia')){
          this.router.navigate(['restablecer-contrasenia']);
          return;
        }

      // Navigate based on the value of `path`
      switch (path) {
        case "login":
          this.IsHiddenLanding = false;
          break;

        case undefined:
        case "":
          this.IsHiddenLanding = false;
          this.router.navigate(['inicio']);
          break;

        case "ver-denuncia":
        case "enviar-denuncia":
          // Navigate to specific sub-paths within 'ver-denuncia' or 'enviar-denuncia'
          if (subPath === "consultar-denuncia" || subPath === "finalizar-denuncia") {
            this.router.navigate([`/${path}/${subPath}`]);
          }
          break;

        default:
          // Default case: Navigate to 'inicio'
          this.IsHiddenLanding = false;
          this.router.navigate(['inicio']);
          break;
      }
    }
  }

  private configClientParams(web: string) {
    this.apiService.searchParamCliente(web).subscribe((parametros: Parametros) => {
      this.parametros = parametros;
      this.clausulas_correo = this.parametros[0].valor;
      this.colorbgseccion1 = this.parametros[1].valor;
      this.colorbgseccion2 = this.parametros[2].valor;
      this.colorbgseccion3 = this.parametros[3].valor;
      this.colorprincipal = this.parametros[4].valor;
      this.colorsecundario = this.parametros[5].valor;
      this.colortxtbtn = this.parametros[6].valor;
      this.colortxtseccion1 = this.parametros[7].valor;
      this.colortxtseccion2 = this.parametros[8].valor;
      this.colortxtseccion3 = this.parametros[9].valor;
      this.colortxttitulo = this.parametros[10].valor;
      this.gestionar_conflicto = this.parametros[11].valor;
      this.diascompliance = this.parametros[12].valor;
      this.diasdecisor = this.parametros[13].valor;
      this.diasinves = this.parametros[14].valor;
      this.logo = this.parametros[15].valor;
      this.terminos = this.parametros[16].valor;
      this.documento = this.parametros[17].valor;
      this.url = this.parametros[18].valor;
      this.colormenulateral = this.parametros[19].valor;
      this.colortxtcabeceras = this.parametros[20].valor;
      this.colortxtbotones = this.parametros[21].valor;
      this.version = this.parametros[22].valor;
      this.docboton1 = this.parametros[23].valor;
      this.boton1 = this.parametros[24].valor;
      this.docboton2 = this.parametros[25].valor;
      this.boton2 = this.parametros[26].valor;
      this.docboton3 = this.parametros[27].valor;
      this.boton3 = this.parametros[28].valor;
      this.triaje = this.parametros[29].valor;
      this.centros = this.parametros[30].valor;
      this.inputAutocomplete = this.parametros[31].valor;
      this.popUp = this.parametros[32].valor;
      this.politicaEnabled = this.parametros[33].valor;
      this.docpolitica = this.parametros[34].valor;
      this.urlpolitica = this.parametros[35].valor;
      this.colortxtfondo = this.parametros[36].valor;
      this.IdPersonalizableEnabled = this.parametros[37].valor;
      this.politicaClienteEnabled = this.parametros[38].valor;
      this.docpoliticacliente = this.parametros[39].valor;
      this.urlpoliticacliente = this.parametros[40].valor;
      this.googleAnalytics = this.parametros[41].valor;
      this.HabSSO = this.parametros[42].valor;
      this.forzarloSSO = this.parametros[43].valor;
      this.forzarSSO = this.parametros[44].valor;
      // Assign 1 to buttonsDisabled if all buttons are disabled.
      if (this.boton1 == '0' && this.boton2 == '0' && this.boton3 == '0') {
        this.buttonsDisabled = '1';
      }
      // console.log('Parámetros del cliente:', parametros);
      //console.log(this.manual);
      //console.log(this.docmanual);


      localStorage.setItem('gestionar_conflicto', this.gestionar_conflicto);
      localStorage.setItem('clausulas_correo', this.clausulas_correo);

      localStorage.setItem('diasinves', this.diasinves);
      localStorage.setItem('diasdecisor', this.diasdecisor);
      localStorage.setItem('diascompliance', this.diascompliance);

      localStorage.setItem('terminos', this.terminos);
      localStorage.setItem('url', this.url);
      localStorage.setItem('documento', this.documento);

      localStorage.setItem('politicaEnabled', this.politicaEnabled);
      localStorage.setItem('urlpolitica', this.urlpolitica);
      localStorage.setItem('docpolitica', this.docpolitica);

      localStorage.setItem('politicaClienteEnabled', this.politicaClienteEnabled);
      localStorage.setItem('urlpoliticacliente', this.urlpoliticacliente);
      localStorage.setItem('docpoliticacliente', this.docpoliticacliente);

      localStorage.setItem('version', this.version);
      localStorage.setItem('boton1', this.boton1);
      localStorage.setItem('docboton1', this.docboton1);

      localStorage.setItem('boton2', this.boton2);
      localStorage.setItem('docboton2', this.docboton2);

      localStorage.setItem('boton3', this.boton3);
      localStorage.setItem('docboton3', this.docboton3);

      localStorage.setItem('triaje', this.triaje);
      localStorage.setItem('centros', this.centros);
      localStorage.setItem('inputAutocomplete', this.inputAutocomplete);

      localStorage.setItem('HabSSO', this.HabSSO);
      localStorage.setItem('forzarloSSO', this.forzarloSSO);
      localStorage.setItem('forzarSSO', this.forzarSSO);

      localStorage.setItem('popUp', this.popUp);
      localStorage.setItem('idPersonalizable', this.IdPersonalizableEnabled);

      localStorage.setItem('googleAnalytics', this.googleAnalytics);


      this.setEstilosCliente(this.colorprincipal, this.colorsecundario, this.colortxtbtn, this.colortxttitulo, this.colortxtseccion1, this.colorbgseccion2, this.colortxtseccion2, this.colorbgseccion3, this.colortxtseccion3, this.colorbgseccion1, this.colormenulateral, this.colortxtcabeceras, this.colortxtbotones, this.colortxtfondo);
    });
  }

  isOn(item) {
    this.IsHidden = false;
    this.IsHiddenLanding = true;
    this.IsFormulario = true;
  }

  hideModal() {
    this.auth.logoutSimple();
    this.IsHidden = true;
    this.IsHiddenLanding = false;
    this.IsFormulario = false;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/login']);
    });
  }

  setEstilosCliente(color1: string, color2: string, color3: string, color4: string, color5: string, color6: string, color7: string, color8: string, color9: string, color10: string, color11: string, color12: string, color13: string, color14: string) {
    document.documentElement.style.setProperty('--main-color-bg', color1);
    document.documentElement.style.setProperty('--secondary-color-bg', color2);
    document.documentElement.style.setProperty('--color-txt-btn', color3);
    document.documentElement.style.setProperty('--color-txt-titulo', color4);
    document.documentElement.style.setProperty('--color-txt-seccion-1', color5);
    document.documentElement.style.setProperty('--color-bg-seccion2', color6);
    document.documentElement.style.setProperty('--color-txt-seccion2', color7)
    document.documentElement.style.setProperty('--color-bg-seccion3', color8);
    document.documentElement.style.setProperty('--color-txt-seccion3', color9);
    document.documentElement.style.setProperty('--color-bg-seccion1', color10);
    document.documentElement.style.setProperty('--color-txt-menu-lateral', color11);
    document.documentElement.style.setProperty('--color-txt-cabeceras', color12);
    document.documentElement.style.setProperty('--color-txt-botones', color13);
    document.documentElement.style.setProperty('--color-txt-fondo', color14);
  }

  login2() {
    this.apiService.loginSingleSignOn();
  }

  mostrarLogin() {
    this.router.navigate(['/login'])
  }

  logar() {
    //console.log(localStorage.getItem("forzarSSO"));
    if (localStorage.getItem("forzarSSO") == "1") {
      this.login2();
    } else {
      this.mostrarLogin();
    }
  }

  /**
  * Method for navigating between sections of the home page.
  * @param sectionId - The ID of the section to navigate to.
  */
  navigateToSection(sectionId: string) {
    this.navigationService.navigateToSection(sectionId);
  }

  /**
   * Method for navigating to "boton1" or "url" after navigating to section "services".
   */
  navigateToButton() {
    // Navigate to the 'services' section first.
    this.navigationService.navigateToSection('services');
    // Focus button or url
    if (this.buttonsDisabled == "1") {
      this.navigationService.navigateToUrlSupport();

    } else {
      this.navigationService.navigateToButton();
    }
  }


  /**
  * Toggles the collapse state of the menu.
  * If the menu is collapsed, it expands it, and vice versa.
  */
  toggleCollapse(): void {
    this.collapse = !this.collapse;
  }

  /**
  * Listens for window resize events and triggers the method to check window width
  */
  @HostListener('window:resize', ['$event'])
  checkWindowWidth() {
    this.collapse = window.innerWidth > 991;
  }


}
