<!-- Saltar al contenido principal -->
<p>
    <a href="#mainContent" id="skip-link" class='admin-bar skip-link' href="javascript:void(0);"
        (click)="navigateToButton()">{{ 'texto_743'|translate }}</a>
</p>
<app-header></app-header>
<!-- End Saltar al contenido principal -->
<section id="mainContent" role="main" tabindex="-1">
    <div class="container">
        <!-- close button -->
    <div class="close">
        <a href="/inicio" class="close-btn" [attr.aria-label]="'texto_735' | translate"><i
                class="bi bi-x-square-fill"></i></a>
    </div>
    <!-- end close button -->

    <!-- breadcrumbs -->
    <div class="breadcrum-div">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' | translate }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ 'texto_787' | translate }}</li>
            </ol>
        </nav>
    </div>
    <!-- end breadcrumbs -->
        <div class="row justify-content-center">
            <div class="col-md-6 d-flex justify-content-center row-logo">
                <img class="logo" src="assets/img/logos/logo.png"
                    alt="{{ 'texto_294' | translate }} {{ 'texto_6' | translate }}">
            </div>
        </div>

        <form [formGroup]="validatingForm" style="margin-left: 25px; margin-right: 25px;">
            <div class="row justify-content-center">
                <div class="col-md-6 mx-auto">
                    <p class="description-form ">{{ 'texto_789' | translate }}</p>
                </div>
            </div>
            <!-- id denuncia input -->
            <div class="row justify-content-center">
                <div class="col-md-6 mx-auto">
                    <div class="form-group">
                        <label class="control-label" for="idDenuncia">
                            {{ 'texto_362' | translate }}
                        </label>
                        <div style="position: relative;">
                            <input formControlName="idDenuncia" class="form-control" id="idDenuncia"
                                placeholder="{{ 'texto_362' | translate }}">
                            <mdb-error
                                *ngIf="validatingForm.get('idDenuncia')?.invalid && (validatingForm.get('idDenuncia')?.dirty || validatingForm.get('idDenuncia')?.touched)">
                                {{ 'texto_61' | translate }}
                            </mdb-error>
                            <mdb-success *ngIf="validatingForm.get('idDenuncia')?.valid">
                                {{ 'texto_62' | translate }}
                            </mdb-success>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Password input -->
            <div class="row justify-content-center">
                <div class="col-md-6 mx-auto">
                    <div class="form-group">
                        <label class="control-label" for="newPassword">
                            {{ 'texto_127' | translate }}
                        </label>
                        <div style="position: relative;">
                            <input formControlName="newPassword" [type]="passwordVisible ? 'text' : 'password'"
                                class="form-control" id="newPassword" placeholder="{{ 'texto_127' | translate }}">
                            <i class="fa" [ngClass]="{'fa-eye': !passwordVisible, 'fa-eye-slash': passwordVisible}"
                                (click)="togglePasswordVisibility()"
                                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer;"></i>
                            <mdb-error
                                *ngIf="validatingForm.get('newPassword')?.invalid && (validatingForm.get('newPassword')?.dirty || validatingForm.get('newPassword')?.touched)">
                                {{ 'texto_61' | translate }}
                            </mdb-error>
                            <mdb-success *ngIf="validatingForm.get('newPassword')?.valid">
                                {{ 'texto_62' | translate }}
                            </mdb-success>
                        </div>
                        <!-- Details password -->
                        <div class="d-flex justify-content-center"
                            *ngIf="validatingForm.get('newPassword')?.invalid && (validatingForm.get('newPassword')?.dirty || validatingForm.get('newPassword')?.touched)">
                            <div class="card mt-3 text-center">
                                <div class="card-body">
                                    <ul class="password-criteria list-unstyled">
                                        <li [ngClass]="{'valid': hasUpperCase, 'invalid': !hasUpperCase}">
                                            <i class="fa"
                                                [ngClass]="{'fa-check-circle': hasUpperCase, 'fa-times-circle': !hasUpperCase}"></i>
                                            {{ 'texto_791' | translate }}
                                        </li>
                                        <li [ngClass]="{'valid': hasLowerCase, 'invalid': !hasLowerCase}">
                                            <i class="fa"
                                                [ngClass]="{'fa-check-circle': hasLowerCase, 'fa-times-circle': !hasLowerCase}"></i>
                                            {{ 'texto_792' | translate }}
                                        </li>
                                        <li [ngClass]="{'valid': hasNumber, 'invalid': !hasNumber}">
                                            <i class="fa"
                                                [ngClass]="{'fa-check-circle': hasNumber, 'fa-times-circle': !hasNumber}"></i>
                                            {{ 'texto_793' | translate }}
                                        </li>
                                        <li [ngClass]="{'valid': hasSpecialChar, 'invalid': !hasSpecialChar}">
                                            <i class="fa"
                                                [ngClass]="{'fa-check-circle': hasSpecialChar, 'fa-times-circle': !hasSpecialChar}"></i>
                                            {{ 'texto_794' | translate }}
                                        </li>
                                        <li [ngClass]="{'valid': hasMinLength, 'invalid': !hasMinLength}">
                                            <i class="fa"
                                                [ngClass]="{'fa-check-circle': hasMinLength, 'fa-times-circle': !hasMinLength}"></i>
                                            {{ 'texto_795' | translate }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- End Details password -->
                    </div>
                </div>
            </div>

            <!-- Repeated password input -->
            <div class="row justify-content-center">
                <div class="col-md-6 mx-auto">
                    <div class="form-group">
                        <label class="control-label" for="repeatedPassword">
                            {{ 'texto_128' | translate }}
                        </label>
                        <div style="position: relative;">
                            <input formControlName="repeatedPassword"
                                [type]="repeatedPasswordVisible ? 'text' : 'password'" class="form-control"
                                id="repeatedPassword" placeholder="{{'texto_128' | translate}}">
                            <i class="fa"
                                [ngClass]="{'fa-eye': !repeatedPasswordVisible, 'fa-eye-slash': repeatedPasswordVisible}"
                                (click)="toggleRepeatedPasswordVisibility()"
                                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer;"></i>
                            <mdb-error
                                *ngIf="validatingForm.get('repeatedPassword')?.dirty && validatingForm.get('repeatedPassword')?.value && validatingForm.get('repeatedPassword')?.value !== validatingForm.get('newPassword')?.value">
                                {{ 'texto_61' | translate }}
                            </mdb-error>
                            <mdb-success
                                *ngIf="validatingForm.get('repeatedPassword')?.valid && validatingForm.get('repeatedPassword')?.value === validatingForm.get('newPassword')?.value">
                                {{ 'texto_62' | translate }}
                            </mdb-success>
                        </div>
                        <!-- Password Details -->
                        <div class="d-flex justify-content-center"
                            *ngIf="validatingForm.get('repeatedPassword')?.dirty && validatingForm.get('repeatedPassword')?.value && validatingForm.get('repeatedPassword')?.value !== validatingForm.get('newPassword')?.value">
                            <div class="card mt-3 text-center">
                                <div class="card-body">
                                    <ul class="password-criteria list-unstyled">
                                        <li class="invalid">
                                            <i class="fa fa-times-circle"></i>
                                            {{ 'texto_223' | translate }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Password details-->
            <div class="d-flex justify-content-center btn-padding">
                <button (click)="saveNewPassword()"
                    [disabled]="!validatingForm.valid && validatingForm.get('repeatedPassword')?.value !== validatingForm.get('newPassword')?.value"
                    class="btn">
                    {{ 'texto_384' | translate }}
                </button>
            </div>
        </form>
    </div> 
</section>
<app-footer [isFixed]="false"></app-footer>