import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { Usuarios } from '../dashboard/models/usuarios';
import { PlazosDenuncia } from '../model/plazosDenuncia';

@Component({
  selector: 'app-add-investigator',
  templateUrl: './add-investigator.component.html',
  styleUrls: ['./add-investigator.component.css']
})
export class AddInvestigatorComponent implements OnInit {
  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  @Input() denuncia: DatosDenuncia;
  investigadores: Usuarios[];
  inves_select: Usuarios[];
  plazos: PlazosDenuncia[];
  investigadorF:string = '';
  plazoFaseInvestigacionF:string = '';
  plazoFaseDecisorF:string = '';
  plazoFaseComplianceF:string = '';
  cargado: boolean = false;

  diasinves:string = '';
  diasdecisor:string = '';
  diascompliance:string = '';

  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.diasinves = localStorage.getItem("diasinves");
    this.diasdecisor = localStorage.getItem("diasdecisor");
    this.diascompliance = localStorage.getItem("diascompliance");

    //console.log(this.diasinves);
    //console.log(this.diasdecisor);
    //console.log(this.diascompliance);

    this.validatingForm = new FormGroup({
      investigador: new FormControl('', []),
      plazoFaseInvestigacion: new FormControl('', [Validators.required]),
      plazoFaseDecisor: new FormControl('', Validators.required),
      plazoFaseCompliance: new FormControl('', [Validators.required]),
    });


    let url = window.location.href.split("/", 5);
    let idDenuncia = url[4];

    this.apiService.searchUserByIdSocietyRole(this.auth.getSociedad(),"2",false,"nulo").subscribe((usuarios: Usuarios[])=>{
      this.investigadores = usuarios;
    });

    this.apiService.searchInvesSelect(this.auth.getSociedad(),idDenuncia,"2").subscribe((usuarios2: Usuarios[])=>{
      this.inves_select = usuarios2;
    });


    /*let url = window.location.href.split("/", 6);
    let idDenuncia = url[5]; */
    this.apiService.searchPlazosByIdDenuncia(idDenuncia).subscribe((investigator: PlazosDenuncia[])=>{
      this.plazos = investigator;
      if(this.plazos.length > 0 ){
        if(this.plazos[0].id_denuncia!= ""){
          this.cargado = true;
          this.plazoFaseInvestigacionF = this.plazos[0].plazo_investigacion;
          this.plazoFaseDecisorF = this.plazos[0].plazo_decisor;
          this.plazoFaseComplianceF = this.plazos[0].plazo_compliance;
        }
      }else{
        this.plazoFaseInvestigacionF = this.diasinves;
        this.plazoFaseDecisorF = this.diasdecisor;
        this.plazoFaseComplianceF = this.diascompliance;
      }
    });
  }

  get investigador() {
    return this.validatingForm.get('investigador');
  }

  get plazoFaseInvestigacion() {
    return this.validatingForm.get('plazoFaseInvestigacion');
  }

  get plazoFaseDecisor() {
    return this.validatingForm.get('plazoFaseDecisor');
  }

  get plazoFaseCompliance() {
    return this.validatingForm.get('plazoFaseCompliance');
  }

  addInvestigator(){
    if(this.plazoFaseInvestigacion.value == "" || this.plazoFaseDecisor.value == "" ||  this.plazoFaseCompliance.value == "" || this.investigador.value == ""){
      this.toastr.error(this.translate.instant('texto_323'), this.translate.instant('texto_274'));
    }else{
      this.apiService.createDeadlinesByIdComplaint(this.auth.getSociedad(), this.denuncia[0].id_denuncia, this.plazoFaseInvestigacion.value, this.plazoFaseDecisor.value, this.plazoFaseCompliance.value, this.investigador.value).subscribe(
        res => {
          if(res == 200){
            this.toastr.success(this.translate.instant('texto_325'), this.translate.instant('texto_326'));
            /*this.apiService.createHistoryComplaintById(this.denuncia[0].id_denuncia,"1",this.auth.getSociedad(),this.investigador.value).subscribe(
              res => {
                if(res == 200){
                  this.toastr.success(this.translate.instant('Investigador asignado correctamente'), this.translate.instant('texto_274'));
                }else{
                  this.toastr.error(this.translate.instant('texto_327'), this.translate.instant('texto_274'));
                }
              },
              err =>{
                this.toastr.error(this.translate.instant('texto_327'), this.translate.instant('texto_274'));
              }); */
              this.apiService.createUserByIdComplaint(this.auth.getSociedad(), this.investigador.value, this.denuncia[0].id_denuncia,"1", '1','1', true).subscribe(
                res => {
                  if(res == 200){
                    this.toastr.success(this.translate.instant('texto_273'), this.translate.instant('texto_274'));
                  }else{
                   // this.toastr.error(this.translate.instant('texto_327'), this.translate.instant('texto_274'));
                  }
                },
                err =>{
                  this.toastr.error(this.translate.instant('texto_327'), this.translate.instant('texto_274'));
                });
          }else{
            this.toastr.error(this.translate.instant('texto_465'), this.translate.instant('texto_326'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
          });
      });
    }

  }

  addDays(date: Date, days: number): Date {
         date.setDate(date.getDate() + days);
         return date;
     }

  deleteinves(id_user:string){
      this.apiService.countInvesSelect(this.auth.getSociedad(), this.denuncia[0].id_denuncia, id_user,"2").subscribe(
        res => {
          if(res == 200){
            this.apiService.deleteInvesSelect(id_user, this.auth.getSociedad(), this.denuncia[0].id_denuncia,"2").subscribe(
              res => {
                if(res == 200){
                  this.toastr.success(this.translate.instant('texto_462'), this.translate.instant('texto_463'));
                }else{
                  this.toastr.error(this.translate.instant('texto_464'), this.translate.instant('texto_463'));
                }
                this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                  this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
              });
            });
          }else{
            this.toastr.error(this.translate.instant('texto_464'), this.translate.instant('texto_463'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
          });
      });
    }
}


