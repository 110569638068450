import { jwtDecode } from 'jwt-decode';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/auth/services/login.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { ConsultarDenunciaService } from '../../services/consultar-denuncia.service';

@Component({
  selector: 'app-recuperar-contrasenia-denuncia',
  templateUrl: './recuperar-contrasenia-denuncia.component.html',
  styleUrls: ['./recuperar-contrasenia-denuncia.component.css']
})
export class RecuperarContraseniaDenunciaComponent implements OnInit {

  validatingForm: FormGroup;  // Form group for password validation
  passwordVisible = false;
  repeatedPasswordVisible = false;
  emailUser: string = '';
  language: string;

  hasUpperCase: boolean = false;
  hasLowerCase: boolean = false;
  hasNumber: boolean = false;
  hasSpecialChar: boolean = false;
  hasMinLength: boolean = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private consultarDenuncia: ConsultarDenunciaService
  ) {
    // Initialize the form group with controls and their validators.
    this.validatingForm = this.fb.group({
      idDenuncia: ['', [Validators.required]],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_]).+$')
      ]],
      repeatedPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_]).+$')
      ]]
    });
  }

  /**
   * Set the default language for translations and fetches the user's email.
   */
  ngOnInit(): void {
    // Set the default language if not already set
    if (this.language == "" || this.language == null) {
      this.language = 'es';
    }
    this.translate.setDefaultLang(this.language);

    this.toastr.info(
      this.translate.instant('texto_796'),
      this.translate.instant('texto_789'), {
      timeOut: 0,
      closeButton: true,
    });

    this.validatingForm.get('newPassword')?.valueChanges.subscribe((password: string) => {
      this.checkPasswordStrength(password);
    });

    // Decode JWT token and get the email
    const token = localStorage.getItem('token_password_compaint');
    if (token) {
      try {
        const decodedToken: any = jwtDecode(token);
        this.emailUser = decodedToken.data?.email || '';  
      } catch (error) {
        console.error('Error decoding the JWT token:', error);
      }
    } else {
      console.log('No token found in localStorage.');
    }


  }

  /**
   * Function to validate strength password
   */
  checkPasswordStrength(password: string): void {
    const uppercasePattern = /[A-Z]/;
    const lowercasePattern = /[a-z]/;
    const numberPattern = /\d/;
    const specialCharPattern = /[\W_]/;

    this.hasUpperCase = uppercasePattern.test(password);
    this.hasLowerCase = lowercasePattern.test(password);
    this.hasNumber = numberPattern.test(password);
    this.hasSpecialChar = specialCharPattern.test(password);
    this.hasMinLength = password.length >= 8;
  }

  /**
 * Saves the new password by sending it to the backend for the current user.
 * Displays a success or error message based on the response.
 */
  saveNewPassword(): void {
    // Check if the form is valid before proceeding
    if (this.validatingForm.valid) {
      const idDenuncia = this.validatingForm.value.idDenuncia;
      const newPassword = this.validatingForm.value.newPassword;

      // First request to check if the complaint isn't terminated yet
      this.consultarDenuncia.searchComplaintNoTerminated(idDenuncia)
        .subscribe(
          (res) => {
            // If complaint isn't terminated and has a code of 200.
            if (res.code == 200) {
              this.consultarDenuncia.saveNewPasswordComplaint(idDenuncia, this.emailUser, newPassword)
                .subscribe(
                  (res: any) => {
                    // If the response has code 200, show the success message
                    if (res.code == 200) {
                      this.toastr.success(this.translate.instant('texto_772'));

                      // Refresh the component and navigate to consultar-denuncia
                      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true })
                        .then(() => {
                          this.router.navigate(['ver-denuncia/consultar-denuncia']);
                        });
                    } 
                  },
                  (error: any) => {
                    if (error.status === 404) {
                      this.toastr.error(
                        this.translate.instant('texto_798'),
                        this.translate.instant('texto_797')
                      );
                    } else {
                      this.toastr.error(
                        this.translate.instant('texto_773')
                      );
                    }
                  }
                );
            } 
          },
          (error: any) => {
            if (error.status === 410) {
              this.toastr.error(
                this.translate.instant('texto_801'),
                this.translate.instant('texto_800')
              );
            } else if(error.status === 404){
              this.toastr.error(
                this.translate.instant('texto_804'),
                this.translate.instant('texto_797')
              ); 
            } else {
              this.toastr.error(
                this.translate.instant('texto_773')
              );
            }
            
          }
        );
    }
  }


  /**
   * Toggles the visibility of the new password field.
   */
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  /**
   * Toggles the visibility of the repeated password field.
   */
  toggleRepeatedPasswordVisibility() {
    this.repeatedPasswordVisible = !this.repeatedPasswordVisible;
  }

  /**
   * Navigates to the main content of the page.
   */
  navigateToButton(): void {
    document.getElementById('mainContent')?.focus();
  }
}
