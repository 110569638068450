import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.css']
})
export class RecuperarPasswordComponent implements OnInit {
  validatingForm: FormGroup;  // Form group for password validation
  passwordVisible = false;    
  repeatedPasswordVisible = false; 
  emailUser: string = '';     
  language: string; 
  
  hasUpperCase: boolean = false;
  hasLowerCase: boolean = false;
  hasNumber: boolean = false;
  hasSpecialChar: boolean = false;
  hasMinLength: boolean = false;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private apiService: ApiService
  ) {
    // Initialize the form group with controls and their validators.
    this.validatingForm = this.fb.group({
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_]).+$')
      ]],
      repeatedPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_]).+$')
      ]]
    });
  }

  /**
   * Set the default language for translations and fetches the user's email.
   */
  ngOnInit(): void {
    // Set the default language if not already set
    if (this.language == "" || this.language == null) {
      this.language = 'es';
    }
    this.translate.setDefaultLang(this.language);

    this.validatingForm.get('newPassword')?.valueChanges.subscribe((password: string) => {
      this.checkPasswordStrength(password);
    });

    this.toastr.info(
      this.translate.instant('texto_796'),
      this.translate.instant('texto_789'), {
      timeOut: 0,
      closeButton: true,
    });

    // Fetch the email of the logged-in user
    this.apiService.meEmail().subscribe(
      (res) => {
        this.emailUser = res.mail_user;
      },
      (error) => {
        console.error("Error fetching the user's email:", error);
      }
    );
  }

    /**
   * Function to validate strength password
   */
    checkPasswordStrength(password: string): void {
      const uppercasePattern = /[A-Z]/;
      const lowercasePattern = /[a-z]/;
      const numberPattern = /\d/;
      const specialCharPattern = /[\W_]/;
  
      this.hasUpperCase = uppercasePattern.test(password);
      this.hasLowerCase = lowercasePattern.test(password);
      this.hasNumber = numberPattern.test(password);
      this.hasSpecialChar = specialCharPattern.test(password);
      this.hasMinLength = password.length >= 8;
    }

  /**
   * Saves the new password by sending it to the backend for the current user.
   * Displays a success or error message based on the response.
   */
  saveNewPassword(): void {
    if (this.validatingForm.valid) {
      const newPassword = this.validatingForm.value.newPassword;
      this.loginService.saveNewPassword(this.emailUser, newPassword).subscribe(res => {
        if (res == 200) {
          this.toastr.success(
            this.translate.instant('texto_772')
          );
          // Refresh the component and navigate to login page
          this.router
            .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/login']);
            });
        } else {
          this.toastr.error(
            this.translate.instant('texto_773') 
          );
        };
      });
    }
  }

  /**
   * Toggles the visibility of the new password field.
   */
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  /**
   * Toggles the visibility of the repeated password field.
   */
  toggleRepeatedPasswordVisibility() {
    this.repeatedPasswordVisible = !this.repeatedPasswordVisible;
  }

  /**
   * Navigates to the main content of the page.
   */
  navigateToButton(): void {
    document.getElementById('mainContent')?.focus();
  }
}
