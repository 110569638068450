import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TipoComponent } from './components/fase-1/tipo/tipo.component';
import { DenuncianteComponent } from './components/fase-2/denunciante/denunciante.component';
import { DatosDenunciaComponent } from './components/fase-3/datos-denuncia/datos-denuncia.component';
import { TerminadaDenunciaComponent } from './components/fase-7/terminada-denuncia/terminada-denuncia.component';
import { PersonalComponent } from './components/fase-4/personal/personal.component';
import { ReceptorComponent } from './components/fase-1/receptor/receptor.component';
import { FinalizarDenunciaComponent } from './components/fase-6/finalizar-denuncia/finalizar-denuncia.component';
import { ArchivosComponent } from './components/fase-5/archivos/archivos.component';
import { AsignarContraseniaComponent } from './components/fase-7/asignar-contrasenia/asignar-contrasenia.component';

const routes: Routes = [
  {
    path: 'datos-empresa', component: TipoComponent
  },
  {
    path: 'denunciante',
    component: DenuncianteComponent,
  },
  {
    path: 'datos-denuncia',
    component: DatosDenunciaComponent,
  },
  {
    path: 'finalizada/:id',
    component: TerminadaDenunciaComponent,
  },
  {
    path: 'receptor',
    component: ReceptorComponent,
  },
  {
    path: 'testigo',
    component: PersonalComponent,
  },
  {
    path: 'finalizar-denuncia',
    component: FinalizarDenunciaComponent,
  },
  
  { path: 'archivos',
  component: ArchivosComponent 
  },
  {
    path: 'crear-contrasenia',
    component: AsignarContraseniaComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EnviarDenunciaRoutingModule {}
