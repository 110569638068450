<footer class="bg-secondary da-section" [ngClass]="{'fixed-footer': isFixed}">
  <div class="container text-white">
    <div class="row">
      <div class="col-md-4 col-lg-4">
        <p class="p-footer">&copy; 2024 Becompliance. {{ 'texto_42' | translate }}</p>
      </div>
      <div class="col-md-4 col-lg-4">
        <p class="p-footer"><a routerLink="/declaracion-de-accesibilidad" style="color: white">{{ 'texto_744' | translate }}</a></p>
      </div>
      <div class="col-md-4 col-lg-4">
        <p class="p-footer">CanalDenuncia.app v {{version}}</p>
      </div>
    </div>
  </div>
</footer>

