<div class="card" *ngIf="gAnalyticsEnabled && esSADM">
    <div class="card-header card-header-primary">
        <h4 class="card-title">{{ 'texto_782' | translate }}</h4>
    </div>
    <div class="content">
        <form [formGroup]="validatingForm" (ngSubmit)="submit()" style="margin-left: 25px; margin-right: 25px;">

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="bmd-label-floating">{{ 'texto_783' | translate }}</label>
                        <input formControlName="idClienteAnalytics" [(ngModel)]="idClienteAnalyticsF" type="text" class="form-control">

                        <!-- Mensaje de error -->
                        <mdb-error id="idClienteAnalyticsError" role="alert" *ngIf="validatingForm.get('idClienteAnalytics')?.invalid && 
                        (validatingForm.get('idClienteAnalytics')?.dirty || validatingForm.get('idClienteAnalytics')?.touched)">
                            {{ 'texto_61' | translate }}
                        </mdb-error>

                        <!-- Mensaje de éxito -->
                        <mdb-success style="font-size: 0.7rem !important; margin-left: 0% !important;"
                            *ngIf="validatingForm.get('idClienteAnalytics')?.valid">
                            {{ 'texto_62' | translate }}
                        </mdb-success>
                    </div>
                </div>
            </div>

            <!-- Botón de envío -->
            <button [disabled]="validatingForm.invalid" type="submit" class="btn btn-primary pull-right">
                {{ 'texto_384' | translate }}
            </button>
            <div class="clearfix"></div>
        </form>
    </div>
</div>