<section style="position: absolute;width: 100%;z-index: 1;" class="image-container line-spacing"
    style="background-image: url(/assets/img/inicio/inicio-1.jpg);">
    <div class="container" style="padding: 0;">
        <div class="board">
            <button type="button" style="z-index:100;float: right;" class="btn btn-dark" (click)="hide();" [attr.aria-label]="'texto_735' | translate">X</button>

            <!--Breadcrumbs-->
            <div class="breadcrum-div">
                <nav aria-label="breadcrumb" class="nav">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' |
                                translate }}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ 'texto_717'
                            | translate }}</li>
                    </ol>
                </nav>
            </div>
            <!--End Breadcrumbs-->
            <!-- Navigation Area (Circular Tabs) -->
            <msw-navbar></msw-navbar>
            <!-- End Navigation Area (Circular Tabs) -->

            <!-- Content Area -->
            <div class="tab-content">
                <form #denuncianteForm="ngForm" class="editForm" novalidate>
                    <div class="tab-pane fade in active">
                        <h2 class="head text-center">{{title | translate }}</h2>
                        <div class='row'>
                            <div class="grid-container">
                                <div class="relacion">
                                    <div class="form-group">
                                        <label class="control-label" for="denuncianteRelacion" id="label-denuncianteRelacion" (click)="focusSelect()">{{ 'texto_342' | translate }}*
                                        </label>
                                        <mat-form-field>
                                            <mat-select #denuncianteSelect id="denuncianteRelacion" name="denuncianteRelacion"
                                                [(ngModel)]="relacion.denuncianteRelacion" required aria-labelledby="label-denuncianteRelacion">
                                                <mat-option *ngFor="let tipo of tipoDenunciantes"
                                                    [value]="tipo.id_tp_relacion">
                                                    {{tipo.nombre}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="datos-denunciante" [hidden]="IsAnonimo">
                                    <div class="row">
                                        <div class='col-xs-12 col-sm-6'>
                                            <div class="form-group">
                                                <label class="control-label" for="denuncianteFirstName">{{ 'texto_35' |
                                                    translate }}</label>
                                                <input class="form-control input-md" #denuncianteFirstName="ngModel"
                                                    id="denuncianteFirstName" name="denuncianteFirstName" type="text"
                                                    placeholder="{{ 'texto_35' | translate }}" autocomplete="given-name"
                                                    [(ngModel)]="relacion.denuncianteFirstName">
                                            </div>
                                        </div>
                                        <div class='col-xs-12 col-sm-6'>
                                            <div class="form-group">
                                                <label class="control-label" for="denuncianteLastName">{{ 'texto_36' |
                                                    translate }}</label>
                                                <input class="form-control input-md" #denuncianteLastName="ngModel"
                                                    id="denuncianteLastName" name="denuncianteLastName" type="text"
                                                    placeholder="{{ 'texto_36' | translate }}" autocomplete="family-name"
                                                    [(ngModel)]="relacion.denuncianteLastName">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class='col-xs-12 col-sm-6'>
                                            <div class="form-group">
                                                <label class="control-label" for="denuncianteEmail">{{ 'texto_82' | translate }}</label>
                                                <input class="form-control input-md" #denuncianteEmail="ngModel"
                                                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" id="denuncianteEmail"
                                                    name="denuncianteEmail" type="text" placeholder="{{ 'texto_82' | translate }}" autocomplete="email" [(ngModel)]="relacion.denuncianteEmail"
                                                    [attr.aria-invalid]="denuncianteEmail.invalid && (denuncianteEmail.dirty || denuncianteEmail.touched)">
                                                <div *ngIf="denuncianteEmail.invalid && (denuncianteEmail.dirty || denuncianteEmail.touched)"
                                                    class="alert alert-danger" >
                                                    <div style="font-size:12px">
                                                        {{ 'texto_106' | translate }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='col-xs-12 col-sm-6'>
                                            <div class="form-group">
                                                <label class="control-label" for="denunciantePhone">{{ 'texto_66' | translate }}</label>
                                                <input class="form-control input-md" #denunciantePhone="ngModel" id="denunciantePhone" name="denunciantePhone"
                                                    type="text" placeholder="{{ 'texto_66' | translate }}" autocomplete="tel" [(ngModel)]="relacion.denunciantePhone"
                                                    pattern="[0-9+]{1,25}" maxlength="25" [attr.aria-invalid]="denunciantePhone.invalid && (denunciantePhone.dirty || denunciantePhone.touched)">
                                                <div *ngIf="denunciantePhone.invalid && (denunciantePhone.dirty || denunciantePhone.touched)"
                                                    class="alert alert-danger" >
                                                    <div style="font-size:12px">
                                                        {{ 'texto_105' | translate }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                      <div class='col-xs-12 col-sm-6'>
                                          <label class="control-label" for="denuncianteDNI">{{ 'texto_83' | translate }}</label>
                                          <input class="form-control input-md" #denuncianteDNI="ngModel" maxlength="30"  id="denuncianteDNI" name="denuncianteDNI" type="text" placeholder="{{ 'texto_83' | translate }}" [(ngModel)]="relacion.denuncianteDNI">
                                          <div *ngIf="denuncianteDNI.invalid && (denuncianteDNI.dirty || denuncianteDNI.touched)" class="alert alert-danger">
                                              <div style="font-size:12px">
                                                  {{ 'texto_446' | translate }}
                                              </div>
                                          </div>
                                      </div>
                                  </div>   -->
                                </div>
                                <div class="comentario" [hidden]="IsHidden">
                                    <div
                                        style="font-size: 0.87rem; line-height: 1.5;border: 0px solid black; padding: 10px;margin-left: 56px;text-align: justify;">
                                        <p>{{ 'texto_339' | translate }}</p>
                                        <p>{{ 'texto_340' | translate }}</p>
                                    </div>
                                </div>
                                <div class="botones">
                                    <div class="form-group text-center" style="margin-top: 30px;">
                                        <div
                                            style="font-size: 0.75rem;line-height: 1.5;border: 0px solid black;padding-top: 10px; padding-top: 10px; text-align: justify;">
                                            <p>{{ 'texto_732' | translate }}</p>
                                        </div>
                                        <button (click)="goToPrevious(denuncianteForm)"  mdbBtn
                                            rounded="true" class="btn-back" mdbWavesEffect><span
                                                style="margin-right:10px;" class="glyphicon glyphicon-arrow-left">
                                            </span>{{ 'texto_142' | translate }}
                                        </button>

                                        <button [disabled]="!denuncianteForm.valid && !IsAnonimo"
                                            (click)="goToNext(denuncianteForm)"  mdbBtn rounded="true"
                                            class="btn-next" mdbWavesEffect>{{ 'texto_141' | translate }} <span
                                                style="margin-left:10px;" class="glyphicon glyphicon-arrow-right">
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
