import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { PlazosDenuncia } from '../model/plazosDenuncia';
import { Historico } from '../model/historico';
import { environment } from "../../environments/environment";
import { DatePipe } from '@angular/common';
import { Rol } from '../model/rol';

@Component({
  selector: 'app-enviar-denuncia-decisor',
  templateUrl: './enviar-denuncia-decisor.component.html',
  styleUrls: ['./enviar-denuncia-decisor.component.css'],
  providers: [DatePipe]
})
export class EnviarDenunciaDecisorComponent implements OnInit {
  historico: Historico[];
  validatingForm: FormGroup;
  IsHiddenForm: boolean = false;
  plazos: PlazosDenuncia[];
  enviarDenuncia1: boolean = false;
  roles: Rol[];
  @Input() denuncia: DatosDenuncia;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService, private datepipe: DatePipe) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

  }

  toggleEnvioDenuncia() {
    this.enviarDenuncia1 = !this.enviarDenuncia1;
  }

  validar() {
    if (this.enviarDenuncia1) {
      this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "4", this.auth.getSociedad(), "", "").subscribe(
        res => {
          if (res == 200) {
            this.toastr.success(this.translate.instant('texto_296'), this.translate.instant('texto_274'));
            let $clau = false;
            let clausulas_correo = localStorage.getItem("clausulas_correo");
            if (clausulas_correo == '1') {
              $clau = true;
            }

            this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '4').subscribe((investigator: PlazosDenuncia[]) => {
              this.plazos = investigator;
              for (let i = 0; i < this.plazos.length; i++) {
                let fecha = new Date(); // ó new Date(valor);
                let plazo = this.plazos[0].plazo_decisor;
                fecha.setDate(fecha.getDate() + parseInt(plazo));
                let _date = this.datepipe.transform(fecha, 'dd-MM-yyyy');
                let url = environment.base + "/editar-denuncia/" + this.denuncia[0].id_denuncia;
                let message = this.translate.instant('texto_576') + " " + this.plazos[i].mail_user + this.translate.instant('texto_592') + " " + this.denuncia[0].id_denuncia + " " + this.translate.instant('texto_593') + " " + _date;
                this.apiService.sendEmail(this.plazos[i].mail_user, this.translate.instant('texto_564') + this.denuncia[0].id_denuncia, message, url, $clau, this.translate.instant('texto_595')).subscribe(
                  res => {
                    if (res == 200) {
                      this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                      //let role = this.auth.getRole();
                      let role = "";
                      this.apiService.searchRoleByUserSociety('setUser', this.auth.getSociedad(), '1').subscribe((roles: Rol[]) => {
                        this.roles = roles;
                        for (let i = 0; i < this.roles.length; i++) {
                          role = role + "," + this.roles[i].n_rol;
                        }
                        if (role.includes("DECISOR") && this.plazos[0].id_user == this.auth.getIdUser()) {
                          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                            this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
                          });
                        } else {
                          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                            this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
                          });
                        }
                      });
                    } else {
                      this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                    }
                  });
              }
            });

            if (this.denuncia[0].denunciante_correo != "") {
              this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '1').subscribe((investigator: PlazosDenuncia[]) => {
                this.plazos = investigator;
                let fecha = new Date(); // ó new Date(valor);
                let plazo = this.plazos[0].plazo_decisor;
                fecha.setDate(fecha.getDate() + parseInt(plazo));
                let _date = this.datepipe.transform(fecha, 'dd-MM-yyyy');
                let url = environment.base + "/ver-denuncia/consultar-denuncia";
                // let message = this.translate.instant('texto_576') + " " + this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_596') + " " + this.denuncia[0].id_denuncia + " " + this.translate.instant('texto_597') + " " + _date;
                let message = this.translate.instant('texto_576') + " " + this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_596') + " " + this.denuncia[0].id_denuncia + " " + this.translate.instant('texto_597');
                this.apiService.sendEmail(this.denuncia[0].denunciante_correo, this.translate.instant('texto_598'), message, url, $clau, this.translate.instant('texto_598')).subscribe(
                  res => {
                    if (res == 200) {
                      //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                    } else {
                      this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                    }
                  });
              });
            }
          }
        });
    }
  }

}
