import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmltoplaintext'
})
export class HtmltoplaintextPipe implements PipeTransform {
  transform(value: string): string {
      return value ? value.replace(/]+>/gm, '') : '';
    }

}
