import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
  constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) { }


  login(username: string, password: string): Observable<void> {
    return this.httpClient.post<void>(`${this.PHP_API_SERVER}/api/authenticateWithToken.php`, {
      email: username,
      password: password,
    });
  }

  loginSingleSignOn() {
    //return this.httpClient.post(`${this.PHP_API_SERVER}/api/saml_login.php`, {
    //return this.router.navigate([`${this.PHP_API_SERVER}/api/saml_login.php`],{
    //});
    window.location.href = `${this.PHP_API_SERVER}/api/saml_login.php`;
  }

  /**
   * Sends a request to the server to initiate the password recovery process by generating a token.
   * @param email - The email address of the user who wants to recover their password.
   */
  sendEmailToRecoverPassword(email: string, ruta: string, link: string): Observable<void> {
    return this.httpClient.post<void>(`${this.PHP_API_SERVER}/api/users/resetPasswordWithToken.php`, {
      email: email,
      ruta: ruta,
      link: link
    });
  }

  /**
   * Verifies if the provided email address is registered and returns specific user details.
   * @param email - The email address to verify.
   * @returns An Observable containing the mail_user and is_lock fields from the server's response.
   */
  verifyEmail(email: string): Observable<{ message: string, user: { mail_user: string, is_lock: string } }> {
  return this.httpClient.post<{ message: string, user: { mail_user: string, is_lock: string} }>(
    `${this.PHP_API_SERVER}/api/users/searchUserByEmail.php`, 
    { email: email }
  );
}

  /**
 * Service method to save the new password for a user who is resetting their password.
 * @param emailUser - The email of the user whose password is being reset.
 * @param newPassword - The new password that the user wants to set.
 */
  saveNewPassword(emailUser: string, newPassword: string) {
    // Set the headers with the authorization token
    const headers = { 'Authorization': 'Bearer ' + this.auth.getAccessTokenPassword() }
    let options = { headers: headers };

    // Send a POST request to the backend to update the password
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/users/updatePasswordFromResetPassword.php`, {
      username: emailUser,
      password: newPassword
    }, options);
  }


}
