import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from 'src/app/shared/services/auth.service';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { ConsultarDenunciaService } from 'src/app/ver-denuncias/services/consultar-denuncia.service';
import { Title } from '@angular/platform-browser';
import { LoginService } from 'src/app/auth/services/login.service';


@Component({
  selector: 'app-consultar-denuncia',
  templateUrl: './consultar-denuncia.component.html',
  styleUrls: ['./consultar-denuncia.component.css']
})
export class ConsultarDenunciaComponent implements OnInit {
  title = 'Consultar denuncias';
  validatingForm: FormGroup;
  denuncia: DatosDenuncia[];
  language: string;
  showConsultComplaint: boolean = false;
  showRecoveryFields: boolean = false;
  loginNoUser: boolean = false;
  recoveryEmailFormControl: FormControl; 

  constructor(
    private titleService: Title,
    private consultarDenunciaService: ConsultarDenunciaService,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private consultarDenuncia: ConsultarDenunciaService,
    private loginService: LoginService) {

    // Set title based on selected language
    this.translate.get('texto_147', { value: this.language }).subscribe((text: string) => {
      this.titleService.setTitle(text);
    });
  }

  ngOnInit() {
    this.language = this.auth.getLanguage();
    if (this.language == "" || this.language == null) {
      this.language = 'es';
    }
    this.translate.setDefaultLang(this.language);
    this.showConsultComplaint = true;

    this.validatingForm = new FormGroup({
      formPin: new FormControl('', Validators.required),
      formId: new FormControl('', Validators.required)
    });

    this.recoveryEmailFormControl = new FormControl('', [
      Validators.email,
      Validators.required
    ]);
  }



  /**
 * Method for navigating to "boton1" after navigating to a section "services".
 */
  navigateToButton() {
    // Navigate to the 'services' section first
    // this.navigationService.navigateToSection('services');
    const element = document.getElementById('mainContent');

    setTimeout(() => {
      // After a delay, invoke the navigateToButton method of the NavigationService to focus the button.
      if (element) {
        element.focus();
      }
    }, 500); // Wait for a specified time to ensure the navigation is complete before focusing the button.
  }

  get formPin() {
    return this.validatingForm.get('formPin');
  }

  get formId() {
    return this.validatingForm.get('formId');
  }

  enviar() {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    if (this.formPin.value == "" || this.formId.value == "") {
      this.toastr.error(this.translate.instant('texto_288'), this.translate.instant('texto_243'));
    } else {
      this.consultarDenunciaService.searchComplaintByPinId(this.formPin.value, this.formId.value, language).subscribe((denuncia: DatosDenuncia[]) => {
        this.denuncia = denuncia;
        if (denuncia.length == 0) {
          this.toastr.error(this.translate.instant('texto_189'), this.translate.instant('texto_797'));
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/ver-denuncia/consultar-denuncia']);
          });
        } else {
          localStorage.setItem("verdenuncia", "");
          this.router.navigate(['ver-denuncia/', this.formId.value]);
          // Cambiar el estado de isLoggedInComplaint a true una vez que se hayan validado los datos del formulario
          this.consultarDenunciaService.isLoggedInComplaint = true;
        }
      },
      (error: any) => {
        if (error.status === 410) {
          this.toastr.error(
            this.translate.instant('texto_800')
          );
        } else if(error.status === 404){
          this.toastr.error(
            this.translate.instant('texto_189'),
            this.translate.instant('texto_797')
          ); 
        } else {
          this.toastr.error(
            this.translate.instant('texto_773')
          );
        }
        
      });
    }
  }

  inicio() {
    this.auth.logoutSimple();
    /*window.location.reload();*/
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/inicio']);
    });
  }

  
  /**
   * Toggles the visibility of the recover password screen.
   */
  showRecovery() {
    this.showRecoveryFields = true;
    this.showConsultComplaint = false;
    this.loginNoUser = false;
    
  }

  /**
 * Verifies if the email is registered, and if it is, sends an email to recover the password.
 */
  verifyEmailPasswordComplaint(): void {
    const email = this.recoveryEmailFormControl.value;
  
    // Check if the email form control is valid
    if (this.recoveryEmailFormControl.valid) {
      // Verify if the email is registered
      this.consultarDenunciaService.verifyEmailPasswordComplaint(email).subscribe({
        next: (res) => {
          // If the user is registered
          if (res.message === "Email is registered") {
            this.loginService.sendEmailToRecoverPassword(email, "restablecer-contrasenia-denuncia", "tokenPasswordComplaint").subscribe({
              next: () => {
                this.toastr.info(
                  this.translate.instant('texto_715'),
                  this.translate.instant('texto_194'),
                  {
                    timeOut: 0,
                    closeButton: true,
                  }
                );
                this.loginNoUser = false;
              }
            });
          }
        },
        error: (errorResponse) => {
          // If the email is not registered (404) or there is a server error
          if (errorResponse.status === 404 && errorResponse.error.message === "Email not found") {
            this.loginNoUser = true;
          } else {
            //server error
            this.toastr.error(
              this.translate.instant('Server Error')
            );
          }
        }
      });
    }
  }

  backConsultComplaint(){
    this.showConsultComplaint = true;
    this.showRecoveryFields = false;
  }
}
