import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Archivos } from 'src/app/shared/models/formData.model';
import { Documento } from 'src/app/enviar-denuncia/models/documento';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormDataService } from 'src/app/enviar-denuncia/services/formData.service';

@Component({
  selector: 'app-archivos',
  templateUrl: './archivos.component.html',
  styleUrls: ['./archivos.component.css'],
  animations: []
})
export class ArchivosComponent implements OnInit {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  title = 'texto_358';
  lastModified: string = "0";
  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', []),
    descripcion: new FormControl('', [Validators.maxLength(4000)]),
  });
  //List to manage the files before adding them to ArchivoList.
  files: any = [];
  //list to add files of type Archivos.
  archivosList: Archivos[] = [];
  documento: Documento[];
  sizeList: number = 0;
  sizeListMb: number = 0;

  //List of allowed extensions.
  allowedExtensions = ['.eml', '.msg', '.pptx', '.pptm', '.potx', '.potm', '.ppam', '.ppsx', '.ppsm', '.xlsx', '.xlsm', '.xltx', '.xltm', '.xlam', '.gif', '.jpeg', '.jpg', '.png', '.pdf', '.txt', '.doc', '.docx'];



  constructor(
    private translate: TranslateService,
    private router: Router,
    private formDataService: FormDataService,
    private auth: AuthService,
    private toastr: ToastrService) {
  }

  ngOnInit() {
    //get the list of files by pressing goToPrevious or goToNext.
    this.archivosList = this.formDataService.getArchivos();
    let totalSizeBytes = 0;
    //add the files size.
    this.archivosList.forEach(archivo => {
      totalSizeBytes += archivo.nombreArchivo.size;
    });
    this.sizeListMb = totalSizeBytes / 1000000;

  }

  /**
   * Function called to handle file upload, add the file to the list "files".
   * It processes the selected files, checks their extensions, and performs validations.
   * 
   * @param event The change event triggered when files are selected.
   */
  uploadFile(event) {
    // Loop through each selected file.
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];

      // Check if the file extension is allowed.
      const fileExtension = element.name.split('.').pop().toLowerCase();
      //console.log(fileExtension);

      if (!this.allowedExtensions.includes('.' + fileExtension)) {
        // Alert if the file does not have an allowed extension.
        this.toastr.error(this.translate.instant('texto_181'));
        continue;
      }

      // If the file size exceeds the limit, show an alert.
      if (element.size / 1000000 > 20) {
        this.toastr.error(this.translate.instant('texto_333'));
        continue;
      } 

      this.lastModified = element.lastModified;
      this.onFileChange(event);
      // Add the file to the list of files.
      this.files.push(element.name);
    }
  }

  /**
   * Function to add files to archivosList and set them to setArchivos.
   * It clears the list of selected files after processing.
   * 
   * It first assigns data from the form to a new 'Archivos' object.
   * Then, it verifies if a file exists based on its size.
   * If a file exists and its size is within the allowed limit, it adds the file to archivosList.
   * It also updates the total size of archivosList.
   * Finally, it resets the form to allow adding a new file and its description, and saves archivosList.
   */
  submit() {
    this.files = [];

    // Assign data from the form to Archivos.
    const archivo: Archivos = {
      nombreArchivo: this.myForm.get('fileSource').value,
      descripcionArchivo: this.myForm.get('descripcion').value
    };

    // Verify that a file exists by its size.
    if (archivo.nombreArchivo.size > 0) {
      const newFileSizeMb = (this.sizeList + archivo.nombreArchivo.size) / 1000000; // Calculate new total size in MB

      if (newFileSizeMb < 20) {
        // Add archivo to archivosList.
        this.archivosList.push(archivo);
        // Update total size.
        this.sizeList += archivo.nombreArchivo.size;
        this.sizeListMb = this.sizeList / 1000000;

        // Clean up the form to allow adding a new file and its description.
        this.myForm.reset();
        // Save archivosList.
        this.formDataService.setArchivos(this.archivosList);
      } else {
        // Alert when exceeding the maximum size.
        this.toastr.error(this.translate.instant('texto_333'));
      }
    }
  }


  /**
  * Function to delete a file and its description from archivosList.
  * It subtracts the size of the deleted file from the total file size.
  * Finally, it updates archivosList and saves the updated list.
  * 
  * @param index The index of the item to be deleted from archivosList.
  */
  deleteItemArchivosList(index) {
    // Subtract the size of the deleted file from the total file size.
    this.sizeList -= this.archivosList[index].nombreArchivo.size;
    this.sizeListMb = this.sizeList / 1000000; // Recalculate the total size in MB.

    // Delete the file from archivosList.
    this.archivosList.splice(index, 1);
    this.formDataService.setArchivos(this.archivosList);
  }

  /**
   * Function to delete a file from the Files list.
   * It removes the file at the specified index from the Files list.
   * If the file is the last one selected, it checks if it needs to be deleted from the server.
   * 
   * @param index The index of the file to be deleted from the Files list.
   */
  deleteItemFiles(index) {
    this.files.splice(index, 1);

    // Create a FormData object to handle file deletion from the server
    const formData = new FormData();

    // Get the file to be deleted from the form data
    let file = this.myForm.get('fileSource').value;

    // If no file is selected, append the name of the file to be deleted from the server
    if (this.myForm.get('fileSource').value == "") {
      formData.append('delete_file', this.documento[0].n_archivo);
    }
  }

  /**
   * Function to save the form.
   * It checks if the form is valid and returns a boolean indicating the result.
   * 
   * @param form The form to be saved.
   * @returns A boolean indicating whether the form is valid or not.
   */
  save(form: any): boolean {
    if (!form.valid) {
      return false; // Return false if the form is invalid
    }
    return true; // Return true if the form is valid
  }

  /**
   * Function similar to uploadFile, is called when a file is upload by drag and drop
   * It processes the selected files, checks their extensions, and performs validations.
   * 
   * @param files The change event triggered when files are selected.
   */
  dragFile(files: FileList) {
    // Loop through each selected file.
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
  
      // Check if the file extension is allowed.
      const fileExtension = element.name.split('.').pop().toLowerCase();
  
      if (!this.allowedExtensions.includes('.' + fileExtension)) {
        // Alert if the file does not have an allowed extension.
        this.toastr.error(this.translate.instant('texto_181'));
        continue;
      }
  
      // Check if more than one file is selected.
      if (this.files.length > 0) {
        // Show an error message.
        this.toastr.error(this.translate.instant('texto_334'), this.translate.instant('texto_203'));
        break;
      }
  
      // Check if the file size exceeds the limit.
      if (element.size / 1000000 > 20) {
        // Show an alert for exceeding file size limit.
        this.toastr.error(this.translate.instant('texto_333'));
        continue;
      }
  
      // Call the function to handle file change.
      this.onFileChangeDrag(files);
  
      // Add the file to the list of files.
      this.files.push(element.name);
    }
  }


  /**
  * Function called when the user change the file.
  * It updates the value of a field in the reactive form with the information of the selected file.
  * 
  * @param event The change event triggered when the user selects a file.
  */
  onFileChange(event) {
    // Check if files are selected.
    if (event.target.files.length > 0) {
      // Get the first file from the list of selected files.
      const file = event.target.files[0];
      // Update the value of the field in the reactive form with the information of the selected file.
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  /**
  * Function similar to onFileChange, is called when a file is changed by drag and drop.
  * It updates the value of a field in the reactive form with the information of the selected file.
  * 
  * @param files The change event triggered when the user selects a file.
  */
  onFileChangeDrag(files: FileList) {
    // Check if files are selected.
    if (files.length > 0) {
      // Get the first file from the list of selected files.
      const file = files[0];

      // Update the value of the field in the reactive form with the information of the selected file.
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  get descripcion() {
    return this.myForm.get('descripcion');
  }

  hide() {
    this.auth.logoutSimple();
    this.router
      .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/inicio']);
      });
  }

  goToPrevious(form: any) {
    if (this.save(form)) {
      this.router.navigate(['/enviar-denuncia/testigo']);
    }
  }

  goToNext(form: any) {

    if (this.save(form)) {
      this.router.navigate(['/enviar-denuncia/finalizar-denuncia']);

    }
  }

}
