import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { environment } from "../../environments/environment";
import { Archivos } from '../data/formData.model';
import { Rol } from '../model/rol';

@Component({
  selector: 'app-anadir-archivo-receptor',
  templateUrl: './anadir-archivo-receptor.component.html',
  styleUrls: ['./anadir-archivo-receptor.component.css']
})
export class AnadirArchivoReceptorComponent implements OnInit {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";

  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  myForm = new FormGroup({
    file: new FormControl('', []),
    fileSource: new FormControl('', []),
    contactFormModalMessage: new FormControl('', []),
  });
  archivos: Archivos;
  files: any = [];
  @Input() denuncia: DatosDenuncia;
  contador = 0

  idrole: string;
  roles: Rol[];

  // List of allowed extensions based on the accept attribute.
  allowedExtensions = ['.gif', '.jpeg', '.jpg', '.png', '.mp4', '.mp3', '.pdf', '.zip', '.eml', '.msg'];


  constructor(
    private apiService: ApiService,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private http: HttpClient) { }

  ngOnInit() {

  }

  /*onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
     // this.submit();
    }
  }

  get contactFormModalMessage() {
    return this.myForm.get('contactFormModalMessage');
  }

  submit(){
    const formData = new FormData();
    formData.append('file', this.myForm.get('fileSource').value);
    formData.append('id_sociedad', this.denuncia[0].id_sociedad);
    formData.append('id_denuncia', this.denuncia[0].id_denuncia);
    formData.append('comentario', this.contactFormModalMessage.value);
    this.http.post(`${this.PHP_API_SERVER}/api/uploadDocument.php`, formData).subscribe(
      res => {
        if(res == 200){
          this.toastr.success('Archivos cargados correctamente', 'Cargando ...');
        }else{
          this.toastr.error('Error al cargar los archivos', 'Cargando ...');
        }

        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
        });
      })
  }*/
  onKey(event) {
    var datos = document.getElementById("addDocument");
    for (var i = 0; i < 1; i++) {
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
  }

  get contactFormModalMessage() {
    return this.myForm.get('contactFormModalMessage');
  }

  submit() {
    if (this.contador > 4000) {
      this.toastr.error(this.translate.instant('Superado el máximo número de caracteres'), this.translate.instant('Parámetros incorrectos'));
    } else {

      let comentario = this.contactFormModalMessage.value.replace(/<[^>]*>/g, '');
      comentario = comentario.replace(/'/g, '');

      //let role = this.auth.getRole();
      let role = "";
      this.apiService.searchRoleByUserSociety('setUser', this.auth.getSociedad(), '1').subscribe((roles: Rol[]) => {
        this.roles = roles;
        for (let i = 0; i < this.roles.length; i++) {
          role = role + "," + this.roles[i].n_rol;
        }
        if (role != "") {
          this.idrole = '0';
          if (role.includes("EXTERNO")) {
            this.idrole = '11';
          }
        }
        // this.idrole = '0';
        // if (role.includes("EXTERNO") ){
        //   this.idrole = '11';
        // }

        const formData = new FormData();
        formData.append('file', this.myForm.get('fileSource').value);
        formData.append('id_sociedad', this.denuncia[0].id_sociedad);
        formData.append('id_denuncia', this.denuncia[0].id_denuncia);
        formData.append('comentario', comentario);
        formData.append('id_usuario', "setUser");
        formData.append('idrole', this.idrole);

        this.http.post(`${this.PHP_API_SERVER}/api/uploadDocument.php`, formData).subscribe(
          res => {
            if (res == 200) {
              this.toastr.success('Archivos cargados correctamente', 'Cargando ...');
            } else {
              this.toastr.error('Error al cargar los archivos', 'Cargando ...');
            }

            this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
            });

          });

      });
    }
  }


  /**
   * Function called to handle file upload.
   * It processes the selected files, checks their extensions, and performs validations.
   * 
   * @param event The change event triggered when files are selected.
   **/
  uploadFile(event) {
    // Loop through each selected file.
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];

      // Check if the file extension is allowed.
      const fileExtension = element.name.split('.').pop().toLowerCase();

      if (!this.allowedExtensions.includes('.' + fileExtension)) {
        // Alert if the file does not have an allowed extension.
        this.toastr.error(this.translate.instant('texto_181'));
        continue;
      }

      // Check if more than one file is selected.
      if (this.files.length > 0) {
        // Show an error message.
        this.toastr.error(this.translate.instant('texto_334'), this.translate.instant('texto_203'));
        break;
      }

      // Check if the file size exceeds the limit.
      if (element.size / 1000000 > 20) {
        // Show an alert for exceeding file size limit.
        this.toastr.error(this.translate.instant('texto_333'));
        continue;
      }

      // Call the function to handle file change.
      this.onFileChange(event);

      // Add the file to the list of files.
      this.files.push(element.name);
    }
  }

  /**
   * Function similar to uploadFile, is called when a file is upload by drag and drop
   * It processes the selected files, checks their extensions, and performs validations.
   * 
   * @param files The change event triggered when files are selected.
   */
  dragFile(files: FileList) {
    // Loop through each selected file.
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
  
      // Check if the file extension is allowed.
      const fileExtension = element.name.split('.').pop().toLowerCase();
  
      if (!this.allowedExtensions.includes('.' + fileExtension)) {
        // Alert if the file does not have an allowed extension.
        this.toastr.error(this.translate.instant('texto_181'));
        continue;
      }
  
      // Check if more than one file is selected.
      if (this.files.length > 0) {
        // Show an error message.
        this.toastr.error(this.translate.instant('texto_334'), this.translate.instant('texto_203'));
        break;
      }
  
      // Check if the file size exceeds the limit.
      if (element.size / 1000000 > 20) {
        // Show an alert for exceeding file size limit.
        this.toastr.error(this.translate.instant('texto_333'));
        continue;
      }
  
      // Call the function to handle file change.
      this.onFileChangeDrag(files);
  
      // Add the file to the list of files.
      this.files.push(element.name);
    }
  }

  /**
   * Function called when the user change the file.
   * It updates the value of a field in the reactive form with the information of the selected file.
   * 
   * @param event The change event triggered when the user selects a file.
   */
  onFileChange(event) {
    // Check if files are selected.
    if (event.target.files.length > 0) {
      // Get the first file from the list of selected files.
      const file = event.target.files[0];

      // Update the value of the field in the reactive form with the information of the selected file.
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  /**
  * Function similar to onFileChange, is called when a file is changed by drag and drop.
  * It updates the value of a field in the reactive form with the information of the selected file.
  * 
  * @param files The change event triggered when the user selects a file.
  */
  onFileChangeDrag(files: FileList) {
    // Check if files are selected.
    if (files.length > 0) {
      // Get the first file from the list of selected files.
      const file = files[0];

      // Update the value of the field in the reactive form with the information of the selected file.
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }


  deleteAttachment(index) {
    //this.archivos.nombreArchivo =  "";
    this.files.splice(index, 1)
    const formData = new FormData();
    let file = this.myForm.get('fileSource').value;

    formData.append('file', file);
    formData.append('id_sociedad', this.denuncia[0].id_sociedad);
    formData.append('id_denuncia', this.denuncia[0].id_denuncia);
    formData.append('comentario', this.contactFormModalMessage.value);
    formData.append('id_usuario', "setUser");
    // this.http.post(`${this.PHP_API_SERVER}/api/deleteDocument.php`, formData).subscribe(
    //   res => {
    //     if(res == 200){
    //       this.toastr.success('Archivos eliminado correctamente', 'Cargando ...');
    //     }else{
    //       this.toastr.error('Error al eliminar los archivos', 'Cargando ...');
    //     }
    // })
  }

}
