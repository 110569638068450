import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsultarDenunciaService {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
  constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) { }

  // Propiedad para validar que se haya ingresado el pin y el id de la denuncia a consultar
  isLoggedInComplaint: boolean = false;

  searchComplaintByPinId(pin_code: string, id_complaint: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciaByPinId.php`, {
      pin_code: pin_code,
      id_complaint: id_complaint,
      idioma: idioma
    }, options);
  }

  /**
  * Verifies if the provided email address is registered and returns specific user details.
  * @param email - The email address to verify.
  * @returns An Observable containing the mail_user and is_lock fields from the server's response.
  */
  verifyEmailPasswordComplaint(email: string): Observable<{ message: string, user: { mail_user: string } }> {
    return this.httpClient.post<{ message: string, user: { mail_user: string } }>(
      `${this.PHP_API_SERVER}/api/Denuncias/searchEmailPasswordCompaint.php`,
      { email: email }
    );
  }

  
  /**
  * Service method to search if the complaint isn´t terminated.
  */
  searchComplaintNoTerminated(id_denuncia:string) {
    // Set the headers with the authorization token
    const headers = { 'Authorization': 'Bearer ' + this.auth.getAccessTokenPasswordComplaint() }
    let options = { headers: headers };

    // Send a POST request to the backend to update the password
    return this.httpClient.post<{ message: string,  code: number }>(`${this.PHP_API_SERVER}/api/Denuncias/searchComplaintNoTerminated.php`, {
      id_denuncia: id_denuncia
    }, options);
  } 

  /**
  * Service method to save the new password complaint.
  * @param emailUser - The email of the user whose password is being reset.
  * @param newPassword - The new password that the user wants to set.
  */
  saveNewPasswordComplaint(id_denuncia:string, email: string, newPassword: string) {
    // Set the headers with the authorization token
    const headers = { 'Authorization': 'Bearer ' + this.auth.getAccessTokenPasswordComplaint() }
    let options = { headers: headers };

    // Send a POST request to the backend to update the password
    return this.httpClient.post<{ message: string,  code: number }>(`${this.PHP_API_SERVER}/api/Denuncias/updatePasswordComplaint.php`, {
      id_denuncia: id_denuncia,
      email: email,
      password: newPassword
    }, options);
  }
}
