
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Empresa } from 'src/app/enviar-denuncia/models/empresa';
import { Centro } from 'src/app/enviar-denuncia/models/centro';
import { Denuncia } from 'src/app/enviar-denuncia/models/denuncia';
import { GrabarDenuncia, TipoHecho } from 'src/app/shared/models/formData.model';
import { AutocompleteWordService } from 'src/app/services/autocomplete-word.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EnviarDenunciaService } from 'src/app/enviar-denuncia/services/enviar-denuncia.service';
import { FormDataService } from 'src/app/enviar-denuncia/services/formData.service';
import { Receptor } from 'src/app/data/formData.model';
import { RolReceptor } from 'src/app/enviar-denuncia/models/rolReceptor';
import { DatosService } from 'src/app/enviar-denuncia/services/datos.service';
import { ReceptorInicial } from 'src/app/data/formData.model';
import { ReceptorService } from 'src/app/enviar-denuncia/services/receptor.service';
import { Title } from '@angular/platform-browser';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-tipo',
  templateUrl: './tipo.component.html',
  styleUrls: ['./tipo.component.css']
})

export class TipoComponent implements OnInit {
  /**
   * View Child for Accesibility Focus
   */
  @ViewChild('empresa') empresa: MatSelect;
  @ViewChild('centroSelect') centroSelect: MatSelect;
  @ViewChild('tipoDenuncia') tipoDenuncia: MatSelect;
  @ViewChild('receptorSelect') receptorSelect: MatSelect;

  @Input() formData;
  title = 'texto_338';
  tipoHecho: TipoHecho;
  form: any;
  empresas: Empresa[];
  centros: Centro[]; //Model Centro
  denuncias: Denuncia[];
  selectedCompany: string;
  selectedComplaint: string;
  selectedCenter: null | string;
  idCentro: null | string;
  descripcion: string = "";
  showSelected: number = 0; //Indicator to show the selected centers
  reactiveForm: FormGroup;
  // Autocomplete variables
  hideerrorentrycompanies: boolean = false;
  inputValueAutocomplete: string = '';
  resultErrorAutocomplete: any;
  colorfieldAutocomplete: string = 'primary'; // Valor predeterminado
  showInputAutocomplete: boolean = false;
  enablebuttonNext: boolean = false;
  searchInput = new FormControl();
  suggestions: any;
  language: string;

  //Receptor variables
  Receptoraleatorio: string = "";
  description: string = ""; //description complaint type
  dataComplaint: GrabarDenuncia;
  complaints: Denuncia[];
  receptores: RolReceptor[];
  receptorInicial: ReceptorInicial;
  receptor: Receptor;
  contador = 0;
  inicial: boolean = false;

  //Buttons
  Showbutton: boolean = true; //show button 'Proponer otro receptor'
  Showselect: boolean = false; //show select 'proponer otro receptor'
  typeButton: boolean = false; //type of button to be displayed
  ShowSetReceptor: boolean = false; //Show proposed recipient automatically
  gestionar_conflicto: string = "";
  HiddeIsNotConflict: boolean = false;

  //variables for POP-UP
  showPopUp: boolean;
  showModalPopUp = false;

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  constructor(
    private titleService: Title,
    private toastr: ToastrService,
    private searchService: AutocompleteWordService,
    private translate: TranslateService,
    private receptorService: ReceptorService,
    private apiService: ApiService,
    private router: Router,
    private formDataService: FormDataService,
    private auth: AuthService,
    private dataService: DatosService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute) {
    this.setupAutoComplete();
    this.receptorInicial = new ReceptorInicial;
    this.receptor = new Receptor;
    // Set title based on selected language
    this.translate.get('texto_717', { value: this.language }).subscribe((text: string) => {
      this.titleService.setTitle(text);
    });
  }

  ngOnInit(): void {
    // Retrieve the value of "popUp" from local storage
    if (localStorage.getItem("popUp") == "1") {
      // If the value is "1", set showPopUp to true initially
      this.showPopUp = true;

      // Subscribe to route query parameters to determine if the pop-up should be shown
      this.route.queryParams.subscribe(params => {
        // If the query parameter 'showPopUp' is explicitly 'false', hide the pop-up
        this.showPopUp = params['showPopUp'] !== 'false';
      });
    } else {
      // If the value is not "1", set showPopUp to false initially
      this.showPopUp = false;

      // Subscribe to route query parameters to determine if the pop-up should be shown
      this.route.queryParams.subscribe(params => {
        // If the query parameter 'showPopUp' is explicitly 'true', show the pop-up
        this.showPopUp = params['showPopUp'] === 'true';
      });
    }


    this.language = this.auth.getLanguage();
    if (this.language == "" || this.language == null) {
      this.language = 'es';
    }
    this.translate.setDefaultLang(this.language);

    // if mejorado
    this.showSelected = this.auth.getCentros() == '1' ? 1 : 0;
    // if mejorado
    this.showInputAutocomplete = this.auth.getInputAutocomplete() == '1' ? true : false;

    this.addRecaptchaScript();
    var iframe = document.getElementsByTagName('iframe')[1];
    if (typeof iframe !== 'undefined') {
      iframe.style.display = "block";
    }

    //obtiene las empresas por sociedad
    this.apiService.searchCompanies(this.auth.getSociedad()).subscribe((empresa: Empresa[]) => {
      this.empresas = empresa;
    });

    this.tipoHecho = this.formDataService.getTipoHecho();
    if (this.tipoHecho.tipoDenuncia != "") {
      this.apiService.searchComplaintByUserSocietyID(this.tipoHecho.empresa, this.tipoHecho.tipoDenuncia, false, this.language).subscribe((denuncia: Denuncia[]) => {
        this.denuncias = denuncia;
        this.descripcion = denuncia[0].descripcion;
      });
      //this.descripcion = this.denuncias[tipoDenuncia-1].descripcion;
    }
  }

  /**
 * FOCUS FOR MAT SELECT BY ACCESIBILITY
 */
  focusSelect(ID: number) {
    if (ID == 0){
      this.empresa.focus();
    } else if (ID == 1){
      this.centroSelect.focus();
    } else if (ID == 2) {
      this.tipoDenuncia.focus();
    } else if (ID == 3){
      this.receptorSelect.focus();
    } else {
      return null;
    }
  }

  // Hidde Popup
  hidePopUp() {
    this.showPopUp = false;
  }

  hideButton() {
    // Implementación de la función hideButton para ocultar el PopUp después de un tiempo
    setTimeout(() => {
      // Oculta el PopUp después de 1000 milisegundos (1 segundo)
      this.hidePopUp();
    }, 1000); // Tiempo en milisegundos, por ejemplo, 1000ms = 1 segundo
  }

  recuperar() {
    var valor = localStorage.getitem('popup');
    if (valor == true) {
      this.showPopUp = true
    } else {
      this.showPopUp = false;
    }
  }

  redirectToInicio(): void {
    localStorage.setItem('redirectToInicio', 'true');
    this.router.navigate(['/inicio']);
  }






  // enviarDatos(): void {
  //   const datos = {
  //     idEmpresa: this.tipoHecho.idEmpresa,
  //     tipoDenuncia: this.tipoHecho.tipoDenuncia,
  //     language: this.language,

  //   };
  //   if (this.tipoHecho.idEmpresa !== '' && this.tipoHecho.tipoDenuncia !== '' && this.language !== '') {
  //     this.dataService.enviarDatos(datos);
  //     console.log("datos tipo", datos);
  //   }

  // }


  /**
   * Evaluate the changes from autocomplete input
   */
  private setupAutoComplete() {
    this.searchInput.valueChanges
      .pipe(
        debounceTime(300), // Wait 300ms after last key press
        switchMap(query => {
          if (query && query.length >= 4) {
            return this.searchService.getAutocompleteSuggestions(query); // Find the word
          } else {
            return of([]); // observable void
          }
        })
      )
      .subscribe(arrayNames => {
        this.suggestions = arrayNames;
      });
  }

  /**
   * Handle the selection event company
   * @param suggestion
   * @param suggestionId
   * @param suggestionSociedad
   */
  selectSuggestion(suggestion: string, suggestionId: string, suggestionSociedad: string) {
    this.searchInput.setValue(suggestion);
    this.searchCentersAndComplaint(suggestion, suggestionId, suggestionSociedad);
    this.suggestions = []; // Hide suggestions after selection
    this.autocompletionerrorhandling(suggestion);
  }

  /**
   * Input Change from Autocomplete input
   * @param value // value from autocomplete input
   */
  onInputChange(value: string): void {
    this.inputValueAutocomplete = value;
    if (this.inputValueAutocomplete.length >= 4) {
      this.autocompletionerrorhandling(this.inputValueAutocomplete);
      if (this.suggestions) {
        //Checks if the company exists by writing it to the autocomplete input
        const companiesfound = this.suggestions.find(empresa => empresa.n_empresa === this.inputValueAutocomplete);
        if (companiesfound != '' && companiesfound != null && companiesfound != undefined) {
          this.searchCentersAndComplaint(companiesfound.n_empresa, companiesfound.id_empresa, companiesfound.id_sociedad);
        }
      }
    } else {
      this.hideerrorentrycompanies = false
    }
  }
  cerrar() {
    window.location.href = "informacion-principal.component.html";
  }
  /**
   * Handle autocomplete input errors
   * @param word //searched character
   */
  autocompletionerrorhandling(word: string) {
    this.searchService.getAutocompleteSuggestionsByWord(word).subscribe(resultado => {
      this.resultErrorAutocomplete = resultado;
      if (this.resultErrorAutocomplete != 0 && this.resultErrorAutocomplete != null) {
        this.hideerrorentrycompanies = false
        this.colorfieldAutocomplete = 'primary';
        this.enablebuttonNext = false;
      } else {
        this.hideerrorentrycompanies = true
        this.colorfieldAutocomplete = 'warn';
        this.enablebuttonNext = true;
      }
    });
  }

  /**
   * Search for the centers and types of complaints according to the selected company
   * @param suggestion //name company
   * @param suggestionId //id company
   * @param suggestionSociedad  //sociedad from company
   */
  searchCentersAndComplaint(suggestion: string, suggestionId: string, suggestionSociedad: string) {
    //Get language from canal
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    if (suggestionId && suggestionSociedad) {
      //Save data -> tipoHecho
      this.descripcion = "";
      this.tipoHecho.empresa = suggestionSociedad;
      this.tipoHecho.idEmpresa = suggestionId; //id company
      this.tipoHecho.identificadorEmpresa = suggestionId; //id company

      //Search centers by company ID
      this.apiService.searchCentersbyidCompany(suggestionId, suggestionSociedad).subscribe((centro: Centro[]) => {
        this.centros = centro;
        this.tipoHecho.tipoDenuncia = "";
      });

      //Search Type Complaint by Sociedad ID from Company selected
      this.apiService.searchTypeComplaint(suggestionSociedad, false, language).subscribe((denuncia: Denuncia[]) => {
        this.denuncias = denuncia;
        if (this.tipoHecho.tipoDenuncia == "") {
          this.descripcion = "";
        } else {
          this.descripcion = denuncia[0].descripcion;
        }
      });
    }
  }


  onClick(event) {
    this.descripcion = "";
    this.apiService.searchCompanies(this.auth.getSociedad()).subscribe((empresa: Empresa[]) => {
      this.empresas = empresa;
      this.tipoHecho.tipoDenuncia = "";
    });
  }

  //evento para obtener los centros de QSC_CENTROS
  onClickCenters(event) {
    //this.descripcion = "";
    this.apiService.searchCenters(this.auth.getSociedad()).subscribe((centro: Centro[]) => {
      this.centros = centro;
      this.tipoHecho.tipoDenuncia = "";
    });
  }

  onChange(event) {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    //datos de la tabla QSC_EMPRESA
    let selectedCompany = event.value.id_empresa; //empresa seleccionada
    let idEmpresa = event.value.id_empresa; //id de la empresa
    let idSociedad = event.value.id_sociedad; // id sociedad

    this.searchCentersAndComplaint(selectedCompany, idEmpresa, idSociedad);
  }

  onChangeCenter(event) {
    //datos de la tabla QSC_CENTROS
    let selectedCenter = event.value.id_centro;
    let idCentro = event.value.id_centro;

    this.tipoHecho.idCentro = selectedCenter; //id centro
    this.tipoHecho.identificadorCentro = idCentro;
  }

  onClickComplaint(event) {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    if (this.tipoHecho.empresa != "") {
      this.apiService.searchTypeComplaint(this.tipoHecho.empresa, false, language).subscribe((denuncia: Denuncia[]) => {
        this.denuncias = denuncia;
      });
    }

  }

  save(form: any): boolean {
    if (!form.valid) {
      return false;
    }

    this.formDataService.setTipoHecho(this.tipoHecho);

    // Compare the length of the reason for rejection
    if (this.contador > 255) {
      this.toastr.error(
        this.translate.instant('texto_266'),
        this.translate.instant('texto_243')
      );
      return false;
    } else {
      // Receiver object with receiver and reason properties
      if (this.typeButton && this.receptor.motivo === '') {
        this.toastr.error(
          'Es necesario añadir la descripción',
          'Parámetros incorrectos'
        );
        return false;
      } else {
        let receptor = { receptor: this.receptor.receptor, motivo: this.receptor.motivo };
        this.formDataService.setReceptor(receptor);
        return true;
      }
    }
  }

  goToNext(form: any) {
    if (this.save(form)) {
      //this.preSubmitForm()
      // Navigate to the work page
      this.router.navigate(['/enviar-denuncia/denunciante']);
    }
  }

  hide() {
    this.auth.logoutSimple();
    //window.location.reload();
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/inicio']);
    });
  }

  /*public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public preSubmitForm(): void {
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        console.log("What do I do with this?: ", token)
      });
  }*/


  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
    }

    (function (d, s, id, obj) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }


  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey': '6Ldku8sZAAAAANZFlrofaO_GHQQbxH99Y2nT_D-C',
      'callback': (response) => {
        //console.log(response);
      }
    });
  }


  /**
   * Method that is executed when the complaint type select is selected and searching of receptores
   * @param event //value id complaint
   */
  onChangeComplaint(event) {
    this.descripcion = "";
    let selectedComplaint = event.value;
    for (var i = 0; i < this.denuncias.length; i++) {
      if (this.denuncias[i].id_tp_denuncia == selectedComplaint) {
        this.descripcion = this.denuncias[i].descripcion;
        break;
      }
    }
    //Configure Language
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language); //set language value

    this.tipoHecho.tipoDenuncia = event.value; //Put the ID COMPLAINT value to the model tipoHecho.tipodenuncia
    let idSociedad = this.tipoHecho.empresa; //Get Id Sociedad from searchcenterandComplaint method

    this.HiddeIsNotConflict = localStorage.getItem("gestionar_conflicto") === '1';
    this.apiService.searchTypeComplaint(this.tipoHecho.empresa, false, language).subscribe((denuncia: Denuncia[]) => {
      this.denuncias = denuncia;
    });
    //Method get the random receptor with id sociedad, id tipo denuncia, language
    this.apiService.searchUsersReceptorsRamdom(idSociedad, this.tipoHecho.tipoDenuncia, language).subscribe((receptores: RolReceptor[]) => {
      this.receptores = receptores;
      for (var i = 0; i < this.receptores.length; i++) {
        this.Receptoraleatorio = JSON.stringify(receptores[0].n_user + ' ' + receptores[0].ape_user); //Get name and lastname from user initial receptor
        this.receptorInicial.receptorInicial = receptores[0].id_user; //Inserts the value of the "receptor inicial" to the receptorInicial model
        this.receptorInicial.motivoInicial = ""; //Inserts the value "" of the "motivo inicial" to the receptorInicial model
        this.formDataService.setReceptorInicial(this.receptorInicial); //send data formData service
        //this.formData = this.formDataService.getFormData();
        //console.log('receptor inicial con gestion conflicto', this.formData.receptorInicial);
      }
    });

    if (this.typeButton != true) {
      this.ShowSetReceptor = true; //Show button 'proponer otro receptor' and show 'receptor automatico'
    }
  }


  /**
   * Event That works when clicked "Proponer otro receptor"
   */
  searchReceptors() {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.Showbutton = false; //hidde button "proponer otro receptor"
    this.Showselect = true; //show select "elegir receptores"
    this.ShowSetReceptor = false; //hidde receptor "prouesto automáticamente"
    this.typeButton = true; //show button 'Proponer receptor automáticamente'
    let idSociedad = this.tipoHecho.empresa; //set Id Sociedad

    //Search Receptors service
    this.apiService.searchUsersReceptors(idSociedad, this.tipoHecho.tipoDenuncia, language).subscribe((receptores: RolReceptor[]) => {
      this.receptores = receptores;
    });
  }

  /**
   * Event That works when clicked "Proponer receptor automáticamente"
   */
  automaticReceptors() {
    //logic of the propose automatic receiver and choose receiver buttons
    this.Showbutton = true;
    if (this.Showbutton = true) {
      this.typeButton = false;
    }
    this.Showselect = false;
    this.ShowSetReceptor = true;

    let receptor = { receptor: "", motivo: "" }; // empty receiver object with receiver and reason properties
    this.formDataService.setReceptor(receptor); // set receptor value to formData
    //this.formData = this.formDataService.getFormData();
    //console.log('receptor elegido por gestion de conflcito ELIMINADO', this.formData.receptor);
  }

  /**
   * Event That Get the Value of the Receptor from the select
   * @param event //id receptor
   */
  onChangeReceptor(event) {
    let idReceptor = event.value;
    let receptor = { receptor: idReceptor, motivo: "" }; // receiver object with receiver and reason properties
    this.formDataService.setReceptor(receptor); //Set receptor value to formData
    //this.formData = this.formDataService.getFormData();
    //console.log('receptor elegido por gestion de conflcito', this.formData.receptor);
  }

  // Get the value by receptor
  onClickReceptor(event) {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.apiService.searchUsersReceptors(this.tipoHecho.empresa, this.tipoHecho.tipoDenuncia, language).subscribe((receptores: RolReceptor[]) => {
      this.receptores = receptores;
    });
  }

  /**
   * Event That Get the Value of the reason for rejection from the textarea
   */
  public onKey(event) {
    this.receptor.motivo = (document.getElementById('motivo') as HTMLInputElement).value;
    this.contador = this.receptor.motivo.length;
  }

  public deshacer() {
    let empresa = this.tipoHecho.empresa;
    this.receptor.receptor =
      this.formDataService.getReceptorInicial().receptorInicial;
    this.receptorService
      .searchUsersSocietyReceptors(empresa, this.receptor.receptor)
      .subscribe((receptores: RolReceptor[]) => {
        this.receptores = receptores;
      });
    this.inicial = false;
  }


  mostrarPopUp(mode: false | true | null) {
    this.showModalPopUp = mode;
  }
}
