import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { Usuarios } from '../dashboard/models/usuarios';
import { environment } from "../../environments/environment"
import { PlazosDenuncia } from '../model/plazosDenuncia';
import { DatePipe } from '@angular/common';
import { Rol } from '../model/rol';

@Component({
  selector: 'app-asignar-decisor',
  templateUrl: './asignar-decisor.component.html',
  styleUrls: ['./asignar-decisor.component.css'],
  providers: [DatePipe]
})
export class AsignarDecisorComponent implements OnInit {
  validatingForm: FormGroup;
  IsHiddenForm: boolean = false;
  @Input() denuncia: DatosDenuncia;
  decisores: Usuarios[];
  decisores_select: Usuarios[];
  plazos: PlazosDenuncia[];
  roles: Rol[];
  plazoFaseInvestigacionF: string = '';
  plazoFaseDecisorF: string = '';
  plazoFaseComplianceF: string = '';

  constructor(private apiService: ApiService, private actRoute: ActivatedRoute, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService, private datepipe: DatePipe) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      selectDecisor: new FormControl('', [Validators.required])
    });
    let url = window.location.href.split("/", 5);
    let idDenuncia = url[4];

    this.apiService.searchUserByIdSocietyRole(this.auth.getSociedad(), "3", false, "nulo").subscribe((usuarios: Usuarios[]) => {
      this.decisores = usuarios;
    });

  // get plazos form complaint
  this.apiService.searchInvestigatorByIdDenuncia(idDenuncia, '1').subscribe((plazos: PlazosDenuncia[]) => {
    this.plazos = plazos;

    if (this.plazos.length > 0 && this.plazos[0].id_denuncia) {
      this.plazoFaseInvestigacionF = this.plazos[0].plazo_investigacion;
      this.plazoFaseDecisorF = this.plazos[0].plazo_decisor;
      this.plazoFaseComplianceF = this.plazos[0].plazo_compliance;
    } else {
      this.plazoFaseInvestigacionF = localStorage.getItem("diasinves");
      this.plazoFaseDecisorF = localStorage.getItem("diasdecisor");
      this.plazoFaseComplianceF = localStorage.getItem("diascompliance");
    }
  });
   
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['denuncia'] && this.denuncia) {
      this.decisoresSeleccionados();
    }
  }

  get selectDecisor() {
    return this.validatingForm.get('selectDecisor');
  }

  asignarDecisor() {
    if (this.selectDecisor.valid) {
      this.apiService.disableUserByIdComplaint(this.denuncia[0].id_denuncia).subscribe(
        res => {
          if (res == 200) {
            this.apiService.createUserByIdComplaint(this.auth.getSociedad(), this.selectDecisor.value, this.denuncia[0].id_denuncia, "1", '1', '3', false).subscribe(
              res => {
                if (res == 200) {
                  this.toastr.success(this.translate.instant('texto_296'), this.translate.instant('texto_297'));

                  // add "decisores" to table QSC_DENUNCIAS_PLAZOS with deadlines assingned from investigador.
                  this.apiService.createDeadlinesByIdComplaint(this.auth.getSociedad(), this.denuncia[0].id_denuncia, this.plazoFaseInvestigacionF, this.plazoFaseDecisorF, this.plazoFaseComplianceF, this.selectDecisor.value).subscribe(
                    res => {
                      if (res == 200) {
                        this.toastr.success(this.translate.instant('texto_325'), this.translate.instant('texto_326'));
                      } else {
                        this.toastr.error(this.translate.instant('texto_298'), this.translate.instant('texto_297'));
                      }
                    },
                    err => {
                      this.toastr.error(this.translate.instant('texto_298'), this.translate.instant('texto_297'));
                    }
                  );

                  this.decisoresSeleccionados();

                  this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
                  });
                } else {
                  this.toastr.error(this.translate.instant('texto_298'), this.translate.instant('texto_297'));
                }
              });
          } else {
            this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
          }
        });
    } else {
      this.toastr.error(this.translate.instant('texto_298'), this.translate.instant('texto_297'));
    }
  }



  decisoresSeleccionados() {
    this.apiService.searchInvesSelect(this.auth.getSociedad(), this.denuncia[0].id_denuncia, "3").subscribe((usuarios2: Usuarios[]) => {
      this.decisores_select = usuarios2;
    });
  }

  deleteinves(id_user: string) {
    this.apiService.countInvesSelect(this.auth.getSociedad(), this.denuncia[0].id_denuncia, id_user, "3").subscribe(
      res => {
        if (res == 200) {
          this.apiService.deleteInvesSelect(id_user, this.auth.getSociedad(), this.denuncia[0].id_denuncia, "3").subscribe(
            res => {
              if (res == 200) {
                this.toastr.success(this.translate.instant('texto_462'), this.translate.instant('texto_463'));
              } else {
                this.toastr.error(this.translate.instant('texto_464'), this.translate.instant('texto_463'));
              }
              this.decisoresSeleccionados();
            });
        } else {
          this.toastr.error(this.translate.instant('texto_464'), this.translate.instant('texto_463'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
        });
      });
  }
}
