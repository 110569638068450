import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, NavigationExtras }              from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Canal } from 'src/app/enviar-denuncia/models/canal';
import { DatosDenunciante } from 'src/app/shared/models/formData.model';
import { Relacion } from 'src/app/enviar-denuncia/models/relacion';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EnviarDenunciaService } from 'src/app/enviar-denuncia/services/enviar-denuncia.service';
import { FormDataService } from 'src/app/enviar-denuncia/services/formData.service';
import { MatSelect } from '@angular/material/select';


@Component({
  selector: 'app-denunciante',
  templateUrl: './denunciante.component.html',
  styleUrls: ['./denunciante.component.css']
})
export class DenuncianteComponent implements OnInit {
  @ViewChild('denuncianteSelect') denuncianteSelect: MatSelect;
  @Input() formData;
  title = 'texto_78';
    relacion: DatosDenunciante;
    tipoRelacion: string;
    form: any;
    tipoDenunciantes: Relacion;
    canal: Canal;
    IsAnonimo:boolean = false;
    IsHidden= true;
    constructor( private auth: AuthService,private apiService: ApiService, private enviarDenunciaService: EnviarDenunciaService, private router: Router, private formDataService: FormDataService, private toastr: ToastrService) {}

    ngOnInit() {
      let language = this.auth.getLanguage();
      if(language == "" || language == null){
        language = 'es';
      }

        this.relacion = this.formDataService.getDenunciante();
        this.formData = this.formDataService.getFormData();

        this.apiService.searchTypeRelation(this.formData.empresa,false,language).subscribe((tipoDenunciantes: Relacion)=>{
          this.tipoDenunciantes = tipoDenunciantes;
        });

        this.enviarDenunciaService.searchCanal().subscribe((canal: Canal)=>{
            this.canal = canal;
            if(this.canal[0].valor == "1"){
                this.IsAnonimo = true;
            }
            if(this.canal[0].valor == "2"){
              this.IsHidden = false;
            }
          });
    }

    /**
     * FOCUS FOR MAT SELECT BY COMPANY RELATION
     */
    focusSelect() {
      this.denuncianteSelect.focus();
    }

    save(form: any): boolean {
        if(this.IsAnonimo != true){
            if (!form.valid) {
                return false;
            }
            if(this.canal[0].valor == "0"){
              if(this.relacion.denuncianteFirstName == "" || this.relacion.denuncianteLastName == "" || this.relacion.denuncianteEmail == "" || this.relacion.denunciantePhone == ""){
                this.toastr.error('Es obligatorio dejar los datos personales', 'Parámetros incorrectos');
                return false;
              }
            }
        }


        this.formDataService.setDenunciante(this.relacion);
        return true;
    }

    goToPrevious(form: any) {
      // Configurar parámetros adicionales para pasar al componente tipo
        const navigationExtras : NavigationExtras = {
        queryParams : { showPopUp :'false'
        // Establecer el valor de showPopUp como false
        } };
       // if (this.save(form)) {



            this.router.navigate(['/enviar-denuncia/datos-empresa'], navigationExtras);
      //  }
    }

    goToNext(form: any) {
        if (this.save(form)) {
            this.router.navigate(['/enviar-denuncia/datos-denuncia']);
        }
    }

    hide(){
      this.auth.logoutSimple();
      // //window.location.reload();
      this.router
        .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(['/inicio']);
        });
    }
}
