import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Pin } from 'src/app/enviar-denuncia/models/pin';
import { environment } from 'src/environments/environment';
import { Denuncia } from 'src/app/enviar-denuncia/models/denuncia';
import { Abogado } from 'src/app/enviar-denuncia/models/abogado';
import { Receptor } from 'src/app/enviar-denuncia/models/receptor';
import { Relacion } from 'src/app/enviar-denuncia/models/relacion';
import { Empresa } from 'src/app/enviar-denuncia/models//empresa';
import { EnviarDenunciaService } from 'src/app/enviar-denuncia/services/enviar-denuncia.service';
import { FormDataService } from 'src/app/enviar-denuncia/services/formData.service';
import { FinalizarDenunciaService } from 'src/app/enviar-denuncia/services/finalizar-denuncia.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { HttpClient } from '@angular/common/http';
import { FinalizarDenuncia } from 'src/app/shared/models/formData.model';

@Component({
  selector: 'app-finalizar-denuncia',
  templateUrl: './finalizar-denuncia.component.html',
  styleUrls: ['./finalizar-denuncia.component.css'],
})
export class FinalizarDenunciaComponent implements OnInit {
  title = 'texto_359';
  finalizar: FinalizarDenuncia;
  @Input() formData;
  terminos1: boolean = false;// Represents the status of terms or conditions 1. True if agreed, false if not agreed.
  politicas: boolean = false;// Represents the status of privacy policy. True if agreed, false if not agreed.
  documentosAbiertos: { [key: string]: boolean } = { 'url': false, 'documento': false };
  // An object that tracks the open status of documents.
  // 'url': True if the URL document is open, false otherwise.
  // 'documento': True if the document is open, false otherwise.
  canFinalize: boolean = false;
// Indicates whether the finalization process can proceed based on specified conditions.
// True if all necessary terms are agreed upon and required documents are open, otherwise false.

  terminos2: string;
  url: string;
  documento: string;

  politicaEnabled: string;
  urlpolitica: string;
  docpolitica: string;
  finaliza: any = {
    terminos: false,
    proteccionDatos: false
  };
  terminosDisabled: boolean = false;
  proteccionDatosDisabled: boolean = true;
  PHP_API_SERVER = environment.baseUrl;

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  constructor(
    private router: Router,
    private auth: AuthService,
    private formDataService: FormDataService,
    private enviarDenunciaService: EnviarDenunciaService,
    private finalizarDenunciaService: FinalizarDenunciaService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.finalizar = this.formDataService.getFinalizar();
    this.terminos2 = localStorage.getItem('terminos');
    this.url = localStorage.getItem('url');
    this.documento = localStorage.getItem('documento');
    this.politicaEnabled = localStorage.getItem('politicaClienteEnabled');
    this.urlpolitica = localStorage.getItem('urlpoliticacliente');
    this.docpolitica = localStorage.getItem('docpoliticacliente');


    //console.log(this.terminos2);
    //console.log(this.url);
    //console.log(this.documento);
  }

  /**
   * Function to navigate to path or url of private privacy.
   */
  onPoliticasChange() {
    if (this.finalizar.politicas) {
      window.open(this.politicaEnabled == '1' ? this.urlpolitica : this.docpolitica, '_blank');
    }
    if (this.finaliza.politicas) {
      this.proteccionDatosDisabled = false;
    } else {
      this.finaliza.proteccionDatos = false;
      this.proteccionDatosDisabled = true;
    }
  }

  onTerminosChange() {
    if (this.finalizar.terminos) {
      window.open(this.terminos2 == '1' ? this.url : this.documento, '_blank');
    }
    if (this.finaliza.terminos) {
      this.proteccionDatosDisabled = false;
    } else {
      this.finaliza.proteccionDatos = false;
      this.proteccionDatosDisabled = true;
    }
  }

  onProteccionDatosChange() {
    // You can add any additional logic here if necessary
  }

  finalizarDenuncia() {
    if (this.finalizar.terminos && !this.proteccionDatosDisabled && this.finalizar.proteccionDatos) {
      // Process to finalize the complaint
      console.log('Denuncia finalizada');
    }
  }

  documentOpened(doc: string) {
    // Mark the document as opened
    this.documentosAbiertos[doc] = true;
    // Mark the document as opened
    //this.checkTerms();
    // Log the status of the opened document to the console
    console.log(this.documentosAbiertos[doc]);
  }



  save(form: any): boolean {
    if (!form.valid) {
      return false;
    }

    this.formDataService.setFinalizar(this.finalizar);
    return true;
  }

  goToPrevious(form: any) {
    //if (this.save(form)) {
    // Navigate to the personal page
    this.formDataService.setFinalizar(this.finalizar);

    this.router.navigate(['/enviar-denuncia/archivos']);
    // }
  }
  goToNext(form: any) {
    if (this.save(form)) {
        this.router.navigate(['/enviar-denuncia/crear-contrasenia']);
    }
}

  

  hide() {
    this.auth.logoutSimple();
    // //window.location.reload();
    this.router
      .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/inicio']);
      });
  }

  navigateToNewPage() {
    //Replace the current history state with a new entry for the root URL ('/')
    this.auth.logoutSimple();
    window.history.replaceState(null, '', '/');
  }
}
