import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GestionUsuariosService {

    PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
    // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
    constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) { }

    //Componente gestion-usuarios
    deleteUser(id_usuario: string, id_sociedad: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarUsuario.php`, {
            id_usuario: id_usuario,
            id_sociedad: id_sociedad,
        }, options);
    }

    searchAllUsers() {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTodosUsuarios.php`, {
        }, options);
    }

    searchCountComplaintBySocietyUser(id_sociedad: string, id_user: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorUsuarioSociedad.php`, {
            id_sociedad: id_sociedad,
            id_user: id_user
        }, options);
    }

    // Componente update-password
    updatePasswordUser(username: string, password: string, passwordOld: string, reenviar: boolean) {
        // TODO: Todas estas configuraciones de cabeceras se hacen en el interceptor. Es redundante hacerlo aquí.
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/updatePassword.php`, {
            username: username,
            password: password,
            passwordOld: passwordOld,
            reenviar: reenviar
        }, options);
    }


    //Componente user-profile
    updateDataUser(email: string, name: string, surname: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/updateUser.php`, {
            email: email,
            name: name,
            surname: surname,
        }, options);
    }

    searchUserById(id_user: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuarioId.php`, {
            id_user: id_user
        }, options);
    }

    // Componente add-user
    addUser(id_user: string, mail_user: string, n_user: string, ape_user: string, id_sociedad: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearUsuario.php`, {
            id_user: id_user,
            mail_user: mail_user,
            n_user: n_user,
            ape_user: ape_user,
            id_sociedad: id_sociedad
        }, options);
    }

    //Componente update-user
    deleteRoleByUserSociety(id_user: string, id_sociedad: string, id_rol: string, fec_inicio: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarRolbyUserSociety.php`, {
            id_user: id_user,
            id_sociedad: id_sociedad,
            id_rol: id_rol,
            fec_inicio: fec_inicio,
        }, options);
    }

    blockUser(id_user: string, state: string, id_usuario: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/bloquearUsuario.php`, {
            id_user: id_user,
            state: state,
            id_usuario: id_usuario
        }, options);
    }

    updateRolUser(id_sociedad: string, id_user: string, id_rol: string, fec_inicio: string, fec_fin: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/actualizarRolbyUserSociety.php`, {
            id_user: id_user,
            id_sociedad: id_sociedad,
            id_rol: id_rol,
            fec_inicio: fec_inicio,
            fec_fin: fec_fin,
        }, options);
    }

    updateUser(id_user: string, mail_user: string, n_user: string, ape_user: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarUsuario.php`, {
            id_user: id_user,
            mail_user: mail_user,
            n_user: n_user,
            ape_user: ape_user,
        }, options);
    }

    //Componente add-user-role
    searchRole() {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/getRoles.php`, {
        }, options);
    }

    addRoleByIdUser(id_user: string, id_sociedad: string, id_rol: string, fec_inicio: string, fec_fin: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearRolbyUserSociety.php`, {
            id_user: id_user,
            id_sociedad: id_sociedad,
            id_rol: id_rol,
            fec_inicio: fec_inicio,
            fec_fin: fec_fin,
        }, options);
    }


}
