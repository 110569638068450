<header style="z-index: 90 !important; background-color: white;">
    <nav class="navbar navbar-expand-lg navbar-dark bg-transparent px-0"
        style="z-index: 90 !important; padding-left: 5% !important; padding-right: 5% !important; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); margin-bottom: 0px !important;">
        <a href="#inicio" class="button-link">
            <img src="assets/img/logos/logo.png" height="50px" style="margin-left: -10px;"
                alt="{{ 'texto_294' | translate }} {{ 'texto_6' | translate }}">
        </a>
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link smooth-scroll"
                    style="color: #000000!important;"><app-translation></app-translation></a>
            </li>
        </ul>
    </nav>
</header>