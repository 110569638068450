import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { FormDataService }     from '../../../data/formData.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../shared/services/auth.service';
import { Router }              from '@angular/router';
import { FinalizarDenuncia } from '../../../data/formData.model';
import { TipoHecho } from '../../../data/formData.model';

@Component ({
    selector: 'msw-navbar'
    ,templateUrl: './navbar.component.html'
})

export class NavbarComponent implements OnInit{
    finalizar: FinalizarDenuncia;
    tipoHecho: TipoHecho;
    isActiveTipo = false;
    isActiveDenunciante = false;
    isActiveReceptor = false;
    isActiveDatosDenuncia = false;
    isActiveTestigo = false;
    isActiveArchivos = false;
    isActiveFinalizarDenuncia = false;
    isActiveFinalizada = false;

    constructor(private router: Router, private auth: AuthService, private formDataService: FormDataService, private apiService: ApiService, private toastr: ToastrService, private translate: TranslateService) {}

    ngOnInit() {
        let tabs = window.location.href.split("/", 6);
        let path = tabs[4];
        if(path == "datos-empresa"){
            this.isActiveTipo = true;
        }else if(path == "denunciante"){
            this.isActiveDenunciante = true;
            this.isActiveTipo = true;
        }else if(path == "datos-denuncia"){
            this.isActiveDatosDenuncia = true;
            this.isActiveDenunciante = true;
            this.isActiveTipo = true;
        }else if(path == "testigo"){
            this.isActiveTestigo = true;
            this.isActiveDatosDenuncia = true;
            this.isActiveDenunciante = true;
            this.isActiveTipo = true;
        }else if(path == "archivos"){
            this.isActiveArchivos = true;
            this.isActiveTestigo = true;
            this.isActiveDatosDenuncia = true;
            this.isActiveDenunciante = true;
            this.isActiveTipo = true;
        }else if(path == "finalizar-denuncia"){
            this.isActiveFinalizarDenuncia = true;
            this.isActiveArchivos = true;
            this.isActiveTestigo = true;
            this.isActiveDatosDenuncia = true;
            this.isActiveDenunciante = true;
            this.isActiveTipo = true;
        }else if(path == "finalizada" || path == "crear-contrasenia"){
            this.isActiveFinalizada = true;
            this.isActiveFinalizarDenuncia = true;
            this.isActiveArchivos = true;
            this.isActiveTestigo = true;
            this.isActiveDatosDenuncia = true;
            this.isActiveDenunciante = true;
            this.isActiveTipo = true;
        }
    }

    goToDenunciante(){
        this.tipoHecho = this.formDataService.getTipoHecho();
        if(this.tipoHecho.empresa == "" || this.tipoHecho.tipoDenuncia == ""){
            return false;
        }else{
            this.router.navigate(['/enviar-denuncia/denunciante']);
        }
    }
}
