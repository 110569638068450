import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VerDenunciaService {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
  constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) { }

  searchComplaintById(id_denuncia: string, idioma: string, id_user: string, isActiveCenter: any, isActiveIdPersonalizable: any) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasById.php`, {
      id_denuncia: id_denuncia,
      idioma: idioma,
      id_user: id_user,
      isActiveCenter: isActiveCenter,
      isActiveIdPersonalizable: isActiveIdPersonalizable
    }, options).pipe(
      tap((data) => {
      })
    );;
  }


  searchWitnessByUserComplaint(id_denuncia: string, id_usuario: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTestigoByIdDenunciaUsuario.php`, {
      id_denuncia: id_denuncia,
      id_usuario: id_usuario
    }, options);

  }


  searchDocumentsByUserComplaint(id_denuncia: string, id_usuario: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDocumentosByIdDenunciaUsuario.php`, {
      id_denuncia: id_denuncia,
      id_usuario: id_usuario
    }, options);
  }


  searchCommentsByComplaint(id_denuncia: string, seguro: boolean) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarComentariosByDenuncia.php`, {
      id_denuncia: id_denuncia,
      seguro: seguro,
    }, options);
  }
  sendMessageChat(id_sociedad: string, id_denuncia: string, id_user: string, comentario: string, fecha_comentario: string, id_usuario: string, seguro: boolean) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/enviarMensajeChat.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      id_user: id_user,
      comentario: comentario,
      fecha_comentario: fecha_comentario,
      id_usuario: id_usuario,
      seguro: seguro,
    }, options);
  }
  buscarUsuarioDenuncia(id_denuncia: string, seguro: boolean) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuarioByDenuncia.php`, {
      id_denuncia: id_denuncia,
      seguro: seguro,
    }, options);
  }

  sendEmail(id_user: string, subject: string, message: string, boton: string, clausulas: boolean, titulo: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/enviarEmail.php`, {
      id_user: id_user,
      subject: subject,
      message: message,
      boton: boton,
      clausulas: clausulas,
      titulo: titulo,
    }, options);
  }
  verArchivo(id_archivo): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth.getToken()
    });
    let options = {
      headers: headers
    };
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/verArchivo.php`, { id_archivo }, options);
  }

}
